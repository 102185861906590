<template>
  <div class="px-2 pt-2 mb-2 d-flex current-user">
    <div class="d-flex flex-column justify-center full-width">
      <p class="mb-0 ">{{ userDisplayName }}</p>
      <p class="mb-0 role-text primary--text">{{ balance }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatCurrency } from "@/helpers/formatCurrency";

export default {
  name: "CurrentUser",
  computed: {
    balance() {
      const balance = this.$store.getters["session/balance"];
      return formatCurrency(balance.amount);
    },
    ...mapGetters("session", ["userDisplayName"])
  }
};
</script>

<style lang="scss">
@import "./CurrentUser.scss";
</style>
