<template>
  <div class="fast-add mx-6" v-click-outside="hideMenu">
    <v-btn
      color="primary"
      class="bb-button-shadow add-button  d-none d-md-flex"
      @click="menuActive = !menuActive"
      x-large
    >
      <v-icon left x-small>
        {{ menuActive ? "bb-close_icon" : "bb-add_icon" }}
      </v-icon>
      {{ $t("common.add") }}
    </v-btn>
    <div
      @click="menuActive = !menuActive"
      v-if="menuActive"
      class="fast-add-menu"
    >
      <v-list color="primary">
        <v-list-item
          v-for="(item, index) in menuItems"
          :key="`fast-add-item-${index}`"
          :to="item.to"
        >
          <v-list-item-title class="white--text body-2 text-center">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  directives: {
    ClickOutside
  },
  methods: {
    hideMenu() {
      this.menuActive = false;
    }
  },
  data() {
    return {
      menuActive: false
    };
  },
  computed: {
    menuItems() {
      return [
        {
          name: this.$t("fastAdd.group"),
          to: { name: "AddGroup" }
        },
        {
          name: this.$t("fastAdd.user"),
          to: { name: "InviteUser" }
        },
        {
          name: this.$t("fastAdd.target"),
          to: { name: "AddTarget" }
        }
      ];
    }
  }
};
</script>

<style lang="scss">
.fast-add {
  position: relative;
  .fast-add-menu {
    max-width: 100%;
    width: 100%;
    position: absolute;
    bottom: 2px;
    transform: translateY(100%);
    right: 0;
  }
}
</style>
