<template>
  <div v-if="files && files.length">
    <p class="pb-2 font-weight-medium">{{ $t("downloadFiles.title") }}</p>
    <div
      class="file-row my-4 d-flex align-center"
      v-for="file in files"
      :key="`download-file-${file.id}`"
    >
      <v-icon size="16" color="primary" class="mr-4">bb-pdf_file_icon</v-icon>
      <a class="text-1" :href="file" target="_blank">
        {{
          $t("downloadFiles.fileName", { extension: getFileExtention(file) })
        }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    files: Array
  },
  methods: {
    getFileExtention(link) {
      return link.split(".")[link.split(".").length - 1];
    }
  }
};
</script>

<style></style>
