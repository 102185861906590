<template>
  <img src="/img/bb-heart.png" class="bb-heart" />
</template>

<script>
export default {
  name: "BeeHeartIcon"
};
</script>

<style lang="scss">
@media (max-width: 960px) {
  .bb-heart {
    max-width: 80px;
  }
}
</style>
