import { roles } from "@/constants/roles";

export default {
  isLoggedIn: (state) => {
    return !!state.accessToken;
  },
  currentPlan: (state) => state.user.company.subscription,
  authStatus: (state) => state.status,
  gotUserData: (state) => !!state.userData,
  user: (state) => state.userData,
  mail: (state) =>
    state.userData.email.length > 25
      ? `${state.userData.email.slice(0, 20)}...`
      : state.userData.email,
  userDisplayName: (state, getters) => {
    return !!state.userData.full_name ? state.userData.full_name : getters.mail;
  },
  userType: (state) => {
    return roles.find((role) => {
      return role.id === state.userData.type;
    }).name;
  },
  userTypeTranslationKey: (state) => {
    return roles.find((role) => {
      return role.id === state.userData.type;
    }).translationKey;
  },
  isAdmin: (state) => {
    if (state.user) {
      return state.user.type === 1;
    } else return false;
  },
  firstName: (state) => state.userData.first_name,
  currency: (state) => state.userData.company.default_currency,
  paymentCurrency: (state) => state.userData.company.default_currency == "PLN" ? "PLN" : "USD",
  balance: (state) => state.userData.balance
};
