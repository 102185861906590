import axios from "axios";
import sessionSerivce, { changePassword } from "@/services/session.js";
import { setCookiesAuth, removeCookiesAuth } from "@/helpers/cookies";
import router from "@/router";
import jwt_decode from "jwt-decode";
import i18n from "@/i18n/index.js";
import { getMyProfile, updateMyProfile } from "@/services/profiles";
import { updateCompany } from "@/services/companies";

export default {
  updateCompany({ commit, getters }, data) {
    const companyId = getters["user"].company.id;
    delete data.default_currency;
    delete data.default_timezone;

    updateCompany(companyId, data).then(({ data }) => {
      this._vm.$swal({
        title: i18n.t("messeages.connectionSuccess.updatedCompany"),
        icon: "success",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000
      });

      commit("save_company_data", data);
    });
  },
  updateMyProfile({ commit }, data) {
    updateMyProfile(data).then(({ data }) => {
      commit("save_user_data", data);
    });
  },
  changePassword({ commit }, data) {
    changePassword(data).then(() => {
      router.push({
        name: "Dashboard"
      });
    });
  },
  async getUserData({ commit, dispatch }) {
    await getMyProfile()
      .then(({ data }) => {
        commit("save_user_data", data);
      })
      .catch(() => {
        dispatch("logout");
        router.push({
          name: "Login"
        });
      });
  },
  activate({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("auth_request");

      sessionSerivce
        .activate(data)
        .then((resp) => {
          const commitData = {
            accessToken: resp.data.access_token,
            refreshToken: resp.data.refresh_token,
            user: resp.data.user
          };

          setCookiesAuth(
            commitData.accessToken,
            commitData.refreshToken,
            JSON.stringify(commitData.user)
          );

          axios.defaults.headers.common["Authorization"] =
            commitData.accessToken;

          commit("auth_success", commitData);

          router.push({
            name: "Dashboard"
          });

          resolve(resp);
        })
        .catch((err) => {
          commit("auth_error");

          removeCookiesAuth();

          reject(err);
        });
    });
  },
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      sessionSerivce
        .logIn(data)
        .then((resp) => {
          const commitData = {
            accessToken: resp.data.access_token,
            refreshToken: resp.data.refresh_token,
            user: resp.data.user
          };

          setCookiesAuth(
            commitData.accessToken,
            commitData.refreshToken,
            JSON.stringify(commitData.user)
          );

          axios.defaults.headers.common["Authorization"] =
            commitData.accessToken;

          commit("auth_success", commitData);

          let searchParams = new URLSearchParams(window.location.search);

          if (searchParams.has("redirect")) {
            router.push({ path: searchParams.get("redirect") });
          } else {
            router.go();
          }
          resolve(resp);
        })
        .catch((err) => {
          commit("auth_error");

          removeCookiesAuth();

          reject(err);
        });
    });
  },

  register({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      sessionSerivce
        .register(data)
        .then((resp) => {
          const commitData = {
            accessToken: resp.data.access_token,
            refreshToken: resp.data.refresh_token,
            user: resp.data.user
          };

          setCookiesAuth(
            commitData.accessToken,
            commitData.refreshToken,
            JSON.stringify(commitData.user)
          );

          axios.defaults.headers.common["Authorization"] =
            commitData.accessToken;

          commit("auth_success", commitData);

          router.push({
            name: "Dashboard"
          });

          resolve(resp);
        })
        .catch((err) => {
          commit("auth_error");

          removeCookiesAuth();

          reject(err);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      commit("logout");

      removeCookiesAuth();

      delete axios.defaults.headers.common["Authorization"];

      router.push({
        name: "Login"
      });

      resolve();
    });
  },
  verifyToken({ state, dispatch }) {
    if (state.accessToken && state.refreshToken) {
      sessionSerivce
        .verifyToken(state.accessToken)
        .then(() => {
          dispatch("autoRefresh");
        })
        .catch(() => {
          dispatch("refreshToken");
        });
    } else {
      dispatch("logout");
      router.push({
        name: "Login"
      });
    }
  },
  refreshToken({ state, commit, dispatch }) {
    if (state.refreshToken) {
      sessionSerivce
        .refreshToken(state.refreshToken)
        .then((resp) => {
          let accessToken = resp.data.access;
          $cookies.set("accessToken", accessToken);
          commit("auth_refresh_token", accessToken);
          dispatch("autoRefresh", true);
        })
        .catch(() => {
          dispatch("logout");
          router.push({
            name: "Login"
          });
        });
    } else {
      dispatch("logout");
      router.push({
        name: "Login"
      });
    }
  },
  autoRefresh({ state, commit, dispatch, getters }, ignoreBlockade = false) {
    if (ignoreBlockade || !state.autoRefreshInProgress) {
      const { accessToken } = state;
      const { exp } = jwt_decode(accessToken);

      const now = Date.now() / 1000;
      let timeUntilRefresh = exp - now;
      timeUntilRefresh -= 60;
      commit("setAutoRefreshInProgress", true);

      setTimeout(function() {
        commit("setAutoRefreshInProgress", false);
        if (getters.isLoggedIn) dispatch("refreshToken");
      }, timeUntilRefresh * 1000);
    }
  },
  async confirmResetPassword({ state }, data) {
    return await sessionSerivce.confirmReset(data);
  }
};
