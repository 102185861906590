<template>
  <div>
    <v-btn icon color="primary" dark @click="toggleMobileMenuDrawer">
      <v-icon> mdi-menu </v-icon>
    </v-btn>

    <v-navigation-drawer
      width="300px"
      overlay-opacity="0"
      class="mobile-menu-drawer d-flex flex-column justify-center"
      v-model="mobileMenuDrawer"
      fixed
      right
      temporary
    >
      <div class="pa-5 pb-4 d-flex justify-end">
        <v-btn color="black" small icon @click="toggleMobileMenuDrawer">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>
      <div class="px-3">
        <CurrentUser hideAvatar />
      </div>

      <v-text-field
        class="mobile-search-input mt-5 mb-4"
        v-model="searchValue"
        outlined
        hide-details
        append-icon="mdi-magnify"
        :placeholder="$t('layoutAdmin.searchByCategory')"
        @click:append="search"
        @keyup.enter="search"
      ></v-text-field>
      <div
        class="px-5 fill-height mobile-navigation d-flex flex-column justify-start text-right"
      >
        <slot />
      </div>
      <div class="mobile-menu-bottom pa-5 pt-3 d-flex justify-end">
        <MenuMobileLanguageSwitcher class="px-7" />

        <div>
          <a @click="logOut">
            {{ $t("common.logOut") }}
          </a>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import MenuMobileLanguageSwitcher from "@/components/Base/MenuMobileLanguageSwitcher/MenuMobileLanguageSwitcher.vue";
import CurrentUser from "@/components/Base/CurrentUser/CurrentUser";

export default {
  name: "MobileMenu",
  components: {
    MenuMobileLanguageSwitcher,
    CurrentUser
  },
  data() {
    return {
      mobileMenuDrawer: false,
      searchValue: ""
    };
  },
  methods: {
    logOut() {
      this.$store.dispatch("session/logout");
    },
    search() {
      this.$router.push({ name: "Search", params: { text: this.searchValue } });
    },
    toggleMobileMenuDrawer() {
      this.mobileMenuDrawer = !this.mobileMenuDrawer;
    }
  }
};
</script>
<style lang="scss">
@import "./MobileMenu.scss";
</style>
