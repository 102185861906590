import i18n from "@/i18n/index.js";

export const targetStatuses = [
  {
    id: 0,
    name: "targetStatuses.all",
    singleKey: "targetStatus.all"
  },
  {
    id: 1,
    name: "targetStatuses.open",
    singleKey: "targetStatus.open"
  },
  {
    id: 2,
    name: "targetStatuses.founded",
    singleKey: "targetStatus.founded"
  },
  {
    id: 3,
    name: "targetStatuses.completed",
    singleKey: "targetStatus.completed"
  },
  {
    id: 4,
    name: "targetStatuses.paused",
    singleKey: "targetStatus.paused"
  },
  {
    id: 5,
    name: "targetStatuses.cancelled",
    singleKey: "targetStatus.cancelled"
  }
];
