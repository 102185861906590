<template>
  <div>
    <PaymentSucceedDialog :active="paymentSucced" />

    <label class="grey--text font-weight-medium">
      {{ $t("stripePayment.cardNumber") }}
    </label>
    <div class="stripe-input mt-3 mb-8">
      <div id="card-number"></div>
    </div>
    <div class="d-flex flex-column flex-sm-row">
      <div class="full-width pr-0 pr-sm-3">
        <label class="grey--text font-weight-medium">
          {{ $t("stripePayment.cardExpiry") }}
        </label>
        <div class="stripe-input mt-3">
          <div id="card-expiry"></div>
        </div>
      </div>
      <div class="full-width pl-sm-3 pl-0 pt-8 pt-sm-0">
        <label class="grey--text font-weight-medium">
          {{ $t("stripePayment.cardCvc") }}
        </label>
        <div class="stripe-input mt-3">
          <div id="card-cvc"></div>
        </div>
      </div>
    </div>
    <div class="mt-12 d-flex align-center mb-16">
      <v-checkbox v-model="agreedWithTerms" hide-details />
      <p
        @click.self="toggleAgreed"
        class="mt-5 mb-0 grey--text font-weight-medium clickable"
      >
        {{ $t("stripePayment.agree") }}
        <a :href="terms" target="_blank">{{ $t("stripePayment.terms") }}</a>
        {{ $t("common.and") }}
        <a :href="privacyPolicy" target="_blank">{{
          $t("stripePayment.agreement")
        }}</a
        >.
      </p>
    </div>
    <div class="text-center">
      <v-btn
        :loading="loading"
        color="primary"
        class="mt-4 bb-button-shadow"
        large
        @click="handleBuy"
      >
        {{ $t("stripePayment.completePurchase") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import PaymentSucceedDialog from "@/components/Admin/PaymentSucceedDialog/PaymentSucceedDialog.vue";

import { createSubscription } from "@/services/payments.js";

export default {
  props: {
    priceId: String,
    customerId: String
  },
  components: {
    PaymentSucceedDialog
  },
  data() {
    return {
      loading: false,
      paymentSucced: false,
      privacyPolicy: this.$t("privacyPolicy"),
      terms: this.$t("termsAndConditions"),
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      agreedWithTerms: false
    };
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements({
        fonts: [
          {
            family: "Montserrat",
            src:
              "url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)",
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD",
            style: "normal",
            weight: "400",
            display: "swap"
          }
        ]
      });
    }
  },
  mounted() {
    const style = {
      base: {
        margin: "auto 0px",
        fontFamily: '"Montserrat", sans-serif',
        fontSize: "16px",
        color: "rgba(0, 0, 0, 0.87)"
      }
    };
    this.cardNumber = this.stripeElements.create("cardNumber", { style });
    this.cardNumber.mount("#card-number");
    this.cardExpiry = this.stripeElements.create("cardExpiry", { style });
    this.cardExpiry.mount("#card-expiry");
    this.cardCvc = this.stripeElements.create("cardCvc", { style });
    this.cardCvc.mount("#card-cvc");
  },
  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    toggleAgreed() {
      this.agreedWithTerms = !this.agreedWithTerms;
    },
    handleBuy() {
      if (this.agreedWithTerms) this.createToken();
      else {
        this.$swal({
          title: this.$t("messeages.validationErrors.youNeedToAgreeTerms"),
          icon: "error",
          toast: true,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          position: "top-end"
        });
      }
    },
    async createToken() {
      this.loading = true;

      const { token, error } = await this.$stripe.createToken(this.cardNumber);
      if (error) {
        this.$swal({
          title: error.message,
          icon: "error",
          toast: true,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          position: "top-end"
        });

        this.loading = false;
        return;
      }
      this.subscribe(token.id);
    },
    async subscribe(cardToken) {
      const data = {
        customerId: this.customerId,
        priceId: this.priceId
      };
      createSubscription(data).then(reponse => {
        this.completePayment(reponse.data.clientSecret, cardToken);
      });
    },
    completePayment(clientSecret, cardToken) {
      this.$stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: {
              token: cardToken
            }
          }
        })
        .then(result => {
          if (result.error) {
            this.loading = false;
            this.$swal({
              title: `${this.$t("messeages.connectionSuccess.paymentError")}: ${
                result.error.message
              }`,
              icon: "error",
              toast: true,
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
              position: "top-end"
            });
          } else {
            this.paymentSucced = true;
          }
        });
    }
  }
};
</script>

<style lang="scss">
@import "./StripePayment.scss";
</style>
