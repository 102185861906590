export function setCookiesAuth(accessToken, refreshToken, user) {
  $cookies.set("accessToken", accessToken);
  $cookies.set("refreshToken", refreshToken);
  $cookies.set("user", user);
}

export function removeCookiesAuth() {
  $cookies.remove("accessToken");
  $cookies.remove("refreshToken");
  $cookies.remove("user");
}
