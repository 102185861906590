<template>
  <div class="pr-8">
    <v-menu min-width="220px" offset-y class="test">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <div class="py-2 px-2">
        <UserMenuList />
      </div>
    </v-menu>
  </div>
</template>

<script>
import UserMenuList from "@/components/Base/UserMenuList/UserMenuList.vue";

export default {
  components: {
    UserMenuList
  }
};
</script>

<style></style>
