<template>
  <v-menu offset-y class="UserLayoutMenu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon class="ml-2" v-bind="attrs" v-on="on" color="primary">
        <v-icon> mdi-dots-vertical </v-icon>
      </v-btn>
    </template>
    <div class="user-menu-tooltip text-right px-4">
      <router-link
        :to="{ name: 'ProfileSettings' }"
        class="d-block black--text text-underline my-2"
      >
        {{ $t("userComponent.settings") }}
      </router-link>
      <router-link
        :to="{ name: 'PasswordSettings' }"
        class="d-block black--text text-underline my-2"
      >
        {{ $t("userComponent.changePassword") }}
      </router-link>

      <v-divider class="my-2" />
      <a @click="logOut" class="d-block black--text text-underline my-2">
        {{ $t("common.logOut") }}
      </a>
      <v-divider class="my-2" />
      <MenuMobileLanguageSwitcher class="pb-2" />
    </div>
  </v-menu>
</template>

<script>
import MenuMobileLanguageSwitcher from "@/components/Base/MenuMobileLanguageSwitcher/MenuMobileLanguageSwitcher.vue";

export default {
  components: {
    MenuMobileLanguageSwitcher
  },
  methods: {
    logOut() {
      this.$store.dispatch("session/logout");
    }
  }
};
</script>

<style lang="scss">
@import "./UserLayoutMenu.scss";
</style>
