import { api } from "./api.js";
import { SESSION, USERS } from "@/constants/apiUrls";
import axiosErrorHandler from "@/helpers/axiosErrorHandler";

const logIn = (data) => {
  return api()
    .post(SESSION.LOGIN, data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};
const register = (data) => {
  return api()
    .post(USERS.ROOT, data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

const verifyToken = (token) => {
  return api()
    .post(SESSION.TOKEN_VERIFY, { token: token })
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

const refreshToken = (token) => {
  return api()
    .post(SESSION.TOKEN_REFRESH, { refresh: token })
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

const activate = (data) => {
  return api()
    .post(SESSION.ACTIVATE, data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const changePassword = (data) => {
  return api()
    .post(SESSION.PASSWORD_CHANGE, data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

const confirmReset = (data) => {
  return api()
    .post(SESSION.PASSWORD_RESET, data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export default {
  logIn,
  register,
  verifyToken,
  refreshToken,
  activate,
  confirmReset
};
