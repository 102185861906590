<template>
      <div>
        <p class="pb-2 font-weight-medium">{{ $t("whipround.author") }}</p>
        <p>
          {{ $t(whiproundAuthor) }}
        </p>
      </div>
</template>

<script>

export default {
  props: {
    author: String
  },
  computed: {
    whiproundAuthor() {
      if (!this.author) return "";
      return this.author;
    }
  }
};
</script>

<style>
</style>
