<template>
  <v-overlay class="admin-overlay" z-index="9">
    <v-card class="admin-overlay-card pa-4 pa-sm-8 pa-md-16" outlined light>
      <v-btn
        class="overlay-close-button"
        icon
        text
        color="black"
        @click="closeOverlay()"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <v-card-title class="mb-10">
        {{ $t("removeUserOverlay.title") }}
      </v-card-title>
      <v-card-text>
        <p class="mb-10" v-if="show">
          {{ $t("removeUserOverlay.areYouSure", { user: user.name }) }}
        </p>
        <div>
          <v-btn
            @click="removeUser"
            large
            class="bb-button-shadow"
            color="primary"
            :loading="loading"
          >
            {{ $t("common.imSure") }}
          </v-btn>
          <v-btn class="ml-6" :loading="loading" text @click="closeOverlay()">
            {{ $t("common.clear") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
import { deleteCompanyUser } from "@/services/companies";

export default {
  name: "RemoveUserOverlay",
  props: {
    user: Object
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    removeUser() {
      this.loading = true;

      const companyId = this.$store.state.session.userData.company.id;

      deleteCompanyUser(companyId, this.user.user.id).then(() => {
        this.$emit("refetchUsers");
        this.closeOverlay();
      });
      this.loading = false;
    },
    closeOverlay() {
      this.$emit("closeOverlay");
    }
  },
  computed: {
    show() {
      return !!this.user;
    }
  }
};
</script>

<style></style>
