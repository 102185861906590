import { api } from "./api.js";
import { PROFILE } from "@/constants/apiUrls";
import axiosErrorHandler from "@/helpers/axiosErrorHandler";

export const getProfiles = () => {
  return api()
    .get(PROFILE.ROOT)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getMyProfile = () => {
  return api()
    .get(PROFILE.ME)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const updateMyProfile = (data) => {
  return api()
    .patch(PROFILE.ME, data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getProfile = (email) => {
  return api()
    .get(PROFILE.SINGLE(email))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const updateProfile = (email, data) => {
  return api()
    .patch(PROFILE.SINGLE(email), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};
