export default {
  set_loading(state, loading) {
    state.loading = loading;
  },
  update_templates(state, templates) {
    state.templates = templates;
  },
  set_loading_template(state, loading) {
    state.loadingTemplate = loading;
  },
  update_template(state, template) {
    state.template = template;
  }
};
