<template>
  <div v-if="!loading">
    <transition name="fade">
      <RechargeOverlay
        v-if="overlayRecharge"
        @toggleOverlayRecharge="toggleOverlayRecharge"
        @recharge="donateCompany"
        groupRecharge
      />
    </transition>
    <v-container fluid class="px-6">
      <v-row>
        <v-col cols="12" md="12" lg="6">
          <AdminInformationCard
            :dashboardCompanyMenu="dashboardCompanyMenu"
            @toggleOverlayRecharge="toggleOverlayRecharge"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="3"
          v-for="progressBox in progressBoxes"
          :key="`progress-box-${progressBox.title}`"
        >
          <CircularProgressBox
            :title="progressBox.title"
            :percentProgress="progressBox.percentProgress"
            :percentProgressLabel="progressBox.percentProgressLabel"
            :maxNumber="progressBox.max"
            :maxLabel="progressBox.maxLabel"
            :tooltipText="progressBox.tooltipText"
            :formatAsCurrency="progressBox.formatAsCurrency"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <WhipRoundTable
            :title="$t('dashboard.completed')"
            :showMoreLink="{ name: 'Targets' }"
            :whipRounds="whiproundsInRealization"
            :loading="loadingWhiproundsInRealization"
          />
        </v-col>
        <v-col cols="12" md="6">
          <WhipRoundTable
            :title="$t('dashboard.inProgress')"
            :showMoreLink="{ name: 'Targets' }"
            :whipRounds="whiproundsInProgress"
            showBudget
            :loading="loadingWhiproundsInProgress"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-tour name="adminTour" :steps="steps" :callbacks="tourCallbacks" :options="tourOptions"></v-tour>
  </div>
</template>

<script>
import CircularProgressBox from "@/components/Admin/CircularProgressBox/CircularProgressBox.vue";
import WhipRoundTable from "@/components/Admin/WhipRoundTable/WhipRoundTable.vue";
import RechargeOverlay from "@/components/Base/RechargeOverlay/RechargeOverlay.vue";
import AdminInformationCard from "@/components/Admin/AdminInformationCard/AdminInformationCard.vue";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    CircularProgressBox,
    WhipRoundTable,
    RechargeOverlay,
    AdminInformationCard
  },
  data() {
    return {
      overlayRecharge: false,
      tourOptions: {
        labels: {
            buttonSkip: this.$t("tour.skipTour"),
            buttonPrevious: this.$t("tour.previous"),
            buttonNext: this.$t("tour.next"),
            buttonStop: this.$t("tour.finish")
        }
      },
      steps: [
        {
          header: {
            title: this.$t("tour.admin.hello"),
          },
          target: '#menu-groups',
          content: this.$t("tour.admin.groups")
        },
        {
          target: '#menu-users',
          content: this.$t("tour.admin.users")
        },
        {
          target: '#top-up-button',
          content: this.$t("tour.admin.top-up")
        },
        {
          target: '#menu-templates',
          content: this.$t("tour.admin.propositions")
        },
        {
          target: '#menu-targets',
          content: this.$t("tour.admin.whip-rounds")
        },
        {
          target: '#menu-admin-dots',
          content: this.$t("tour.admin.my-account")
        }
      ],
      tourCallbacks: {
        onStop: this.saveSkippingTourProperty
      }
    };
  },
  updated: function () {
      if (!localStorage.getItem('skipAdminTour')){
        this.$tours['adminTour'].start();
      }
  },
  beforeCreate() {
    this.$emit("change-page-name", "Dashboard");
  },
  created() {
    this.$store.dispatch("dashboard/getDashboardData");
  },
  methods: {
    donateCompany(data) {
      this.$store.dispatch("dashboard/donateCompany", data);
      this.toggleOverlayRecharge();
    },
    toggleOverlayRecharge() {
      this.overlayRecharge = !this.overlayRecharge;
    },
    saveSkippingTourProperty() {
      localStorage.setItem("skipAdminTour", "true");
    }
  },
  computed: {
    dashboardData() {
      return this.$store.getters["dashboard/dashboardData"];
    },
    ...mapGetters("dashboard", [
      "dashboardData",
      "loading",
      "whiproundsInProgress",
      "whiproundsInRealization",
      "loadingWhiproundsInProgress",
      "loadingWhiproundsInRealization"
    ]),

    dashboardCompanyMenu() {
      return [
        {
          name: this.$t("dashboard.dashboardCompanyMenu.first"),
          route: {
            name: "User",
            params: { id: this.$store.state.session.user.id }
          }
        },
        {
          name: this.$t("dashboard.dashboardCompanyMenu.second"),
          route: { name: "CompanySettings" }
        },
        {
          name: this.$t("dashboard.dashboardCompanyMenu.third"),
          route: { name: "Users" }
        }
      ];
    },
    progressBoxes() {
      if (this.loading) return [];

      return [
        {
          percentProgress: this.dashboardData.transactions.donated_amount,
          max: this.dashboardData.transactions.credited_amount,
          title: this.$t("dashboard.progressBox.first.title"),
          percentProgressLabel: this.$t(
            "dashboard.progressBox.first.percentProgressLabel"
          ),
          maxLabel: this.$t("dashboard.progressBox.first.maxLabel"),
          tooltipText: this.$t("dashboard.progressBox.first.tooltipText"),
          formatAsCurrency: true
        },
        {
          percentProgress: this.dashboardData.whip_rounds.completed,
          max: this.dashboardData.whip_rounds.all,
          title: this.$t("dashboard.progressBox.second.title"),
          percentProgressLabel: this.$t(
            "dashboard.progressBox.second.percentProgressLabel"
          ),
          maxLabel: this.$t("dashboard.progressBox.second.maxLabel"),
          tooltipText: this.$t("dashboard.progressBox.second.tooltipText")
        }
      ];
    }
  }
};
</script>

<style scoped lang="scss">
@import "./Dashboard.scss";</style
>>
