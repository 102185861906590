export const generateTreeselectOptions = categories => {
  const categoriesInTree = [];

  categories.forEach(category => {
    if (!category.parent) {
      categoriesInTree.push({
        id: category.id,
        label: category.name,
        children: []
      });
    }
  });

  categories.forEach(category => {
    if (category.parent) {
      const parentIndex = categoriesInTree.findIndex(parent => {
        return category.parent === parent.id;
      });

      categoriesInTree[parentIndex].children.push({
        id: category.id,
        label: category.name
      });
    }
  });

  return categoriesInTree;
};
