import LoggedUser from "@/layouts/LoggedUser/LoggedUser.vue";
import Dashboard from "@/views/default/Dashboard/Dashboard.vue";
import SingleWhip from "@/views/default/SingleWhip/SingleWhip.vue";
import Targets from "@/views/default/Targets/Targets.vue";
import AddTarget from "@/views/default/AddTarget/AddTarget.vue";
import PasswordSettings from "@/views/default/PasswordSettings/PasswordSettings.vue";
import ProfileSettings from "@/views/default/ProfileSettings/ProfileSettings.vue";
import Search from "@/views/default/Search/Search.vue";
import User from "@/views/default/User/User.vue";
import Group from "@/views/default/Group/Group.vue";
import AddGroup from "@/views/default/AddGroup/AddGroup.vue";
import Groups from "@/views/default/Groups/Groups";
import Templates from "@/views/default/Templates/Templates";
import Error from "@/views/session/Error/Error.vue";

export default {
  path: "/",
  redirect: "/dashboard",
  component: LoggedUser,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "/groups",
      name: "Groups",
      component: Groups
    },
    {
      path: "/propositions",
      name: "Templates",
      component: Templates
    },
    {
      path: "/add/group",
      name: "AddGroup",
      component: AddGroup
    },
    {
      path: "/user/:id",
      name: "User",
      component: User
    },
    {
      path: "/user-dashboard",
      name: "UserDashboard",
      component: Dashboard
    },
    {
      path: "/whip-round/:id",
      name: "SingleWhip",
      component: SingleWhip
    },
    {
      path: "/add/whip-round/:id?",
      name: "AddTarget",
      component: AddTarget
    },
    {
      path: "/whip-rounds",
      name: "Targets",
      component: Targets
    },
    {
      path: "/settings/password",
      name: "PasswordSettings",
      component: PasswordSettings
    },
    {
      path: "/settings/profile",
      name: "ProfileSettings",
      component: ProfileSettings
    },
    {
      path: "/search/:text",
      name: "Search",
      component: Search
    },
    {
      path: "/group/:id",
      name: "Group",
      component: Group
    },
    {
      beforeEnter(to, from, next) {
        window.open("https://budgetbee.io/faq/", '_blank');
      },
      path: "/faq",
      name: "FAQ"
    },
    {
      path: "*",
      name: "Error",
      component: Error
    }
  ]
};
