<template>
  <div class="text-center mt-8">
    <p class="mb-1">
      {{ $t("registerPage.main.description1") }}
    </p>
    <p class="">
      {{ $t("registerPage.main.description2") }}
    </p>
    <h1>{{ $t("registerPage.main.title") }}</h1>
    <v-container class="register-cards-container">
      <v-row class="mt-4">
        <v-col
          cols="12"
          sm="6"
          v-for="(card, index) in registerCards"
          :key="`register-card-${index}`"
        >
          <RegisterCard :card="card" />
        </v-col>
      </v-row>
    </v-container>
    <p class="mb-2">
      {{ $t("registerPage.main.gotAccount") }}
    </p>
    <v-btn color="primary" small router :to="{ name: 'Login' }" text>
      {{ $t("common.logIn") }}
    </v-btn>
  </div>
</template>

<script>
import RegisterCard from "@/components/Session/RegisterCard/RegisterCard.vue";

export default {
  name: "Register",
  components: {
    RegisterCard
  },
  data() {
    return {
      isValid: null,
      mail: "",
      gdprCheck: false
    };
  },
  computed: {
    registerCards() {
      return [
        {
          title: this.$t("registerPage.main.companyCard.title"),
          description: this.$t("registerPage.main.companyCard.description"),
          buttonText: this.$t("registerPage.main.companyCard.buttonText"),
          link: "RegisterCompany",
          icon: "bb-enterprise_icon"
        },
        {
          title: this.$t("registerPage.main.employeeCard.title"),
          description: this.$t("registerPage.main.employeeCard.description"),
          buttonText: this.$t("registerPage.main.employeeCard.buttonText"),
          link: "RegisterEmployee",
          icon: "bb-person_people"
        }
      ];
    }
  }
};
</script>
