import { api } from "./api.js";
import { PASSWORD } from "@/constants/apiUrls";
import axiosErrorHandler from "@/helpers/axiosErrorHandler";

export const changePassword = (data) => {
  return api()
    .post(PASSWORD.CHANGE, data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const resetPassword = (data) => {
  return api()
    .post(PASSWORD.RESET, data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const resetPasswordConfirm = (data) => {
  return api()
    .post(PASSWORD.CONFIRM, data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};
