export default {
  update_whipround(state, whipround) {
    state.whipround = whipround;
  },

  update_comments(state, comments) {
    state.comments = comments;
  },

  clear_whipround_data(state) {
    state.whipround = {};
    state.comments = [];
    state.donations = [];
    state.loadingDonations = true;
  },
  update_donations(state, donations) {
    state.donations = donations;
    state.loadingDonations = false;
  },
  update_stop_loading_comments(state) {
    state.loadingComments = false;
  }
};
