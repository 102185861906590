import { getDashboardData } from "@/services/dashboard";
import { getWhiprounds } from "@/services/whiprounds";
import { rechargeCompanyUsers } from "@/services/companies";

export default {
  donateCompany({ rootGetters, dispatch }, data) {
    const companyId = rootGetters["session/user"].company.id;

    rechargeCompanyUsers(companyId, data).then(() => {
      dispatch("getDashboard");
    });
  },
  getDashboardData({ commit, dispatch }) {
    dispatch("getDashboard");
    dispatch("getWhiprounds", { filter: 1, status: "InProgress" });
    dispatch("getWhiprounds", { filter: 2, status: "InRealization" });
  },

  getDashboard({ commit }) {
    getDashboardData().then(({ data }) => {
      commit("update_dashboard_data", data);
    });
  },
  getWhiprounds({ commit }, { filter, status }) {
    getWhiprounds({ pageSize: 2, filter }).then(({ data }) => {
      commit("saveWhiprounds", { status, whiprounds: data.results });
    });
  }
};
