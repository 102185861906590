<template>
  <v-container fluid class="mt-0 mt-md-10">
    <v-row>
      <v-col cols="12" md="7">
        <WelcomeCard
          v-if="!hideWelcomeCard"
          :username="username"
          @closeWelcomeCard="closeWelcomeCard"
        />
        <div class="dashboard-user-information" v-else>
          <UserInformation
            dashboardPage
            :firstName="user.first_name"
            :lastName="user.last_name"
            :mail="user.email"
            :wallet="user.balance"
            :roleId="user.type"
          />
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <AddWhipCard />
      </v-col>
    </v-row>
    <h2 class="py-10" id="templates">
      {{ $t("userDashboard.precretedTemplate") }}
    </h2>
    <transition name="fade">
      <v-row v-if="!loading">
        <v-col
          cols="12"
          sm="6"
          md="3"
          v-for="template in templates"
          :key="`template-card-${template.id}`"
        >
          <TemplateCard :template="template" />
        </v-col>
      </v-row>
      <div v-else class="d-flex justify-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </transition>
    <v-tour name="userTour" :steps="steps" :callbacks="tourCallbacks" :options="tourOptions"></v-tour>
  </v-container>
</template>

<script>
import WelcomeCard from "@/components/Default/WelcomeCard/WelcomeCard.vue";
import TemplateCard from "@/components/Default/TemplateCard/TemplateCard.vue";
import AddWhipCard from "@/components/Default/AddWhipCard/AddWhipCard.vue";
import UserInformation from "@/components/Admin/UserInformation/UserInformation.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserDashboard",
  components: {
    WelcomeCard,
    TemplateCard,
    AddWhipCard,
    UserInformation
  },
  created() {
    this.$store.dispatch("templates/getTemplates");
  },
  methods: {
    closeWelcomeCard() {
      this.hideWelcomeCard = true;
      this.$cookies.set("hideWelcomeCard");
    },
    saveSkippingTourProperty() {
      localStorage.setItem("skipUserTour", "true");
    }
  },
  data() {
    return {
      hideWelcomeCard: !!this.$cookies.get("hideWelcomeCard"),
      tourOptions: {
        labels: {
            buttonSkip: this.$t("tour.skipTour"),
            buttonPrevious: this.$t("tour.previous"),
            buttonNext: this.$t("tour.next"),
            buttonStop: this.$t("tour.finish")
        }
      },
      steps: [
        {
          header: {
            title: this.$t("tour.user.hello"),
          },
          target: '#menu-user',
          content: this.$t("tour.user.my-account")
        },
        {
          target: '#menu-templates',
          content: this.$t("tour.user.propositions")
        },
        {
          target: '#menu-targets',
          content: this.$t("tour.user.whip-rounds")
        },
      ],
      tourCallbacks: {
        onStop: this.saveSkippingTourProperty
      }
    };
  },
  computed: {
    ...mapGetters("templates", ["templates", "loading"]),
    username() {
      return this.$store.getters["session/firstName"];
    },
    user() {
      return this.$store.getters["session/user"];
    }
  },
  updated: function () {
    if (!localStorage.getItem('skipUserTour')){
      this.$tours['userTour'].start();
    }
  },
};
</script>

<style lang="sass">
.dashboard-user-information
  max-width: 700px

.tutorial-separate
  margin: 0.75rem 0 0.75rem 0

</style>
