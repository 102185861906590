<template>
  <v-container fluid class="AddGroup pa-6">
    <div class="mb-10">
      <v-form v-model="isValid" ref="groupNameForm">
        <p class="text-1">
          {{ $t("addGroup.groupName.label") }}
        </p>
        <v-text-field
          v-model="groupName"
          outlined
          :placeholder="$t('addGroup.groupName.placeholder')"
          dense
          class="add-mail-input input-white-background mb-1"
          autocomplete="off"
          :rules="[rules.required]"
        ></v-text-field>
        <p class="text-1">
          {{ $t("addGroup.members.label") }}
        </p>
        <v-autocomplete
          outlined
          v-model="groupUsers"
          :items="users"
          item-text="email"
          item-value="id"
          multiple
          chips
          deletable-chips
          class="bb-autocomplete add-mail-input input-white-background mb-1"
          :rules="[rules.userCountMin]"
        />
        <p class="text-1">
          {{ $t("addGroup.founder.label") }}
        </p>
        <v-select
          outlined
          v-model="founder"
          :items="users"
          item-text="email"
          item-value="id"
          :placeholder="$t('addGroup.founder.placeholder')"
          class="bb-autocomplete add-mail-input input-white-background mb-1"
          :rules="[rules.required]"
        />
      </v-form>
    </div>
    <div>
      <v-btn @click="addGroup" class="bb-button-shadow" large color="primary">
        {{ $t("addGroup.addButton") }}
      </v-btn>
      <v-btn @click="cancelAddGroup" class="ml-4" text color="#A0A0A0">
        {{ $t("common.clear") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import rules from "@/constants/rules";
import { addGroup } from "@/services/groups.js";
import { getCompanyUsers } from "@/services/companies.js";

export default {
  components: {},
  data() {
    return {
      founder: this.$store.getters["session/user"].id,
      groupName: "",
      groupUsers: [],
      rules: rules,
      isValid: false,
      addMailRules: rules.emailRules,
      users: []
    };
  },
  created() {
    this.getCompanyUsers();
  },
  methods: {
    handleAddGroup() {
      if (this.isValid) {
        this.sendDataToDatabase();
      }
    },
    addUsers(userList) {
      userList.forEach(user => {
        this.users.push(user.user);
      });
    },
    getCompanyUsers() {
      getCompanyUsers(this.$store.state.session.user.company.id, {pageSize: 999}).then(
        ({ data }) => {
          this.addUsers(data.results);
        }
      );
    },
    handleAddedUsers(value) {
      this.groupUsers = value;
    },
    addGroup() {
      this.$refs.groupNameForm.validate();
      this.handleAddGroup();
    },

    convertUsersIntoMembers() {
      let needFounder = true;

      const members = this.groupUsers.map(user => {
        if (user === this.founder) {
          needFounder = false;
          return { user, role: 1 };
        } else {
          return { user, role: 2 };
        }
      });

      if (needFounder) {
        members.push({ user: this.founder, role: 1 });
      }

      return members;
    },

    sendDataToDatabase() {
      const data = {
        name: this.groupName,
        members: this.convertUsersIntoMembers()
      };

      addGroup(data).then(({ data }) => {
        this.$router.push({
          name: "Group",
          params: {
            id: data.id
          }
        });
      });
    },
    cancelAddGroup() {
      this.$refs.AddUsersForm.deleteAllMails();
      this.groupName = "";
    }
  },
  beforeCreate() {
    this.$emit("change-page-name", this.$t("addGroup.pageName"));
  }
};
</script>

<style lang="scss">
@import "./AddGroup.scss";
</style>
