import { api } from "./api.js";
import { SEARCH } from "@/constants/apiUrls";
import axiosErrorHandler from "@/helpers/axiosErrorHandler";

export const search = (query) => {
  return api()
    .get(SEARCH.QUERY(query))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};
