import { getCompanyUsers } from "@/services/companies";

export default {
  getCompanyUsers({ commit, rootGetters }) {
    getCompanyUsers(rootGetters["session/user"].company.id, {
      pageSize: 999
    }).then((response) => {
      const { results } = response.data;

      commit("update_users", results);
    });
  }
};
