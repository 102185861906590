import countries from "i18n-iso-countries";
import pl from "i18n-iso-countries/langs/pl.json";
import en from "i18n-iso-countries/langs/en.json";

countries.registerLocale(pl);
countries.registerLocale(en);

import i18n from "@/i18n";

let countyList = Object.entries(
    countries.getNames(i18n.locale, { select: "official" })
).map(country => {
    return {
        iso: country[0],
        name: country[1]
    };
});

export default countyList;