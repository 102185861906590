import regex from "@/constants/regex.js";

export function checkMail(email) {
  return regex.mail.test(String(email).toLowerCase());
}

export function deepClone(value) {
  return JSON.parse(JSON.stringify(value));
}

export function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a target="_blank" href="' + url + '">' + url + "</a>";
  });
}
