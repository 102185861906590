<template>
  <v-overlay v-model="active" z-index="10" opacity="0.6">
    <v-card light outlined class="pa-4 pa-md-10" v-if="editingGroup">
      <div class="d-flex justify-space-between mb-10">
        <h2 class="heading-1 mb-2">
          {{ $t("editGroupOverlay.title") }}
        </h2>
        <v-btn @click="closeModal" class="close-modal" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-form ref="form" v-model="isValid">
        <p class="text-1 gray-text">
          {{ $t("editGroupOverlay.name") }}
        </p>
        <v-text-field
          :placeholder="$t('editGroupOverlay.name')"
          outlined
          v-model="editingGroup.name"
          :rules="[rules.required]"
          class="bb-input"
        />
        <p class="text-1 gray-text">
          {{ $t("editGroupOverlay.users.label") }}
        </p>

        <AddUsersInput
          class="mb-10"
          ref="addUser"
          :placeholder="$t('editGroupOverlay.users.placeholder')"
          :addedMails="editingGroup.members"
          @addedUsers="updateUserList"
          :hint="$t('editGroupOverlay.users.hint')"
          :rules="addMailRules"
        />
        <p class="text-1 gray-text">
          {{ $t("editGroupOverlay.users.label") }}
        </p>

        <v-select
          outlined
          v-model="editingGroup.founder"
          :items="users"
          item-text="user.email"
          item-value="user.id"
          :placeholder="$t('addGroup.founder.placeholder')"
          class="bb-autocomplete add-mail-input input-white-background mb-1"
          :rules="[rules.required]"
        />
      </v-form>

      <v-btn
        :loading="loading"
        @click="validateGroup"
        color="primary"
        class="bb-button-shadow"
        large
      >
        {{ $t("addTarget.saveChanges") }}
      </v-btn>
      <v-btn :loading="loading" @click="closeModal" text class="ml-6" large>
        {{ $t("common.clear") }}
      </v-btn>
    </v-card>
  </v-overlay>
</template>

<script>
import rules from "@/constants/rules";
import { updateGroup } from "@/services/groups";
import AddUsersInput from "@/components/Admin/AddUsersInput/AddUsersInput.vue";

export default {
  name: "EditGroupModal",
  props: {
    active: Boolean,
    group: Object,
    users: Array
  },
  components: {
    AddUsersInput
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      isValid: false,
      rules,
      addMailRules: rules.addUserInputRules
    };
  },
  computed: {
    editingGroup() {
      const editingGroup = Object.assign({}, this.group);
      editingGroup.founder = editingGroup.founder.id;

      editingGroup.members = this.getMembersData(editingGroup.members);

      return editingGroup;
    }
  },
  methods: {
    findUserById(id) {
      const foundUser = this.users.find(user => {
        return user.user.id === id;
      });
      if (foundUser) return foundUser.user.email;
      else return null;
    },
    getMembersData(users) {
      return users
        .map(member => {
          return this.findUserById(member);
        })
        .filter(user => {
          return !!user;
        });
    },

    async editGroup() {
      const members = await this.$store.dispatch(
        "group/getFullUsersList",
        this.editingGroup
      );
      const data = {
        name: this.editingGroup.name,
        members
      };

      updateGroup(this.id, data).then(() => {
        this.$emit("refetchGroup");
        this.closeModal();
      });
    },
    validateGroup() {
      this.$refs.form.validate();

      if (this.isValid) {
        this.editGroup();
      }
    },
    updateUserList(list) {
      this.editingGroup.members = list;
    },
    closeModal() {
      this.$emit("toggleEditGroup");
    }
  }
};
</script>

<style></style>
