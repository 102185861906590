<template>
  <transition name="fade">
    <div v-if="active" class="table-loader d-flex justify-center align-center">
      <v-progress-circular
        size="100"
        width="6"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TableLoader",

  props: {
    active: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss">
@import "./TableLoader.scss";
</style>
