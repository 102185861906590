export default {
  update_groups(state, groups) {
    state.groups = groups;
  },
  update_groups_paginated(state, groups) {
    state.paginatedGroups = groups;
  },
  update_groups_count(state, count) {
    state.count = count;
  }
};
