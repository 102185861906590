<template>
  <v-container fluid class="pa-6">
    <div class="d-flex justify-space-between align-center mb-13">
      <h2>
        {{ $t("recharges.title") }}
      </h2>
    </div>

    <v-simple-table class="bb-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t("recharges.fullName") }}
            </th>
            <th class="text-left">
              {{ $t("common.mail") }}
            </th>
            <th class="text-left">
              {{ $t("recharges.groups") }}
            </th>
            <th class="text-left">
              {{ $t("recharges.recharger") }}
            </th>
            <th class="text-left">
              {{ $t("common.date") }}
            </th>
            <th class="text-left">
              {{ $t("recharges.payment") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="`user-${user.id}`">
            <td class="font-weight-medium">
              {{ `${user.firstName} ${user.lastName}` }}
            </td>
            <td class="font-weight-medium">
              {{ user.mail }}
            </td>
            <td>
              <span
                v-for="(group, index) in user.groups"
                :key="`user-${user.id}-group-${group.id}`"
              >
                <router-link :to="{ name: 'Group', params: { id: group.id } }">
                  {{ group.name }}</router-link
                ><span v-if="index < user.groups.length - 1">, </span>
              </span>
            </td>
            <td class="font-weight-medium">
              {{ user.recharger }}
            </td>
            <td class="font-weight-medium">
              {{ user.date }}
            </td>
            <td class="font-weight-medium primary--text">
              + {{ user.payment }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
export default {
  name: "Recharges",
  data() {
    return {
      users: [
        {
          id: 8,
          firstName: "Siema",
          lastName: "Siemanowicz",
          mail: "test@test.pl",
          recharger: "Filip Tobiasz",
          date: "15/10/1997",
          payment: "7 PLN",
          groups: [
            { id: 0, name: "Frontendzi" },
            { id: 1, name: "Backendzie" }
          ]
        },
        {
          id: 8,
          firstName: "Siema",
          lastName: "Siemanowicz",
          mail: "test@test.pl",
          recharger: "Filip Tobiasz",
          date: "15/10/1997",
          payment: "7 PLN",
          groups: [
            { id: 0, name: "Frontendzi" },
            { id: 1, name: "Backendzie" }
          ]
        },
        {
          id: 8,
          firstName: "Siema",
          lastName: "Siemanowicz",
          mail: "test@test.pl",
          recharger: "Filip Tobiasz",
          date: "15/10/1997",
          payment: "7 PLN",
          groups: [
            { id: 0, name: "Frontendzi" },
            { id: 1, name: "Backendzie" }
          ]
        },
        {
          id: 8,
          firstName: "Siema",
          lastName: "Siemanowicz",
          mail: "test@test.pl",
          recharger: "Filip Tobiasz",
          date: "15/10/1997",
          payment: "7 PLN",
          groups: [
            { id: 0, name: "Frontendzi" },
            { id: 1, name: "Backendzie" }
          ]
        }
      ]
    };
  },
  beforeCreate() {
    this.$emit("change-page-name", this.$t("recharges.pageTitle"));
  }
};
</script>
