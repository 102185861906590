export default {
  clear_all_group_data(state) {
    state.group = {};
    state.targets = [];
    state.users = [];
    state.recharges = [];
    state.loadingTargets = true;
    state.loadingRecharges = true;
  },
  update_group(state, group) {
    state.group = group;
  },
  update_targets(state, targets) {
    state.targets = targets;
    state.loadingTargets = false;
  },
  update_recharges(state, recharges) {
    state.recharges = recharges;
    state.loadingRecharges = false;
  }
};
