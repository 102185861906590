<template>
  <v-container fluid class="pa-6">
    <EditUserOverlay
      @closeOverlay="clearEditingUser"
      v-if="!isEditingUserEmpty"
      :user="editingUser"
      :groups="groups"
      :checkIfOnlyOneAdmin="checkIfOnlyOneAdmin"
      @refetch="refetchUsers"
    />
    <RemoveUserOverlay
      v-if="!isRemovingUserEmpty"
      @closeOverlay="clearRemovingUser"
      @refetchUsers="refetchUsers"
      :user="removingUser"
    />
    <PageLoader :active="!users.length" />
    <transition name="fade">
      <div v-if="users.length">
        <div
          class="d-flex flex-column flex-md-row justify-space-between align-center mb-13"
        >
          <div class="full-width">
            <h2 class="text-left mb-4 mb-md-0">
              {{ $t("users.title") }}
            </h2>
          </div>
          <div
            class="d-flex flex-column flex-md-row align-start align-md-center justify-end full-width"
          >
            <v-select
              v-model="activeFilter"
              hide-details
              class="small-select mr-2 mb-2 mb-md-0"
              outlined
              :placeholder="$t('common.filterBy')"
              :items="filters"
              item-text="name"
              item-value="id"
            />
            <v-btn
              v-if="isAdmin"
              class="bb-button-shadow"
              router
              :to="{ name: 'InviteUser' }"
              large
              color="primary"
            >
              <v-icon left> bb-add </v-icon>
              {{ $t("users.addUser") }}
            </v-btn>
          </div>
        </div>

        <v-simple-table class="bb-table">
          <template v-slot:default>
            <TableLoader :active="loading" />
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("users.tableHeading.name") }}
                </th>
                <th class="text-left">
                  {{ $t("users.tableHeading.eMail") }}
                </th>
                <th class="text-left">
                  {{ $t("users.tableHeading.groups") }}
                </th>
                <th class="text-left">
                  {{ $t("common.role") }}
                </th>
                <th class="text-left"></th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in users" :key="`user-${user.user.id}`">
                <td class="font-weight-medium">
                  {{ user.user.full_name }}
                </td>
                <td class="font-weight-medium">
                  {{ user.user.email }}
                </td>
                <td>
                  <div v-if="user.groups.length > 0">
                    <span
                      v-for="(group, index) in user.groups"
                      :key="`user-${user.id}-group-${group.id}`"
                    >
                      <router-link
                        :to="{
                          name: 'Group',
                          params: { id: group.id }
                        }"
                      >
                        {{ group.name }}</router-link
                      ><span v-if="checkIfNotLastGroup(index, user.groups)"
                        >,
                      </span>
                    </span>
                  </div>
                  <span v-else>
                    {{ $t("common.lack") }}
                  </span>
                </td>
                <td>
                  <v-select
                    class="small-select py-3"
                    color="primary"
                    outlined
                    hide-details
                    :value="user.type"
                    :items="rolesTranslated"
                    item-text="name"
                    item-value="id"
                    :disabled="checkIfOnlyOneAdmin && user.type === 1"
                    @input="e => changeUserType(user.user.id, e, index)"
                  ></v-select>
                </td>
                <td>
                  <v-btn icon small>
                    <v-icon @click="editUser(user)" small>
                      bb-edit_file_icon
                    </v-icon>
                  </v-btn>
                  <v-btn icon small>
                    <v-icon @click="removeUser(user)" small>
                      bb-trash_icon
                    </v-icon>
                  </v-btn>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'User',
                      params: {
                        id: user.user.id
                      }
                    }"
                  >
                    {{ $t("common.more") }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <transition name="fade">
          <v-pagination
            v-if="showPagination"
            class="mt-4"
            v-model="page"
            :length="pagesAmount"
            :total-visible="7"
          />
        </transition>
      </div>
    </transition>
  </v-container>
</template>

<script>
import EditUserOverlay from "@/components/Admin/EditUserOverlay/EditUserOverlay.vue";
import RemoveUserOverlay from "@/components/Admin/RemoveUserOverlay/RemoveUserOverlay.vue";
import PageLoader from "@/components/Base/PageLoader/PageLoader.vue";
import TableLoader from "@/components/Base/TableLoader/TableLoader.vue";

import { roles } from "@/constants/roles.js";
import { getCompanyUsers, updateCompanyUser } from "@/services/companies";
import { pageSize } from "@/constants/apiParams";

export default {
  name: "Groups",
  components: {
    EditUserOverlay,
    RemoveUserOverlay,
    PageLoader,
    TableLoader
  },
  methods: {
    changeUserType(id, value) {
      if (value === 2 && this.checkIfOnlyOneAdmin) {
        this.$swal({
          icon: "error",
          title: this.$t("messeages.connnectionErrors.somethingGoneWrong"),
          text: this.$t("messeages.validationErrors.needToHaveOneAdmin")
        });
      } else {
        const data = {
          type: value
        };

        updateCompanyUser(this.companyId, id, data).then(() => {
          this.refetchUsers();
        });
      }
    },
    refetchUsers() {
      this.loading = true;
      this.getCompanyUsers();
    },
    getCompanyUsers() {
      getCompanyUsers(this.companyId, {
        filter: this.filterName,
        page: this.page
      }).then(({ data }) => {
        this.loading = false;
        this.pagesAmount = Math.ceil(data.count / pageSize);
        this.showPagination = data.count > pageSize;
        this.users = data.results;
      });
    },
    checkIfNotLastGroup(index, groups) {
      const groupsLength = groups.length;
      return index < groupsLength - 1;
    },
    clearEditingUser() {
      this.editingUser = {};
    },
    clearRemovingUser() {
      this.removingUser = {};
    },
    editUser(user) {
      this.editingUser = user;
    },
    removeUser(user) {
      this.removingUser = user;
    },
    changeFilter(value) {
      this.page = 1;
      if (!value) {
        this.filterName = "";
        this.refetchUsers();
        return;
      }
      const filter = this.filters.find(filter => {
        return filter.id == value;
      });
      this.filterName = filter.backendName;
      this.refetchUsers();
    }
  },
  created() {
    this.getCompanyUsers();

    this.$store.dispatch("groups/getGroups");
  },
  watch: {
    activeFilter(value) {
      switch (value) {
        case 1:
          this.changeFilter(value);
          break;
        case 2:
          this.changeFilter(value);
          break;
        default:
          this.changeFilter(null);
          break;
      }
    },
    page() {
      this.refetchUsers();
    }
  },
  data() {
    return {
      companyId: this.$store.state.session.user.company.id,
      loading: true,
      filterName: "",
      activeFilter: 0,
      roles: roles,
      editingUser: {},
      removingUser: {},
      users: [],
      page: 1,
      showPagination: false,
      pagesAmount: 0
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["session/isAdmin"];
    },
    rolesTranslated() {
      const list = [];

      roles.forEach(role => {
        list.push({
          id: role.id,
          name: this.$t(role.translationKey)
        });
      });

      return list;
    },
    groups() {
      return this.$store.getters["groups/groups"];
    },
    checkIfOnlyOneAdmin() {
      let adminsCount = 0;
      if (!this.users) return false;
      this.users.forEach(user => {
        if (user.type === 1) adminsCount++;
      });
      return adminsCount === 1;
    },
    filters() {
      return [
        { id: 1, name: this.$t("users.active"), backendName: "active" },
        { id: 2, name: this.$t("users.invited"), backendName: "invited" },
        { id: 3, name: this.$t("common.all") }
      ];
    },
    isEditingUserEmpty() {
      return Object.keys(this.editingUser).length === 0;
    },
    isRemovingUserEmpty() {
      return Object.keys(this.removingUser).length === 0;
    }
  },
  beforeCreate() {
    this.$emit("change-page-name", this.$t("users.title"));
  }
};
</script>
