<template>
  <v-container class="pa-6" fluid>
    <v-row>
      <div class="d-flex justify-space-between pt-4 pb-5">
        <h2>
          {{ $t("profileSettings.pageTitle") }}
        </h2>
      </div>
    </v-row>
    <v-form ref="form" v-model="isValid">
      <v-row>
        <v-col cols="12" md="6" lg="3">
          <p class="text-1 gray-text">
            {{ $t("common.firstName") }}
          </p>
          <v-text-field
            :rules="[rules.required, rules.maxSign(firstName, 30)]"
            v-model="firstName"
            :placeholder="$t('common.firstName')"
            class="bb-input"
            outlined
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <p class="text-1 gray-text">
            {{ $t("common.lastName") }}
          </p>
          <v-text-field
            :rules="[rules.required, rules.maxSign(sureName, 30)]"
            v-model="sureName"
            :placeholder="$t('common.lastName')"
            class="bb-input"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="2">
          <p class="text-1 gray-text">
            {{ $t("common.role") }}
          </p>
          <v-autocomplete
            v-model="role"
            :items="rolesTranslated"
            item-text="name"
            item-value="id"
            :placeholder="$t('common.role')"
            class="bb-input"
            disabled
            outlined
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <p class="text-1 gray-text">
            {{ $t("profileSettings.eMail") }}
          </p>
          <v-text-field
            :rules="rules.emailRules"
            v-model="eMail"
            readonly
            :placeholder="$t('profileSettings.eMail')"
            class="bb-input"
            outlined
          />
        </v-col>
      </v-row>
    </v-form>
    <v-btn
      @click="validateProfile"
      color="primary"
      class="bb-button-shadow mb-1 mr-2"
      large
    >
      {{ $t("common.saveChanges") }}
    </v-btn>
    <v-btn @click="fillFormWithDefaultData" text class="mb-1 mr-2" large>
      {{ $t("common.clear") }}
    </v-btn>
  </v-container>
</template>

<script>
import rules from "@/constants/rules";
import { roles } from "@/constants/roles";

export default {
  name: "ProfileSettings",
  data() {
    return {
      isValid: false,
      rules: rules,
      firstName: "",
      sureName: "",
      role: 0,
      roles: roles,
      eMail: ""
    };
  },
  computed: {
    user() {
      return this.$store.getters["session/user"];
    },
    rolesTranslated() {
      const list = [];

      roles.forEach(role => {
        list.push({
          id: role.id,
          name: this.$t(role.translationKey)
        });
      });

      return list;
    }
  },
  created() {
    this.fillFormWithDefaultData();
  },
  methods: {
    validateProfile() {
      this.validate();
      this.saveProfile();
    },
    validate() {
      this.$refs.form.validate();
    },
    saveProfile() {
      if (this.isValid) {
        const data = {
          first_name: this.firstName,
          last_name: this.sureName
        };

        this.$store.dispatch("session/updateMyProfile", data).then(() => {
          this.$swal({
            icon: "success",
            title: this.$t("messeages.connectionSuccess.updatedProfile"),
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          });
        });
      }
    },
    fillFormWithDefaultData() {
      const { first_name, last_name, email, type } = this.user;
      this.firstName = first_name;
      this.sureName = last_name;
      this.eMail = email;
      this.role = type;
    }
  },
  beforeCreate() {
    this.$emit("change-page-name", this.$t("profileSettings.pageTitle"));
  }
};
</script>

<style></style>
