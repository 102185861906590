<template>
  <v-row class="pt-16 justify-center payment-card">
    <v-col cols="12" sm="10" md="8">
      <v-card>
        <v-card-text class="black--text pa-4 pa-sm-6 pa-md-16">
          <h2 class="text-center mb-16">
            {{ $t("paymentCard.youBuying") }}
            <span class="primary--text">{{ price.nickname }}</span>
          </h2>
          <div class="d-flex justify-space-between font-weight-medium">
            <span class="grey--text">
              {{ $t("paymentCard.priceType") }}
            </span>
            <span> {{ price.nickname }} </span>
          </div>
          <v-divider class="mt-4 mb-8" />
          <div v-if="price.recurring">
            <div class="d-flex justify-space-between font-weight-medium">
              <span class="grey--text">
                {{ $t("paymentCard.billingPeriod") }}
              </span>
              <span>
                {{ billingPeriod }}
              </span>
            </div>
            <v-divider class="mt-4 mb-8" />
          </div>
          <div
            class="d-flex justify-space-between align-end font-weight-medium mt-16"
          >
            <span class="grey--text">
              {{ $t("paymentCard.totalBilled") }}
            </span>
            <div class="price primary--text">
              {{ formatCurrency(price.unit_amount / 100, price.currency) }}
            </div>
          </div>
          <v-divider class="mt-4 mb-8" />
          <StripePayment :customerId="customerId" :priceId="price.id" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import StripePayment from "@/components/Admin/StripePayment/StripePayment.vue";
import { formatCurrency } from "@/helpers/formatCurrency.js";

export default {
  props: ["price", "customerId"],
  components: {
    StripePayment
  },
  methods: {
    formatCurrency(value, currency) {
      return formatCurrency(value, currency);
    }
  },
  computed: {
    priceType() {
      return this.price.recurring
        ? this.$t("paymentCard.recurringPayment")
        : this.$t("paymentCard.oncePayment");
    },
    billingPeriod() {
      return this.price.recurring.interval === "month"
        ? this.$t("paymentCard.period.month")
        : this.$t("paymentCard.period.year");
    }
  }
};
</script>

<style lang="scss">
@import "./PaymentCard.scss";
</style>
