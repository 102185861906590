<template>
  <v-overlay v-model="active" opacity="1" color="white">
    <div class="d-flex justify-center">
      <v-progress-circular
        size="100"
        width="6"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: 'PageLoader',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style></style>
