import i18n from "@/i18n/index.js";

import { checkMail } from "@/helpers";

const rules = {
  required: (value) =>
    !!value || i18n.t("messeages.validationErrors.fieldIsRequired"),
  gdprRequired: (value) =>
    !!value || i18n.t("messeages.validationErrors.gdprNotChecked"),
  addUserInputRules: [
    (value) =>
      !value ||
      checkMail(value.replaceAll(",", "").trim()) ||
      i18n.t("messeages.validationErrors.wrongMail")
  ],
  emailRules: [
    (value) =>
      checkMail(value) || i18n.t("messeages.validationErrors.wrongMail")
  ],
  passwordRule: (value) =>
    value.length >= 8 ||
    i18n.t("messeages.validationErrors.passwordRequiresMoreSymbols"),
  passwordsMatch: (pass, confirm) => {
    return (
      pass == confirm ||
      i18n.t("messeages.validationErrors.passwordsDoesntMatch")
    );
  },
  maxSign: (value, max) =>
    value.toString().length < max ||
    i18n.t("messeages.validationErrors.tooMuchSign", { maxSigns: max }),
  userCountMin: (value) =>
    value.length > 0 || i18n.t("messeages.validationErrors.fieldIsRequired")
};

export default rules;
