<template>
  <div class="d-flex flex-column whip-description-content">
    <p class="font-weight-bold mb-4">
      {{ $t("whipDescription.title") }}
    </p>
    <p class="text-smaller mb-0 word-break" v-html="descriptionWithUrls"></p>
  </div>
</template>

<script>
import { urlify } from "@/helpers";

export default {
  props: {
    description: String
  },
  computed: {
    descriptionWithUrls() {
      if (!this.description) return "";
      const dividedByLineBreaks = urlify(this.description).split("\n");
      let html = "";

      dividedByLineBreaks.forEach(line => {
        html += `<p>${line}</p>`;
      });

      return html;
    }
  }
};
</script>

<style lang="scss">
.word-break {
  word-break: break-all;
}
.whip-description-content {
  max-width: 100%;
}
</style>
