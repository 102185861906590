<template>
  <v-overlay z-index="9999" class="EditWhipModal">
    <v-card light>
      <v-btn @click="cancelEditTarget" class="close-button" color="black" icon>
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <v-card-text
        class="d-flex justify-center align-center pa-6 pa-md-16 pb-md-10 "
      >
        <div class="full-width">
          <h2 class="mb-10 black--text">
            {{ $t("addTarget.editTitle") }}
          </h2>
          <v-form ref="form" v-model="isValid">
            <div class="addTarget">
              <p class="text-1 gray-text">
                {{ $t("addTarget.whatIsTarget") }}
              </p>
              <v-text-field
                :rules="[rules.required]"
                :placeholder="$t('addTarget.targetName')"
                v-model="editingWhip.name"
                outlined
                class="bb-input"
              />
              <p class="text-1 gray-text mt-2">
                {{ $t("addTarget.targetDesciption") }}
              </p>
              <v-textarea
                :rules="[rules.required]"
                v-model="editingWhip.description"
                :placeholder="$t('addTarget.targetDesciption')"
                outlined
                class="bb-input"
              />

              <ImageInput
                :placeholderImage="editingWhip.placeholderImage"
                @newImage="newImage"
              />

              <FilesToDownloadInput @newFiles="newFiles" />

              <v-row class="mt-2">
                <v-col cols="12" md="4">
                  <p class="text-1 gray-text">
                    {{ $t("addTarget.budget") }}
                  </p>
                  <v-text-field
                    :rules="[rules.required]"
                    v-model="editingWhip.goal"
                    :placeholder="$t('addTarget.budget')"
                    outlined
                    class="bb-input"
                    type="number"
                    step="1"
                    @wheel="$event.target.blur()"
                    min="0"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <p class="text-1 gray-text">
                    {{ $t("addTarget.currency") }}
                  </p>
                  <v-autocomplete
                    disabled
                    v-model="editingWhip.goal_currency"
                    :items="currencies"
                    :placeholder="$t('addTarget.currency')"
                    class="bb-input"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <p class="text-1 gray-text">
                    {{ $t("addTarget.status") }}
                  </p>
                  <v-select
                    :rules="[rules.required]"
                    v-model.number="editingWhip.status"
                    disabled
                    :items="targetStatuses"
                    item-text="name"
                    item-value="id"
                    :placeholder="$t('addTarget.status')"
                    class="bb-input"
                    outlined
                  />
                </v-col>
              </v-row>
              <p class="text-1">
                {{ $t("addTarget.groups") }}
              </p>
              <v-chip-group
                v-model="editingWhip.groups"
                column
                multiple
                class="mb-6"
              >
                <v-chip
                  v-for="group in groups"
                  :key="`chip-group-${group.id}`"
                  class="group-chip"
                >
                  {{ group.name }}
                </v-chip>
              </v-chip-group>

              <p class="text-1">
                {{ $t("addTarget.categories") }}
              </p>

              <treeselect
                class="mb-6"
                v-model="editingWhip.categories"
                :multiple="true"
                :options="categoriesInTree"
                :noChildrenText="
                  $t('messeages.validationErrors.noSubcategories')
                "
                :noOptionsText="$t('messeages.validationErrors.noCategories')"
                :noResultsText="$t('common.noResultsText')"
                :placeholder="$t('addTarget.categories')"
              />
            </div>
          </v-form>

          <v-btn
            @click="validateTarget"
            color="primary"
            class="bb-button-shadow"
            large
          >
            {{ $t("addTarget.saveChanges") }}
          </v-btn>
          <v-btn @click="cancelEditTarget" text class="ml-6" large>
            {{ $t("common.cancel") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
import rules from "@/constants/rules";
import currencies from "@/constants/currencies";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ImageInput from "@/components/Admin/ImageInput/ImageInput.vue";

import { targetStatuses } from "@/constants/targetStatuses";
import FilesToDownloadInput from "@/components/Admin/FilesToDownloadInput/FilesToDownloadInput.vue";

import { generateTreeselectOptions } from "@/helpers/treeselect";

export default {
  components: {
    Treeselect,
    ImageInput,
    FilesToDownloadInput
  },
  props: {
    whip: Object,
    groups: Array,
    categories: Array
  },
  data() {
    return {
      isValid: false,
      currencies: currencies,
      rules: rules,
      editingWhip: {},
      currency: "PLN",
      id: this.$route.params.id,
      files: []
    };
  },
  computed: {
    targetStatuses() {
      return targetStatuses.map(status => {
        status.name = this.$t(status.singleKey);
        return status;
      });
    },
    categoriesInTree() {
      return generateTreeselectOptions(this.categories);
    },
    whipStatuses() {
      return [
        { id: 0, name: this.$t("addTarget.whipInProgress") },
        { id: 1, name: this.$t("addTarget.whipClosed") }
      ];
    }
  },
  created() {
    this.editingWhip = Object.assign({}, this.whip);

    this.editingWhip.groups = [];
    const groups = [];
    this.whip.groups.forEach(group => {
      groups.push(
        this.groups.findIndex(foundGroup => {
          return foundGroup.id === group;
        })
      );
    });

    this.editingWhip.groups = groups;

    this.editingWhip.placeholderImage = this.whip.image;
    this.editingWhip.image = "";
  },
  methods: {
    newFiles(value) {
      this.files = value;
    },
    newImage(value) {
      this.image = value;
    },
    validateTarget() {
      this.$refs.form.validate();
      if (this.editingWhip.categories.length > 0) {
        if (this.isValid) this.editTarget();
      } else {
        this.$swal({
          title: this.$t("messeages.validationErrors.noCategorySelected"),
          icon: "error",
          toast: true,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          position: "top-end"
        });
      }
    },
    getSelectedGroups() {
      const groups = [];

      this.editingWhip.groups.forEach(index => {
        groups.push(this.groups[index].id);
      });

      return groups;
    },
    async editTarget() {
      if (this.image) {
        this.editingWhip.image = this.image;
      }
      let targetData = {
        editingWhip: this.editingWhip,
        groups: await this.getSelectedGroups(),
        files: this.files
      };

      this.$store
        .dispatch("whipround/updateWhip", targetData)
        .then(() => {
          this.cancelEditTarget();
        });
    },
    cancelEditTarget() {
      this.$emit("toggleEditWhip");
    }
  }
};
</script>

<style lang="scss">
@import "./EditWhipModal.scss";
</style>
