import axios from "axios";
import store from "@/store/index.js";
import i18n from "@/i18n/index.js";

export const api = (isFormData) => {
  if (store.getters["session/isLoggedIn"]) {
    return axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        "Accept-Language": i18n.locale,
        Accept: "application/json",
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
        Authorization: `Bearer ${store.state.session.accessToken}`
      }
    });
  } else {
    return axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        "Accept-Language": i18n.locale,
        Accept: "application/json",
        "Content-Type": isFormData ? "multipart/form-data" : "application/json"
      }
    });
  }
};

export const sessionApi = () => {
  return axios.create({
    baseURL: process.env.VUE_APP_SESSION_API_URL,
    headers: {
      "Accept-Language": i18n.locale,
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
};

export const paymentApi = () => {
  return axios.create({
    baseURL: process.env.VUE_APP_PAYMENT_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
};
