<template>
  <v-card outlined class="pa-3">
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div>
          <h4 class="black--text mb-2">{{ user.company.name }}</h4>
          <p class="mb-5 text-1">
            {{ $t("common.currentPlan") }}: {{ currentPlan.name }}
          </p>
        </div>
        <div>
          <v-menu min-width="220px" bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" id="menu-admin-dots">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <div class="white-background">
              <v-list>
                <v-list-item
                  v-for="item in dashboardCompanyMenu"
                  :key="`company-menu-${item.name}`"
                  router
                  :to="item.route"
                >
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </v-menu>
        </div>
      </div>

      <v-divider class="my-5" />
      <div class="d-flex mb-2 justify-space-between align-center">
        <p class="black--text mb-0">{{ $t("dashboard.recharge.text") }}:</p>
        <v-btn
          class="bb-button-shadow"
          @click="toggleOverlayRecharge"
          large
          color="primary" id="top-up-button"
        >
          <v-icon left x-small> bb-circle_add_icon </v-icon>
          {{ $t("dashboard.recharge.button") }}
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    dashboardCompanyMenu: Array
  },
  computed: {
    user() {
      return this.$store.getters["session/user"];
    },
    currentPlan() {
      return this.$store.getters["session/currentPlan"];
    }
  },
  methods: {
    toggleOverlayRecharge() {
      this.$emit("toggleOverlayRecharge");
    }
  }
};
</script>
