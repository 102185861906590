import { api } from "./api.js";
import { WHIPROUNDS } from "@/constants/apiUrls";
import axiosErrorHandler from "@/helpers/axiosErrorHandler";

export const addWhipround = (data) => {
  return api(true)
    .post(WHIPROUNDS.ROOT, data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getWhiprounds = (params) => {
  return api()
    .get(WHIPROUNDS.WITH_PARAMS(params))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getWhipround = (id) => {
  return api()
    .get(WHIPROUNDS.SINGLE(id))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getComments = (id) => {
  return api()
    .get(WHIPROUNDS.COMMENTS(id))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const addComment = (id, data) => {
  return api()
    .post(WHIPROUNDS.COMMENTS(id), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const updateWhip = (id, data) => {
  return api()
    .patch(WHIPROUNDS.SINGLE(id), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getWhipDonations = (id) => {
  return api()
    .get(WHIPROUNDS.DONATIONS_PARAMS(id))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const donateWhip = (id, data) => {
  return api()
    .post(WHIPROUNDS.DONATIONS(id), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};
