<template>
  <v-overlay class="admin-overlay" z-index="9">
    <v-card class="admin-overlay-card pa-4 pa-sm-8 pa-md-16" outlined light>
      <v-btn
        class="overlay-close-button"
        icon
        text
        color="black"
        @click="closeOverlay()"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <v-card-title class="mb-10">
        {{ $t("editUserOverlay.title") }}
      </v-card-title>
      <v-card-text v-if="editingUser">
        <v-form v-model="isValid" ref="form">
          <p class="text-1">
            {{ $t("common.firstName") }}
          </p>
          <v-text-field
            v-model="editingUser.firstName"
            outlined
            dense
            :placeholder="$t('common.firstName')"
            :rules="[rules.required]"
          ></v-text-field>
          <p class="text-1">
            {{ $t("common.lastName") }}
          </p>
          <v-text-field
            v-model="editingUser.lastName"
            outlined
            dense
            :placeholder="$t('common.lastName')"
            :rules="[rules.required]"
          ></v-text-field>
          <p class="text-1">
            {{ $t("editUserOverlay.roles") }}
          </p>
          <v-select
            class="small-select"
            color="primary"
            outlined
            item-text="name"
            item-value="id"
            v-model="editingUser.role"
            :items="roles"
            :rules="[rules.required]"
            :disabled="checkIfOnlyOneAdmin && editingUser.role == 1"
          ></v-select>
          <p class="text-1">
            {{ $t("editUserOverlay.groups") }}
          </p>
          <v-chip-group
            v-if="groups"
            v-model="editingUser.groups"
            column
            multiple
            class="mb-6"
          >
            <v-chip
              v-for="group in groups"
              :key="`chip-group-${group.id}`"
              class="group-chip"
            >
              {{ group.name }}
            </v-chip>
          </v-chip-group>
        </v-form>
        <div>
          <v-btn
            large
            class="bb-button-shadow"
            @click="editUser"
            color="primary"
          >
            {{ $t("common.saveChanges") }}
          </v-btn>
          <v-btn class="ml-6" text @click="closeOverlay()">
            {{ $t("common.cancel") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
import { roles } from "@/constants/roles.js";
import rules from "@/constants/rules";
import { updateCompanyUser } from "@/services/companies";

export default {
  name: "EditUserOverlay",
  props: {
    user: Object,
    groups: Array,
    checkIfOnlyOneAdmin: Boolean
  },
  methods: {
    getUserGroups() {
      const groups = [];
      this.editingUser.groups.forEach((test, index) => {
        const group = this.groups[index];
        groups.push(group.id);
      });
      return groups;
    },
    async sendDataToDatabase() {
      const groups = await this.getUserGroups();

      const companyId = this.$store.state.session.userData.company.id;
      const userId = this.editingUser.id;
      const data = {
        user: {
          first_name: this.editingUser.firstName,
          last_name: this.editingUser.lastName
        },
        type: this.editingUser.role,
        groups
      };

      updateCompanyUser(companyId, userId, data).then(() => {
        this.$emit("refetch");
        this.closeOverlay();
      });
    },
    editUser() {
      this.$refs.form.validate();

      if (this.isValid && this.editingUser.groups.length > 0) {
        this.sendDataToDatabase();
      }
      if (this.editingUser.groups.length === 0) {
        this.$swal({
          title: this.$t("messeages.validationErrors.noGroupSelected"),
          icon: "error",
          toast: true,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          position: "top-end"
        });
      }
    },
    closeOverlay() {
      this.$emit("closeOverlay");
    }
  },
  created() {
    this.editingUser.id = this.user.user.id;
    if (this.user.user.full_name) {
      const names = this.user.user.full_name.split(" ");
      this.editingUser.firstName = names[0];
      this.editingUser.lastName = names[1];
    } else {
      this.editingUser.firstName = "";
      this.editingUser.lastName = "";
    }
    this.editingUser.mail = this.user.user.email;
    this.editingUser.role = this.user.type;
    this.editingUser.groups = [];
    this.user.groups.forEach(group => {
      this.editingUser.groups.push(
        this.groups.findIndex(x => {
          return group.id === x.id;
        })
      );
    });
  },
  data() {
    return {
      rules: rules,
      groupsSelected: [],
      roles: roles,
      editingUser: {},
      isValid: false
    };
  },
  computed: {
    show() {
      return !!this.user;
    }
  }
};
</script>

<style></style>
