<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <h3>{{ title }}</h3>
      <router-link :to="showMoreLink">
        {{ $t("common.showMore") }}
      </router-link>
    </div>
    <v-divider class="my-4" />
    <v-card class="pa-3" outlined v-if="!loading && groups.length">
      <v-card-text>
        <v-simple-table v-if="whipRounds.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("common.whipround") }}
                </th>
                <th class="text-left">
                  {{ $t("common.group") }}
                </th>
                <th v-if="showBudget" class="text-left">
                  {{ $t("common.budget") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in whipRounds" :key="item.name">
                <td>
                  <router-link
                    :to="{ name: 'SingleWhip', params: { id: item.id } }"
                  >
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  <span
                    v-for="(group, index) in item.groups"
                    :key="`${item.id}-${group}`"
                  >
                    {{ getGroupName(group)
                    }}<span v-if="index < item.groups.length - 1">,</span>
                  </span>
                </td>
                <td v-if="showBudget">
                  <BudgetProgressBar
                    :done="item.balance.amount"
                    :max="item.goal"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p v-else class="mb-0  black--text">
          {{ $t("common.noWhiprounds") }}
        </p>
      </v-card-text>
    </v-card>

    <div class="d-flex justify-center full-width" v-else>
      <v-progress-circular color="primary" size="50" width="5" indeterminate />
    </div>
  </div>
</template>

<script>
import BudgetProgressBar from "@/components/Admin/BudgetProgressBar/BudgetProgressBar.vue";

export default {
  name: "WhipRoundTable",
  components: {
    BudgetProgressBar
  },
  methods: {
    getGroupName(id) {
      const found = this.groups.find(group => {
        return group.id === id;
      });
      if (found) {
        return found.name;
      } else {
        return this.$t("common.all");
      }
    }
  },
  computed: {
    groups() {
      return this.$store.getters["groups/groups"];
    }
  },
  created() {
    this.$store.dispatch("groups/getGroups");
  },
  props: {
    title: String,
    showMoreLink: Object,
    showBudget: Boolean,
    whipRounds: Array,
    loading: Boolean
  }
};
</script>
