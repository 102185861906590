<template>
  <v-app>
    <v-main>
      <div class="register-page">
        <v-container fluid>
          <v-row class="mb-16">
            <v-col cols="6" sm="3">
              <v-btn
                text
                color="primary"
                v-if="showGoBackButton"
                router
                :to="{ name: 'Login' }"
              >
                <v-icon left> mdi-keyboard-backspace </v-icon>
                {{ $t("common.return") }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="text-sm-center logo-on-bottom-mobile"
            >
              <router-link :to="{ name: 'Login' }">
                <img class="main-logo" src="@/assets/logo.svg" />
              </router-link>
            </v-col>
            <v-col cols="6" sm="3" class="d-flex justify-end">
              <LanguageSwitcher />
            </v-col>
          </v-row>
          <router-view />
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import LanguageSwitcher from "@/components/Session/LanguageSwitcher/LanguageSwitcher.vue";

export default {
  components: {
    LanguageSwitcher
  },
  computed: {
    showGoBackButton() {
      return this.$router.currentRoute.name !== "Login";
    }
  }
};
</script>
<style lang="scss">
@import "Session.scss";
</style>
