<template>
  <v-overlay class="admin-overlay" z-index="9">
    <v-card class="admin-overlay-card pa-4 pa-sm-8 pa-md-16" outlined light>
      <v-btn
        class="overlay-close-button"
        icon
        text
        color="black"
        @click="closeOverlay()"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <v-card-title class="mb-10">
        {{ $t("removeGroupOverlay.title") }}
      </v-card-title>
      <v-card-text>
        <p class="mb-10" v-if="show">
          {{ $t("common.areYouSureYouWantDelete") }} {{ group.name }}?
        </p>
        <div>
          <v-btn
            large
            class="bb-button-shadow"
            @click="removeGroup"
            color="primary"
          >
            {{ $t("common.imSure") }}
          </v-btn>
          <v-btn class="ml-6" text @click="closeOverlay()">
            {{ $t("common.clear") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: "RemoveGroupOverlay",
  props: {
    group: Object
  },
  methods: {
    closeOverlay() {
      this.$emit("closeOverlay");
    },
    removeGroup() {
      this.$store.dispatch("group/removeGroup", this.group.id).then(() => {
        this.$emit("refetch");
        this.closeOverlay();
      });
    }
  },
  computed: {
    show() {
      return Object.keys(this.group).length > 0;
    }
  }
};
</script>

<style></style>
