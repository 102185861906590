export default {
  user: (state) => state.user,
  loading: (state) => state.loading,

  whiprounds: (state) => state.whiprounds,
  whiproundsLoading: (state) => state.whiproundsLoading,

  donations: (state) => state.donations,
  donationsLoading: (state) => state.donationsLoading,

  recharges: (state) => state.recharges,
  rechargesLoading: (state) => state.rechargesLoading
};
