import alert from "sweetalert2";
import store from "../store";

export default (error) => {
  const { status, data } = error.response;
  if (status === 400) {
    if (data) {
      const error =
        typeof Object.entries(data)[0][1] === "string"
          ? Object.entries(data)[0][1]
          : Object.entries(data)[0][1][0];
      alert.fire({
        title: error,
        icon: "error",
        toast: true,
        timer: 4000,
        timerProgressBar: true,
        showConfirmButton: false,
        position: "top-end"
      });
    }
  } else if (status === 401 || status === 403) {
    store.dispatch("session/logout");
  } else {
    alert.fire({
      title: `Error ${status}`,
      icon: "error",
      confirmButtonText: "Ok"
    });
  }
};
