<template>
  <v-container class="pa-6" fluid>
    <v-form ref="form" v-model="isValid">
      <v-row>
        <v-col cols="12" md="6" lg="4" class="pb-0">
          <p class="text-1 gray-text">
            {{ $t("passwordSettings.currentPassword") }}
          </p>
          <v-text-field
            v-model="currentPassword.value"
            prepend-inner-icon="bb-padlock_icon"
            :placeholder="$t('passwordSettings.currentPassword')"
            class="bb-input"
            outlined
            :append-icon="
              currentPassword.isPasswordVisible
                ? 'bb-eye_closed_icon'
                : 'bb-eye_open_icon'
            "
            :type="currentPassword.isPasswordVisible ? 'text' : 'password'"
            @click:append="toggleShowPassword('currentPassword')"
            :rules="[rules.required, rules.passwordRule]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="4">
          <p class="text-1 gray-text">
            {{ $t("passwordSettings.newPassword") }}
          </p>

          <v-text-field
            v-model="newPassword.value"
            prepend-inner-icon="bb-padlock_icon"
            :placeholder="$t('passwordSettings.newPassword')"
            class="bb-input"
            outlined
            :append-icon="
              newPassword.isPasswordVisible
                ? 'bb-eye_closed_icon'
                : 'bb-eye_open_icon'
            "
            :type="newPassword.isPasswordVisible ? 'text' : 'password'"
            @click:append="toggleShowPassword('newPassword')"
            :rules="[rules.required, rules.passwordRule]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="4">
          <p class="text-1 gray-text">
            {{ $t("passwordSettings.confirmNewPassword") }}
          </p>
          <v-text-field
            v-model="confirmNewPassword.value"
            prepend-inner-icon="bb-padlock_icon"
            :placeholder="$t('passwordSettings.confirmNewPassword')"
            class="bb-input"
            outlined
            :append-icon="
              confirmNewPassword.isPasswordVisible
                ? 'bb-eye_closed_icon'
                : 'bb-eye_open_icon'
            "
            :type="confirmNewPassword.isPasswordVisible ? 'text' : 'password'"
            @click:append="toggleShowPassword('confirmNewPassword')"
            :rules="[
              rules.passwordsMatch(confirmNewPassword.value, newPassword.value)
            ]"
          />
        </v-col>
      </v-row>
    </v-form>

    <div>
      <v-btn
        @click="validateNewPassword"
        color="primary"
        class="bb-button-shadow mb-1 mr-2"
        large
      >
        {{ $t("passwordSettings.saveNewPassword") }}
      </v-btn>
      <v-btn @click="cancelNewPassword" text class="mb-1 mr-2" large>
        {{ $t("common.clear") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import rules from "@/constants/rules";

export default {
  name: "PasswordSettings",
  beforeCreate() {
    this.$emit("change-page-name", this.$t("passwordSettings.pageTitle"));
  },
  data() {
    return {
      rules: rules,
      isValid: false,
      currentPassword: {
        isPasswordVisible: false,
        value: ""
      },
      newPassword: {
        isPasswordVisible: false,
        value: ""
      },
      confirmNewPassword: {
        isPasswordVisible: false,
        value: ""
      }
    };
  },
  methods: {
    validateNewPassword() {
      this.validate();
      this.saveNewPassword();
    },
    validate() {
      this.$refs.form.validate();
    },
    saveNewPassword() {
      if (this.isValid) {
        const data = {
          old_password: this.currentPassword.value,
          new_password1: this.newPassword.value,
          new_password2: this.confirmNewPassword.value
        };

        this.$store.dispatch("session/changePassword", data);
      }
    },
    cancelNewPassword() {
      this.currentPassword.value = "";
      this.newPassword.value = "";
    },

    toggleShowPassword(key) {
      this[key].isPasswordVisible = !this[key].isPasswordVisible;
    }
  }
};
</script>

<style></style>
