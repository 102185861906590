<template>
  <v-list nav dense class="main-menu-list pa-0">
    <v-list-item
      v-for="item in menuItems"
      :key="`menu-item-${item.name}`"
      router
      :to="{ name: item.route, params: item.params }"
      class="mb-0"
    >
      <v-list-item-icon class="d-flex justify-center align-center mr-2">
        <v-icon small>
          {{ item.icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-title :id="'menu-' + item.route.toLowerCase()">
        {{ $t(item.name) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "MenuList",
  props: {
    menuItems: Array
  }
};
</script>

<style lang="scss">
@import "./MenuList.scss";
</style>
