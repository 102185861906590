var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 CircularProgressBox fill-height",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between flex-nowrap"},[_c('h4',[_vm._v(_vm._s(_vm.title))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" bb-info_icon ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltipText)+" ")])])],1),_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"mb-3",attrs:{"color":"primary","rotate":270,"size":"175","width":"10","value":_vm.progress}},[(_vm.enhancedInsideText)?_c('div',{staticClass:"progress-box-inside text-center"},[_c('div',{staticClass:"progress-box-enhanced-count"},[_vm._v(" "+_vm._s(_vm.percentProgress)+" "),_c('span',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.percentProgressLabel)+" ")])]),_c('v-divider',{staticClass:"mx-4 my-2"}),_c('div',{staticClass:"progress-box-enhanced-count"},[_vm._v(" "+_vm._s(_vm.maxNumber)+" "),_c('span',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.maxLabel)+" ")])])],1):_c('div',{staticClass:"progress-box-inside"},[_c('div',{staticClass:"progress-box-count",class:{
            smaller: _vm.formatAsCurrency
              ? _vm.formatCurrency(_vm.percentProgress)
              : _vm.percentProgress > 9
          }},[_vm._v(" "+_vm._s(_vm.formatAsCurrency ? _vm.formatCurrency(_vm.percentProgress) : _vm.percentProgress)+" "),_c('span',{staticClass:"mt-2"},[_vm._v("/ "+_vm._s(_vm.formatAsCurrency ? _vm.formatCurrency(_vm.maxNumber) : _vm.maxNumber))])])])])],1),_c('v-card-text',{staticClass:"pt-0"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"color-show color-show-green mr-2"}),_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.percentProgressLabel))])]),_c('div',{staticClass:"d-flex align-center ml-2"},[_c('div',{staticClass:"color-show color-show-gray mr-2"}),_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.maxLabel))])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }