<template>
  <v-overlay z-index="10" opacity="0.6">
    <v-card light outlined class="pa-md-10 overlay-recharge">
      <v-card-text class="black--text">
        <div class="d-flex justify-space-between">
          <h2 class="heading-1 mb-2">
            {{ $t("common.recharge") }}
          </h2>
          <v-btn @click="toggleOverlayRecharge" class="close-modal" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <p class="text-1 black--text" v-if="!userIsEmployee">
          {{ $t("dashboard.rechargeOverlay.perEmployee") }}
        </p>
        <v-row>
          <v-col cols="6" sm="4">
            <v-form v-model="isValid" ref="amount">
              <v-text-field
                type="number"
                outlined
                :rules="[rules.required]"
                v-model="amount"
                step="1"
                @wheel="$event.target.blur()"
                :placeholder="
                  $t('dashboard.rechargeOverlay.inputsLabels.amount')
                "
              />
            </v-form>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="mt1em">{{ currency }}</div>
            <!-- <v-select
              hide-details
              disabled
              outlined
              v-model="currency"
              :items="currencyList"
              :placeholder="
                $t('dashboard.rechargeOverlay.inputsLabels.currency')
              "
            /> -->
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">
              {{ $t("dashboard.rechargeOverlay.inputsLabels.cyclic") }}:
            </div>
            <div class="cyclic-payment-switch d-flex align-center mt-2">
              <p class="ma-0 text-1">
                {{ $t("common.no") }}
              </p>
              <v-switch
                hide-details
                class="mx-3 mt-0"
                v-model="isCyclic"
              ></v-switch>
              <p class="ma-0 text-1">
                {{ $t("common.yes") }}
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <transition name="fade">
              <div v-if="isCyclic">
                <div class="label">
                  {{ $t("dashboard.rechargeOverlay.inputsLabels.donation") }}:
                </div>
                <v-radio-group
                  :disabled="!isCyclic"
                  v-model="frequency"
                  hide-details
                  row
                  class="mb-8"
                >
                  <v-radio
                    :label="
                      $t('dashboard.rechargeOverlay.inputsLabels.radioWeek')
                    "
                    :value="1"
                  ></v-radio>
                  <v-radio
                    :label="
                      $t('dashboard.rechargeOverlay.inputsLabels.radioMonth')
                    "
                    :value="2"
                  ></v-radio>
                </v-radio-group>
              </div>
            </transition>
            <v-checkbox
              v-if="groupRecharge"
              v-model="isSplit"
              :label="
                $t('dashboard.rechargeOverlay.inputsLabels.splitCheckbox')
              "
            />

            <div class="d-flex mt-8">
              <v-btn @click="recharge" color="primary" v-if="!isCyclic">
                {{ $t("dashboard.recharge.button") }}
              </v-btn>
              <v-btn @click="recharge" color="primary" v-if="isCyclic">
                {{ $t("common.rechargeAndSetupCyclic") }}
              </v-btn>
              <v-btn text @click="toggleOverlayRecharge">
                {{ $t("common.clear") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
import currencies from "@/constants/currencies";

import rules from "@/constants/rules";

export default {
  props: {
    active: Boolean,
    userIsEmployee: Boolean,
    donateFunction: Function,
    groupRecharge: Boolean
  },
  data() {
    return {
      currency: "PLN",
      currencyList: currencies,
      amount: null,
      frequency: 1,
      isCyclic: false,
      rules: rules,
      isValid: false,
      isSplit: false
    };
  },
  methods: {
    toggleOverlayRecharge() {
      this.$emit("toggleOverlayRecharge");
    },
    recharge() {
      this.$refs.amount.validate();

      const data = {
        amount: this.amount,
        currency: this.currency,
        is_cyclic: this.isCyclic,
        periodicity: this.frequency
      };

      if (this.groupRecharge) {
        data.is_split = this.isSplit;
      }

      if (this.isValid) {
        this.$emit("recharge", data);
        this.$swal({
          title: this.$t("messeages.connectionSuccess.rechargeSucceeded"),
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000
        });
      }
    }
  }
};
</script>

<style>
  .mt1em{
    margin-top: 1em;
  }
</style>
