import Vue from "vue";
Vue.use(require("vue-cookies"));

export default {
  status: "",
  accessToken: $cookies.get("accessToken") || "",
  refreshToken: $cookies.get("refreshToken") || "",
  user: $cookies.get("user") || "",
  userData: null,
  autoRefreshInProgress: false
};
