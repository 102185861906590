<template>
  <div class="text-center mt-8">
    <h1 class="mb-5">{{ $t("resetPass.title") }}</h1>
    <p class="gray-text mb-16" v-html="$t('resetPass.description')"></p>
    <v-form
      v-model="isValid"
      ref="form"
      @submit.prevent="
        validate();
        resetPass(isValid);
      "
    >
      <v-text-field
        v-model="password"
        outlined
        prepend-inner-icon="mdi-lock"
        :placeholder="$t('resetPass.password')"
        :append-icon="passwordAppendIcon"
        :type="passwordInputType"
        @click:append="toggleShowPass"
        class="mail-input"
        :rules="[rules.required, rules.passwordRule]"
      />
      <v-text-field
        v-model="confirmPassword"
        outlined
        prepend-inner-icon="mdi-lock"
        :placeholder="$t('resetPass.confirm')"
        :append-icon="passwordAppendIcon"
        :type="passwordInputType"
        @click:append="toggleShowPass"
        class="mail-input"
        :rules="[
          rules.required,
          rules.passwordsMatch(password, confirmPassword)
        ]"
      />
    </v-form>
    <v-btn
      @click="
        validate();
        resetPass(isValid);
      "
      class="mb-8"
      large
      color="primary"
    >
      {{ $t("resetPass.button") }}
    </v-btn>
  </div>
</template>

<script>
import rules from "@/constants/rules";

export default {
  name: "ResetPassword",
  data() {
    return {
      uuid: this.$route.params.uuid,
      token: this.$route.params.token,
      rules,
      confirmPassword: "",
      password: "",
      isValid: null,
      mail: "",
      GDPRCheck: false,
      showPass: false
    };
  },
  computed: {
    passwordAppendIcon() {
      return this.showPass ? "mdi-eye-off" : "mdi-eye";
    },
    passwordInputType() {
      return this.showPass ? "text" : "password";
    }
  },

  methods: {
    toggleShowPass() {
      this.showPass = !this.showPass;
    },
    validate() {
      this.$refs.form.validate();
    },
    resetPass(validate) {
      if (validate) {
        const date = {
          uid: this.uuid,
          token: this.token,
          new_password1: this.password,
          new_password2: this.confirmPassword
        };

        this.$store.dispatch("session/confirmResetPassword", date).then(() => {
          this.$swal({
            icon: "success",
            title: this.$t("messeages.connectionSuccess.passwordReseted"),
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          });
          this.$router.push({ name: "Login" });
        });
      }
    },
    checkMail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-eval
      return re.test(String(email).toLowerCase());
    }
  }
};
</script>
