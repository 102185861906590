<template>
  <v-card
    outlined
    class="AddWhipCard d-flex justify-center align-center px-5 px-md-13 py-6"
  >
    <BeeHeartIcon class="BeeHeartIcon mr-5 mr-md-15" />
    <div>
      <h4 class="mb-5">
        {{ $t("addWhipCard.iDontKnow") }}
      </h4>
      <v-btn depressed small color="primary" router :to="{ name: 'AddTarget' }">
        {{ $t("addWhipCard.addWhip") }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import BeeHeartIcon from "@/components/Base/BeeHeartIcon/BeeHeartIcon.vue";

export default {
  components: {
    BeeHeartIcon
  }
};
</script>

<style lang="scss">
@import "./AddWhipCard.scss";
</style>
