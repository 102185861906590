export default {
  user: null,
  loading: true,

  donations: [],
  donationsLoading: true,
  recharges: [],
  rechargesLoading: true,
  whiprounds: [],
  whiproundsLoading: true
};
