<template>
  <div>
    <div class="d-flex justify-space-between align-end">
      <p class="mb-0 font-weight-medium">
        {{ title }}
      </p>
      <a class="body-2" v-if="isExpanded" @click="emitShowAll('close')">{{
        $t("common.collapse")
      }}</a>
      <a class="body-2" v-else @click="emitShowAll('targets')">{{
        $t("common.seeAll")
      }}</a>
    </div>
    <v-divider class="my-5" />
    <transition name="fade">
      <div v-if="!loading">
        <v-simple-table v-if="groups.length && targetsComputed.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ $t("listFundraising.name") }}
                </th>
                <th>
                  {{ $t("listFundraising.group") }}
                </th>
                <th>
                  {{ $t("listFundraising.progress") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="target in targetsComputed"
                :key="`target-${target.id}`"
              >
                <td :class="isGroupView ? 'big-table-col' : ''">
                  <router-link
                    :to="{
                      name: 'SingleWhip',
                      params: { id: target.id }
                    }"
                  >
                    {{ target.name }}
                  </router-link>
                </td>
                <td v-if="!isGroupView">
                  <span
                    v-for="(group, index) in target.groups"
                    :key="`${target.id}-${group}`"
                  >
                    {{ getGroupName(group) }}

                    <span v-if="index != target.groups.length - 1">,</span>
                  </span>
                </td>
                <td>
                  <BudgetProgressBar
                    :done="target.balance.amount"
                    :max="target.goal"
                  />
                </td>
                <td v-if="isGroupView" class="text-right">
                  <router-link
                    :to="{ name: 'SingleWhip', params: { id: target.id } }"
                  >
                    {{ $t("common.seeAll") }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p v-else>
          {{ $t("common.noWhiprounds") }}
        </p>
      </div>
    </transition>
    <div class="d-flex justify-center" v-if="loading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import BudgetProgressBar from "@/components/Admin/BudgetProgressBar/BudgetProgressBar.vue";

export default {
  name: "ListFundraising",
  props: {
    targets: Array,
    isExpanded: Boolean,
    title: String,
    loading: Boolean,
    isGroupView: Boolean
  },
  components: {
    BudgetProgressBar
  },
  computed: {
    targetsComputed() {
      return this.isExpanded ? this.targets : this.targets.slice(0, 5);
    },
    groups() {
      return this.$store.getters["groups/groups"];
    }
  },
  methods: {
    getGroupName(id) {
      const foundGroup = this.groups.find(group => {
        return group.id === id;
      });

      if (foundGroup) {
        return foundGroup.name;
      }
      return id;
    },
    emitShowAll(value) {
      this.$emit("changeShowElement", value);
    },
    goToWhip(id) {
      this.$router.push({
        name: "SingleWhip",
        params: {
          id
        }
      });
    }
  }
};
</script>

<style></style>
