<template>
  <v-container fluid class="AddGroup pa-6">
    <div class="d-flex justify-space-between align-center mb-13">
      <h2>
        {{ $t("groups.title") }}
      </h2>
      <v-btn
        class="bb-button-shadow"
        router
        :to="{ name: 'AddGroup' }"
        large
        color="primary"
      >
        <v-icon left> bb-group_icon </v-icon>
        {{ $t("groups.addGroup") }}
      </v-btn>
    </div>

    <GroupsTable @refetch="getGroups" :groups="paginatedGroups" />

    <v-pagination
      v-if="pages > 1"
      class="mt-4"
      v-model="page"
      :length="pages"
      :total-visible="7"
    />
  </v-container>
</template>

<script>
import { pageSize } from "@/constants/apiParams";
import { mapGetters } from "vuex";
import GroupsTable from "@/components/Base/GroupsTable/GroupsTable.vue";

export default {
  name: "Groups",
  components: {
    GroupsTable
  },
  data() {
    return {
      page: 1
    };
  },
  methods: {
    getGroups() {
      this.$store.dispatch("groups/getPaginatedGroups", {
        page: this.page
      });
    }
  },
  created() {
    this.getGroups();
  },
  watch: {
    page() {
      this.getGroups();
    }
  },
  computed: {
    ...mapGetters("groups", ["paginatedGroups", "count"]),
    pages() {
      return Math.ceil(this.count / pageSize);
    }
  },
  beforeCreate() {
    this.$emit("change-page-name", this.$t("groups.pageTitle"));
  }
};
</script>

<style></style>
