<template>
  <div>
    <EditGroupOverlay
      @closeOverlay="clearEditingGroup"
      :group="editingGroup"
      @refetch="refetch"
    />
    <transition name="fade">
      <RemoveGroupOverlay
        v-if="isRemovingGroup"
        @closeOverlay="clearRemovingGroup"
        :group="removingGroup"
        @refetch="refetch"
      />
    </transition>
    <v-simple-table class="bb-table" v-if="groups.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t("groups.tableHeading.name") }}
            </th>
            <th class="text-left">
              {{ $t("groups.tableHeading.members") }}
            </th>
            <th class="text-left">
              {{ $t("groups.tableHeading.whips") }}
            </th>
            <th class="text-left">
              {{ $t("groups.tableHeading.options") }}
            </th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="group in groups" :key="`group-${group.id}`">
            <td>
              {{ group.name }}
            </td>
            <td>
              {{ group.members_count }}
            </td>
            <td>{{ group.whip_rounds_count }}</td>
            <td>
              <div v-if="isAdmin || canEdit(group.founder)">
                <v-btn icon small>
                  <v-icon @click="editGroup(group)" small>
                    bb-edit_file_icon
                  </v-icon>
                </v-btn>
                <v-btn icon small>
                  <v-icon @click="removeGroup(group)" small>
                    bb-trash_icon
                  </v-icon>
                </v-btn>
              </div>
            </td>
            <td>
              <router-link :to="{ name: 'Group', params: { id: group.id } }">
                {{ $t("common.more") }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p v-else>
      {{ $t("common.noGroups") }}
    </p>
  </div>
</template>

<script>
import EditGroupOverlay from "@/components/Admin/EditGroupOverlay/EditGroupOverlay.vue";
import RemoveGroupOverlay from "@/components/Admin/RemoveGroupOverlay/RemoveGroupOverlay.vue";

export default {
  name: "GroupsTable",
  props: {
    groups: Array
  },
  components: {
    EditGroupOverlay,
    RemoveGroupOverlay
  },
  data() {
    return {
      editingGroup: {},
      removingGroup: {}
    };
  },
  methods: {
    canEdit(founderId) {
      return this.$store.getters["session/user"].id === founderId;
    },
    refetch() {
      this.$emit("refetch");
    },
    clearEditingGroup() {
      this.editingGroup = {};
    },
    clearRemovingGroup() {
      this.removingGroup = {};
    },
    editGroup(group) {
      this.editingGroup = group;
    },
    removeGroup(group) {
      this.removingGroup = group;
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["session/isAdmin"];
    },
    isRemovingGroup() {
      return Object.keys(this.removingGroup).length > 0;
    }
  }
};
</script>

<style></style>
