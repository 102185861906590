<template>
  <v-container fluid>
    <h2 class="pb-10" id="templates">
      {{ $t("templates.templates") }}
    </h2>
    <transition name="fade">
      <v-row v-if="!loading">
        <v-col
          cols="12"
          sm="6"
          md="3"
          v-for="template in templates"
          :key="`template-card-${template.id}`"
        >
          <TemplateCard :template="template" />
        </v-col>
      </v-row>
      <div v-else class="d-flex justify-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </transition>
  </v-container>
</template>

<script>
import TemplateCard from "@/components/Default/TemplateCard/TemplateCard.vue";
import AddWhipCard from "@/components/Default/AddWhipCard/AddWhipCard.vue";
import { mapGetters } from "vuex";

export default {
  name: "Templates",
  components: {
    TemplateCard,
    AddWhipCard
  },
  created() {
    this.$store.dispatch("templates/getTemplates");
  },
  computed: {
    ...mapGetters("templates", ["templates", "loading"])
  }
};
</script>

<style lang="sass">
.dashboard-user-information
  max-width: 700px
</style>
