<template>
  <div class="my-12">
    <p class="text-1 gray-text mt-6">
      {{ $t("addTarget.files") }}
    </p>

    <div class="mt-3 d-flex align-center mb-3">
      <label class="custom-file-upload" ref="customFileUpload">
        <input type="file" @change="processFiles($event)" multiple />

        <div class="file-input-button" v-ripple>
          <div class="fill-height white--text d-flex align-center">
            <v-icon small left color="white"> bb-pdf_file_icon </v-icon>
            {{ $t("addTarget.addFile") }}
          </div>
        </div>
      </label>
    </div>
    <transition name="fade">
      <div v-if="files.length > 0">
        <div
          class="d-flex align-center ml-4 mb-2"
          v-for="(file, index) in files"
          :key="`whip-added-file-${index}`"
        >
          <v-icon small> bb-pdf_file_icon </v-icon>
          <span class="ml-3 text-1">
            {{ file.name }} ({{ formatFileSize(file.size) }} kB)
          </span>
          <v-btn @click="removeFile(index)" icon x-small class="ml-2">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      files: []
    };
  },
  watch: {
    files: {
      deep: true,
      handler(value) {
        this.$emit("newFiles", value);
      }
    }
  },
  methods: {
    formatFileSize(size) {
      return (size / 1024).toFixed(2);
    },
    processFiles(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.files.push(event.target.files[0]);
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
    }
  }
};
</script>

<style></style>
