<template>
  <v-container class="px-6" fluid>
    <div v-if="showSettings">
      <v-row>
        <div class="d-flex justify-space-between pt-4 pb-5">
          <h2>
            {{ $t("companySettings.pageTitle") }}
          </h2>
        </div>
      </v-row>
      <v-form ref="form" v-model="isValid">
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="4">
            <p class="text-1 gray-text">
              {{ $t("companySettings.officialCompanyName") }}

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="ml-2" v-bind="attrs" v-on="on">
                    bb-info_icon
                  </v-icon>
                </template>
                <span>
                  {{ $t("companySettings.officialCompanyNameTooltip") }}
                </span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="settings.official_name"
              :rules="[rules.required, rules.maxSign(settings.name, 200)]"
              :placeholder="$t('companySettings.officialCompanyName')"
              class="bb-input"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="4">
            <p class="text-1 gray-text">
              {{ $t("companySettings.shortCompanyName") }}

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="ml-2" v-bind="attrs" v-on="on">
                    bb-info_icon
                  </v-icon>
                </template>
                <span>
                  {{ $t("companySettings.shortCompanyNameTooltip") }}
                </span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="settings.name"
              :rules="[rules.required, rules.maxSign(settings.name, 200)]"
              :placeholder="$t('companySettings.shortCompanyName')"
              class="bb-input"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" lg="8">
            <p class="text-1 gray-text">
              {{ $t("companySettings.companyAdress") }}
            </p>
            <v-text-field
              v-model="settings.address"
              :rules="[rules.maxSign(settings.address, 100)]"
              :placeholder="$t('companySettings.companyAdress')"
              class="bb-input"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" lg="2">
            <p class="text-1 gray-text">
              {{ $t("companySettings.city") }}
            </p>
            <v-text-field
              v-model="settings.city"
              :rules="[rules.maxSign(settings.city, 50)]"
              :placeholder="$t('companySettings.city')"
              class="bb-input"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <p class="text-1 gray-text">
              {{ $t("companySettings.postalCode") }}
            </p>
            <v-text-field
              v-model="settings.postal_code"
              :rules="[rules.maxSign(settings.postal_code, 10)]"
              :placeholder="$t('companySettings.postalCode')"
              class="bb-input"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <p class="text-1 gray-text">
              {{ $t("companySettings.voivodeship") }}
            </p>
            <v-text-field
              v-model="settings.province"
              :rules="[rules.maxSign(settings.province, 50)]"
              :placeholder="$t('companySettings.voivodeship')"
              class="bb-input"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <p class="text-1 gray-text">
              {{ $t("companySettings.country") }}
            </p>
            <v-autocomplete
              v-model="settings.country"
              :items="countriesList"
              item-text="name"
              item-value="iso"
              :placeholder="$t('companySettings.country')"
              class="bb-input"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="5" lg="3">
            <p class="text-1 gray-text">
              {{ $t("companySettings.taxNumber") }}
            </p>
            <v-text-field
              v-model="settings.tax_number"
              :rules="[rules.maxSign(settings.tax_number, 12)]"
              :placeholder="$t('companySettings.taxNumber')"
              class="bb-input"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <p class="text-1 gray-text">
              {{ $t("companySettings.timeZone") }}
            </p>

            <v-autocomplete
              disabled
              v-model="settings.default_timezone"
              :items="timeZones"
              :placeholder="$t('companySettings.timeZone')"
              class="bb-input"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="3" lg="2">
            <p class="text-1 gray-text">
              {{ $t("common.currency") }}
            </p>
            <v-autocomplete
              disabled
              v-model="settings.default_currency"
              :items="currencies"
              :placeholder="$t('common.currency')"
              class="bb-input"
              outlined
            />
          </v-col>
        </v-row>
      </v-form>
      <v-btn
        @click="validateSettings"
        color="primary"
        class="bb-button-shadow mb-1 mr-2"
        large
      >
        {{ $t("common.saveChanges") }}
      </v-btn>
      <v-btn @click="fillFormWithDefaultData" text class="mb-1 mr-2" large>
        {{ $t("common.clear") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import rules from "@/constants/rules";
import currencies from "@/constants/currencies";
import countries from "@/constants/countries";
import timeZones from "@/constants/timeZones";

export default {
  name: "companySettings",
  beforeCreate() {
    this.$emit("change-page-name", this.$t("companySettings.pageTitle"));
  },
  methods: {
    validateSettings() {
      this.validate();
      this.saveSettings();
    },
    validate() {
      this.$refs.form.validate();
    },
    saveSettings() {
      if (this.isValid) {
        const data = Object.assign({}, this.settings);

        this.$store.dispatch("session/updateCompany", data);
      }
    },
    fillFormWithDefaultData() {
      this.settings = Object.assign({}, this.company);
    }
  },
  data() {
    return {
      isValid: false,
      settings: {},
      currencies: currencies,
      rules: rules,
      timeZones: timeZones,
      countriesList: countries,
      defaultSettings: null
    };
  },
  computed: {
    company() {
      return this.$store.getters["session/user"].company;
    },
    showSettings() {
      return !!Object.keys(this.settings).length;
    }
  },
  created() {
    this.fillFormWithDefaultData();
  }
};
</script>
