<template>
  <div class="PaymentsList">
    <div class="d-flex justify-space-between align-end">
      <p class="mb-0 font-weight-medium">
        {{ $t("listOfPayments.title") }}
      </p>

      <a
        class="text-smaller"
        @click="emitShowAll(expand ? 'close' : 'payments')"
      >
        {{ expand ? $t("common.collapse") : $t("common.seeAll") }}
      </a>
    </div>
    <v-divider class="my-5" />
    <div v-if="!loading">
      <v-simple-table v-if="lastPayments.length">
        <template v-slot:default>
          <thead>
            <tr>
              <th v-if="showDetails">
                {{ $t("listOfPayments.whipName") }}
              </th>

              <th v-if="!showDetails">
                {{ $t("listOfPayments.member") }}
              </th>
              <th>
                {{ $t("common.date") }}
              </th>
              <th>
                {{ $t("listOfPayments.payment") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="payment in lastPayments" :key="payment.id">
              <td v-if="showDetails">
                {{ payment.whip_round.name }}
              </td>
              <td v-if="!showDetails">
                <router-link
                  :to="{
                    name: getRouteName(payment.author.type),
                    params: { id: payment.author.id }
                  }"
                >
                  {{ payment.author.full_name }}
                </router-link>
              </td>
              <td>
                {{ formatFromIsoToString(payment.date_created) }}
              </td>
              <td class="primary--text">
                {{ formatCurrency(payment.amount) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-else>
        {{ $t("common.noDonations") }}
      </p>
    </div>
    <div class="d-flex justify-center" v-else>
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "@/helpers/formatCurrency";
import { formatFromIsoToString } from "@/helpers/formatDate";

export default {
  name: "ListOfPayments",
  data() {
    return {
      formatCurrency,
      formatFromIsoToString
    };
  },
  props: {
    paymentsNotExpanded: Array,
    expand: Boolean,
    paymentsExpanded: Array,
    showDetails: Boolean,
    loading: Boolean
  },
  methods: {
    emitShowAll(value) {
      this.$emit("changeShowElement", value);
    },
    getRouteName(type) {
      return "User";
    }
  },
  computed: {
    lastPayments() {
      return this.expand ? this.paymentsExpanded : this.paymentsNotExpanded;
    }
  }
};
</script>

<style></style>
