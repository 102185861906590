<template>
  <div class="language-switcher mb-md-0 d-flex align-center">
    <span
      v-for="(lang, index) in langs"
      :key="`language-${lang}`"
      class="text-uppercase grey--text"
    >
      <span class="mx-1  font-weight-normal" v-if="index">|</span
      ><span
        class="single-lang font-weight-medium"
        @click="$i18n.locale = lang"
        :class="{
          'primary--text': $i18n.locale === lang,
          'font-weight-bold': $i18n.locale === lang
        }"
        >{{ lang }}</span
      >
    </span>
  </div>
</template>
<script>
export default {
  name: "LanguageSwitcher",
  computed: {
    langs() {
      return Object.keys(this.$i18n.messages);
    }
  }
};
</script>
<style scoped lang="scss">
@import "LanguageSwitcher.scss";
</style>
