import Vue from "vue";
import Vuex from "vuex";
import session from "./modules/session";
import adminPage from "./modules/admin-page";
import whipround from "./modules/whipround";

import search from "./modules/search";
import groups from "./modules/groups";
import categories from "./modules/categories";

import group from "./modules/group";
import dashboard from "./modules/dashboard";

import user from "./modules/user";
import templates from "./modules/templates";

import users from "./modules/users";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    session,
    adminPage,
    templates,
    whipround,
    groups,
    categories,
    group,
    users,
    dashboard,
    search,
    user
  }
});
