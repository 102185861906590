import { longListParams, getParamsForList } from "@/helpers/getParams";
import store from "@/store/index.js";

export const PASSWORD = {
  CHANGE: "/password/change/",
  RESET: "/password/reset/",
  CONFIRM: "/password/reset/confirm/"
};

export const CATEGORIES = {
  ROOT: `/categories/${longListParams}`
};

export const DASHBOARD = {
  ROOT: "/dashboard/"
};

export const SEARCH = {
  ROOT: `/search/`,
  QUERY: (query) => `/search/?q=${query}`
};

export const COMPANIES = {
  ROOT: "/companies/",
  SINGLE: (id) => `/companies/${id}/`,
  USERS_WITH_PARAMS: (id, params) =>
    `/companies/${id}/users/${getParamsForList(params)}`,
  USERS: (id) => `/companies/${id}/users/`,
  USER: (companyId, userId) => `/companies/${companyId}/users/${userId}/`,
  USER_WHIPS: (companyId, userId) =>
    `/companies/${companyId}/users/${userId}/whiprounds/`,
  USER_DONATIONS: (companyId, userId) =>
    `/companies/${companyId}/users/${userId}/donations/`,
  USER_RECHARGES: (companyId, userId) =>
    `/companies/${companyId}/users/${userId}/recharges/`,
  COMPANY_RECHARGE: (id) => `/companies/${id}/recharges/`
};

export const GROUPS = {
  ROOT: (params) => {
    return `/groups/${params ? getParamsForList(params) : longListParams}`;
  },
  GROUP: (id) => `/groups/${id}/`,
  GROUP_WHIPROUNDS: (id) => `/groups/${id}/whiprounds/`,
  GROUP_RECHARGES: (id) => `/groups/${id}/recharges/`,
  GROUP_MEMBERS: (id) => `/groups/${id}/members/`
};

export const PAYMENTS = {
  PRICES: () => `/prices?currency=${store.getters["session/paymentCurrency"]}`,
  SUBSCRIPTION: "/subscription"
};

export const PROFILE = {
  ROOT: "/profile/",
  ME: "/profile/me/",
  SINGLE: (email) => `/profile/${email}/`
};

export const SESSION = {
  LOGIN: "/login/",
  TOKEN_VERIFY: "/token/verify/",
  TOKEN_REFRESH: "/token/refresh/",
  ACTIVATE: "/invitations/accept/",
  PASSWORD_CHANGE: "/password/change/",
  PASSWORD_RESET: "/password/reset/confirm/"
};

export const USERS = {
  ROOT: "/users/",
  SINGLE: "/user/",
  VERIFY: "/users/verify-email/",
  SINGLE_PROFILE: (id) => `/profile/${id}/`
};

export const WHIPROUNDS = {
  ROOT: "/whiprounds/",
  WITH_PARAMS: (params) => `/whiprounds/${getParamsForList(params)}`,
  VERIFY: "/users/verify-email/",
  SINGLE: (id) => `/whiprounds/${id}/`,
  COMMENTS: (id) => `/whiprounds/${id}/comments/`,
  DONATIONS: (id) => `/whiprounds/${id}/donations/`,
  DONATIONS_PARAMS: (id) => `/whiprounds/${id}/donations/${longListParams}`
};

export const TEMPLATES = {
  ROOT: "/whipround-templates/",
  SINGLE: (id) => `/whipround-templates/${id}/`
};
