<template>
  <v-card outlined class="template-card">
    <v-img :src="template.image" height="180px" class="align-end">
      <div
        class="template-card-banner-badge d-flex justify-space-between align-center py-2 px-5"
      >
        <div>
          <p class="mb-0">
            {{ $t("whipCard.goal") }}:
            <span class="font-weight-medium">{{
              formatCurrency(template.goal, template.goal_currency)
            }}</span>
          </p>
          <p class="mb-0"></p>
        </div>
      </div>
    </v-img>
    <v-card-text>
      <p class="text-1 black--text mb-1 font-weight-medium">
        {{ template.name }}
      </p>
      <p class="body-2">
        {{ description }}
      </p>
      <router-link :to="{ name: 'AddTarget', params: { id: template.id } }">
        {{ $t("whipCard.createWhip") }}
      </router-link>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatCurrency } from "@/helpers/formatCurrency";

export default {
  name: "WhipCard",
  data() {
    return {
      formatCurrency
    };
  },
  props: {
    template: Object
  },
  computed: {
    description() {
      const { description } = this.template;

      if (!description) return "";

      if (description.length > 50) {
        return `${description.slice(0, 47)}...`;
      } else return description;
    }
  },
  methods: {
    getPercentValue(collected, needed) {
      return Math.ceil((collected / needed) * 100);
    }
  }
};
</script>

<style lang="scss">
@import "./TemplateCard.scss";
</style>
