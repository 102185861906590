import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "@/lang/en.json";
import pl from "@/lang/pl.json";

Vue.use(VueI18n);

const messages = {
  pl,
  en
};

export default new VueI18n({
  locale: "pl",
  messages
});
