import {
  getGroup,
  getGroupTargets,
  getGroupRecharges,
  rechargeGroup,
  removeGroup,
  updateGroup,
  removeUserFromGroup
} from "@/services/groups";

export default {
  async editGroup(_vuex, payload) {
    await updateGroup(payload.id, payload.data);
  },
  async removeGroup(_vuex, id) {
    await removeGroup(id);
  },
  deleteUser({ dispatch }, payload) {
    const { groupId, memeberId } = payload;

    const data = {
      data: {
        members: [memeberId]
      }
    };

    removeUserFromGroup(groupId, data).then(() => {
      dispatch("getGroup", groupId);
    });
  },
  loadGroup({ commit, dispatch }, id) {
    commit("clear_all_group_data");
    dispatch("getGroup", id);
    dispatch("getGroupTargets", id);
    dispatch("getGroupRecharges", id);
  },

  rechargeGroup({ commit, dispatch }, { data, id }) {
    rechargeGroup(id, data).then(() => {
      dispatch("getGroup", id);
      dispatch("getGroupRecharges", id);
    });
  },

  getGroup({ commit }, id) {
    getGroup(id).then((response) => {
      const { data } = response;

      const founder = data.members.find((member) => {
        return member.role.value === 1;
      });
      const group = {
        wallet: data.balance,
        founder: founder.user,
        name: data.name,
        users: data.members
      };
      commit("update_group", group);
    });
  },

  getGroupTargets({ commit }, id) {
    getGroupTargets(id).then((response) => {
      const { data } = response;
      commit("update_targets", data);
    });
  },

  getGroupRecharges({ commit }, id) {
    getGroupRecharges(id).then((response) => {
      const { data } = response;

      commit("update_recharges", data.results);
    });
  },

  findUserByMail({ state, rootGetters }, mail) {
    const users = rootGetters["users/users"];
    return users.find((user) => {
      return user.user.email.toLowerCase() === mail.toLowerCase();
    });
  },

  async findUserById({ state, rootGetters }, id) {
    const users = rootGetters["users/users"];
    return await users.find((user) => {
      return user.user.id === id;
    });
  },

  async getFullUsersList({ dispatch, state }, editingGroup) {
    const users = [];
    const founder = await dispatch("findUserById", editingGroup.founder);

    const founderId = founder.user.id;

    editingGroup.members.forEach(async (member) => {
      const user = await dispatch("findUserByMail", member);
      if (user) {
        const id = user.user.id;

        users.push({
          user: id,
          role: id === founderId ? 1 : 2
        });
      }
    });

    const gotFounder = users.findIndex((user) => {
      return user.role === 1;
    });

    if (!gotFounder) {
      users.push({
        user: founderId,
        role: 1
      });
    }

    return users;
  }
};
