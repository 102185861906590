import { api } from "./api.js";
import { DASHBOARD } from "@/constants/apiUrls";
import axiosErrorHandler from "@/helpers/axiosErrorHandler";

export const getDashboardData = () => {
  return api()
    .get(DASHBOARD.ROOT)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};
