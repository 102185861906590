<template>
  <div class="UserInformation">
    <div v-if="!dashboardPage">
      <div class="d-flex justify-space-between align-end">
        <p class="mb-0 font-weight-medium">
          {{ $t("userPage.userInformation") }}
        </p>
      </div>

      <v-divider class="my-5" />
    </div>
    <v-card flat class="d-flex flex-column flex-md-row">
      <v-card-text class="black--text pl-10 py-10 pr-10 pr-md-4">
        <div class="d-flex justify-space-between mb-2">
          <h4>
            {{ !firstName || !lastName ? mail : userDisplayName }}
          </h4>
          <v-btn v-if="isAdmin" icon small @click="toggleUserEdit">
            <v-icon small> bb-edit_file_icon </v-icon>
          </v-btn>
        </div>
        <p class="gray-text mb-2">
          {{ $t(roleTranslationKey) }}
        </p>
        <p
          class="mb-2  primary--text"
          :class="{
            'mb-md-12': !dashboardPage,
            'mb-md-0': dashboardPage
          }"
        >
          {{ mail }}
        </p>
        <div v-if="!dashboardPage">
          <p class="font-weight-medium mb-6">{{ $t("userPage.groups") }}:</p>

          <v-chip
            v-for="group in groupsComputed"
            :key="`category-chip-${group.id}`"
            class="highlited-chip ml-1 mb-1"
            @click="navigateToGroup(group.id)"
          >
            {{ group.name }}
          </v-chip>

          <v-btn
            v-if="onlyFourGroups.length != groups.length && !expandedGroups"
            class="chip-size ml-1 mb-1"
            color="gray"
            text
            @click="handleExpandGroups"
          >
            <v-icon> mdi-dots-horizontal </v-icon>
          </v-btn>
        </div>
      </v-card-text>
      <div class="card-vertical-divider" />
      <v-card-text class="black--text wallet-card pr-10 py-10 pl-10 pl-md-4">
        <Wallet
          @toggleOverlayRecharge="toggleOverlayRecharge"
          :wallet="wallet"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Wallet from "@/components/Admin/Wallet/Wallet.vue";
import { roles } from "@/constants/roles";

export default {
  name: "UserInformation",
  components: {
    Wallet
  },
  props: {
    firstName: String,
    lastName: String,
    mail: String,
    groups: Array,
    wallet: Object,
    roleId: Number,
    dashboardPage: Boolean
  },
  data() {
    return {
      expandedGroups: false
    };
  },
  computed: {
    userDisplayName() {
      return `${this.firstName} ${this.lastName}`;
    },
    isAdmin() {
      return this.$store.getters["session/isAdmin"];
    },
    roleTranslationKey() {
      const found = roles.find(role => {
        return role.id === this.roleId;
      });

      return found ? found.translationKey : "roles.employee";
    },
    onlyFourGroups() {
      return this.groups.slice(0, 4);
    },
    groupsComputed() {
      return this.expandedGroups ? this.groups : this.onlyFourGroups;
    }
  },
  methods: {
    navigateToGroup(id) {
      $router.push({
        name: "Group",
        params: { id }
      });
    },
    handleExpandGroups() {
      this.expandedGroups = !this.expandedGroups;
    },
    toggleOverlayRecharge() {
      this.$emit("toggleOverlayRecharge");
    },
    toggleUserEdit() {
      this.$emit("toggleEditUser");
    }
  }
};
</script>

<style lang="scss">
@import "./UserInformation.scss";
</style>
