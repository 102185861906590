<template>
  <div class="WhipCategories">
    <p class="text-smaller font-weight-medium">
      {{ $t("whipCategories.title") }}:
    </p>
    <div class="ml-n1">
      <v-chip
        v-for="category in categories"
        :key="`category-chip-${category.id}`"
        class="whip-category-chip ml-1 mb-1"
      >
        {{ category.name }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: Array
  }
};
</script>

<style lang="scss">
@import "./WhipCategories.scss";
</style>
