import { getGroups } from "@/services/groups";

export default {
  getGroups({ commit }) {
    getGroups().then((response) => {
      commit("update_groups", response.data.results);
    });
  },
  getPaginatedGroups({ commit }, payload) {
    getGroups(payload).then((response) => {
      commit("update_groups_paginated", response.data.results);
      commit("update_groups_count", response.data.count);
    });
  }
};
