<template>
  <div class="vertical-divider"></div>
</template>

<script>
export default {
  name: "VerticalDivider"
};
</script>

<style>
@import "./VerticalDivider.scss";
</style>
