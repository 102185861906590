<template>
  <v-navigation-drawer
    top="20px"
    permanent
    touchless
    width="135px"
    class="DesktopMenu d-none d-md-flex"
    :class="{
      '--user': user
    }"
    fixed
  >
    <slot />
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "DesktopMenu",
  props: { user: Boolean }
};
</script>

<style lang="scss">
@import "./DesktopMenu.scss";
</style>
