import { api } from "./api.js";
import { TEMPLATES } from "@/constants/apiUrls";
import axiosErrorHandler from "@/helpers/axiosErrorHandler";

export const getTemplates = () => {
  return api()
    .get(TEMPLATES.ROOT)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getTemplate = (id) => {
  return api()
    .get(TEMPLATES.SINGLE(id))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};
