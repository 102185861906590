<template>
  <div>
    <PageLoader
      :active="groups.length == 0 || categories.length == 0 || loadingTemplate"
    />
    <v-container
      class="pa-6"
      fluid
      v-if="groups.length > 0 && categories.length > 0 && !loadingTemplate"
    >
      <div class="mb-5" v-if="gotTemplate">
        <v-btn color="primary" @click="cancelAddTemplate">
          {{ $t("addTarget.noTemplate") }}
        </v-btn>
      </div>

      <v-form ref="form" v-model="isValid">
        <div class="AddTarget">
          <p class="text-1 gray-text">
            {{ $t("addTarget.whatIsTarget") }}
          </p>
          <v-text-field
            :rules="[rules.required]"
            :placeholder="$t('addTarget.targetName')"
            v-model="targetName"
            outlined
            class="bb-input"
          />
          <p class="text-1 gray-text mt-2">
            {{ $t("addTarget.targetDesciption") }}
          </p>
          <v-textarea
            :rules="[rules.required]"
            v-model="targetDesciption"
            :placeholder="$t('addTarget.targetDesciption')"
            outlined
            class="bb-input"
          />
          <ImageInput
            ref="imageInput"
            :placeholderImage="template.image"
            @newImage="newImage"
          />
          <FilesToDownloadInput @newFiles="newFiles" />
          <v-row class="mt-6">
            <v-col cols="12" md="4">
              <p class="text-1 gray-text">
                {{ $t("addTarget.budget") }}
              </p>
              <v-text-field
                :rules="[rules.required]"
                v-model="budget"
                :placeholder="$t('addTarget.budget')"
                outlined
                class="bb-input"
                type="number"
                step="1"
                @wheel="$event.target.blur()"
                min="0"
              />
            </v-col>
            <v-col cols="12" md="4">
              <p class="text-1 gray-text">
                {{ $t("addTarget.currency") }}
              </p>
              <v-autocomplete
                disabled
                v-model.number="currency"
                :items="currencies"
                :placeholder="$t('addTarget.currency')"
                class="bb-input"
                outlined
              />
            </v-col>
          </v-row>
          <p class="text-1">
            {{ $t("addTarget.groups") }}
          </p>
          <v-chip-group v-model="groupsSelected" column multiple class="mb-6">
            <v-chip
              v-for="(group, index) in groups"
              :key="`chip-group-${index}`"
              class="group-chip"
            >
              {{ group.name }}
            </v-chip>
          </v-chip-group>
          <p class="text-1">
            {{ $t("addTarget.categories") }}
          </p>
          <treeselect
            class="mb-6"
            v-model="categoriesSelected"
            :multiple="true"
            :options="categoriesInTree"
            :noChildrenText="$t('messeages.validationErrors.noSubcategories')"
            :noOptionsText="$t('messeages.validationErrors.noCategories')"
            :noResultsText="$t('common.noResultsText')"
            :placeholder="$t('addTarget.categories')"
          />
        </div>
      </v-form>
      <v-btn
        :loading="loading"
        @click="addTarget"
        color="primary"
        class="bb-button-shadow mb-1 mr-2"
        large
      >
        {{ $t("addTarget.addButton") }}
      </v-btn>
      <v-btn
        :loading="loading"
        @click="cancelAddTarget"
        text
        class="mb-1 mr-2"
        large
      >
        {{ $t("common.clear") }}
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import rules from "@/constants/rules";
import currencies from "@/constants/currencies";
import { getCategories } from "@/services/categories";
import { addWhipround } from "@/services/whiprounds";
import ImageInput from "@/components/Admin/ImageInput/ImageInput.vue";
import FilesToDownloadInput from "@/components/Admin/FilesToDownloadInput/FilesToDownloadInput.vue";
import PageLoader from "@/components/Base/PageLoader/PageLoader.vue";
import { parseIntWithDecimal } from "@/helpers/formatCurrency";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import { mapGetters } from "vuex";

import { generateTreeselectOptions } from "@/helpers/treeselect";

export default {
  name: "AddTarget",
  components: {
    ImageInput,
    FilesToDownloadInput,
    PageLoader,
    Treeselect
  },
  computed: {
    ...mapGetters("templates", ["template", "loadingTemplate"]),
    groups() {
      return this.$store.getters["groups/groups"];
    },
    categoriesInTree() {
      return generateTreeselectOptions(this.categories);
    },
    gotTemplate() {
      return Object.keys(this.template).length;
    }
  },

  watch: {
    // TO REFACTOR: SZYMON DISAPROVES
    template(value) {
      if (Object.entries(value).length) {
        this.categoriesSelected = value.categories;
        this.budget = value.goal;
        this.targetName = value.name;
        this.targetDesciption = value.description;

        if (value.image) {
          fetch(value.image).then(async response => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], `whipround-image.jpg`, {
              contentType
            });
            this.templateImage = file;
          });
        }
      }
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      currencies: currencies,
      rules: rules,
      isValid: false,
      currency: "PLN",
      targetName: "",
      targetDesciption: "",
      budget: 0.0,
      groupsSelected: [],
      categoriesSelected: [],
      categories: [],
      loading: false,
      templateImage: null,
      image: null,
      files: []
    };
  },

  methods: {
    cancelAddTemplate() {
      // this.$router.go({ name: "AddTarget" });
      // this.$router.go(this.$router.currentRoute);
      this.$store.dispatch("templates/getTemplate", null);

      this.categoriesSelected = [];
      this.budget = 0;
      this.targetName = "";
      this.targetDesciption = "";
      this.templateImage = null;
      this.$refs.imageInput.setDeafultPlaceholder();
    },
    getSelectedGroups() {
      const groups = [];

      this.groupsSelected.forEach(index => {
        groups.push(this.groups[index].id);
      });

      return groups;
    },
    async sendDataToDatabase() {
      this.loading = true;
      const data = new FormData();
      const groups = await this.getSelectedGroups();

      const goal = parseIntWithDecimal(this.budget, 2);

      data.append("name", this.targetName);
      data.append("description", this.targetDesciption);
      data.append("goal", goal);

      if (this.image) {
        data.append("image", this.image);
      } else if (this.templateImage) {
        data.append("image", this.templateImage);
      }

      if (this.files.length > 0) {
        this.files.forEach(file => {
          data.append("files", file);
        });
      }

      if (this.groups.length) {
        groups.forEach(group => {
          data.append("groups", group);
        });
      }

      this.categoriesSelected.forEach(category => {
        data.append("categories", category);
      });

      if (Object.entries(this.template).length) {
        data.append("template", this.template.id);
      }

      addWhipround(data)
        .then(({ data }) => {
          const { id } = data;
          this.$router.push({ name: "SingleWhip", params: { id } });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    addTarget() {
      this.$refs.form.validate();

      if (this.categoriesSelected.length > 0) {
        if (this.isValid) {
          this.sendDataToDatabase();
        }
      } else {
        this.$swal({
          title: this.$t("messeages.validationErrors.noCategorySelected"),
          icon: "error",
          toast: true,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          position: "top-end"
        });
      }
    },
    getCategories() {
      getCategories().then(({ data }) => {
        const { results } = data;

        this.categories = results;
      });
    },
    newFiles(value) {
      this.files = value;
    },
    newImage(value) {
      this.image = value;
    },
    cancelAddTarget() {
      this.targetName = "";
      this.targetDesciption = "";
      this.budget = 0.0;
      this.groupsSelected = [];
    },

    validate() {
      this.$refs.form.validate();
    }
  },
  created() {
    this.$store.dispatch("groups/getGroups");

    this.getCategories();
    this.$store.dispatch("templates/getTemplate", this.id);
  },
  beforeCreate() {
    this.$emit("change-page-name", this.$t("addTarget.pageTitle"));
  }
};
</script>

<style lang="scss">
@import "./AddTarget.scss";
</style>
