import session from "@/store/modules/session/state";
import regex from "@/constants/regex";
import i18n from "@/i18n";

export function formatCurrency(value, customCurrency) {
  const { country, default_currency } = session.userData.company;

  return new Intl.NumberFormat(country ? country : i18n.locale, {
    style: "currency",
    currency: customCurrency ? customCurrency : default_currency
  }).format(value);
}

export function parseIntWithDecimal(amount, decimalZeros = 2) {
  return parseFloat(amount.replace(/,/g, ".")).toFixed(decimalZeros);
}

export function parseCurrencyToInt(price) {
  return (
    parseInt(
      parseIntWithDecimal(
        price
          .toString()
          .replaceAll(regex.removeNonNumeric, "")
          .replaceAll(" ", ""),
        2
      )
        .replaceAll(" ", "")
        .replaceAll(".", "")
    ) / 100
  );
}
