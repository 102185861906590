import { getTemplates, getTemplate } from "@/services/templates";

export default {
  getTemplates({ commit }, id) {
    commit("set_loading", true);
    getTemplates().then(({ data }) => {
      commit("set_loading", false);
      commit("update_templates", data.results);
    });
  },
  getTemplate({ commit, dispatch }, id) {
    if (id) {
      getTemplate(id)
        .then(({ data }) => {
          commit("set_loading_template", false);
          commit("update_template", data);
        })
        .catch((_err) => {
          dispatch("setNoTemplate");
        });
    } else {
      dispatch("setNoTemplate");
    }
  },
  setNoTemplate({ commit }) {
    commit("set_loading_template", false);
    commit("update_template", {});
  }
};
