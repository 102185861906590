<template>
  <transition name="fade">
    <v-dialog
      max-width="515px"
      v-if="active"
      @click:outside="redirectToDashboard"
      :value="true"
    >
      <v-card outlined class="paymentSuccedDialog">
        <v-btn
          @click="redirectToDashboard"
          color="black"
          x-large
          icon
          class="close-button"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
        <v-card-text class="pa-10 black--text text-center">
          <BeeHeartIcon class="mb-5" />
          <p class="mb-2 succed-header font-weight-medium">
            {{ $t("messeages.connectionSuccess.bzzThankYou") }}
          </p>
          <p class="gray-text font-weight-medium">
            {{ $t("messeages.connectionSuccess.paymentSucceeded") }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import BeeHeartIcon from "@/components/Base/BeeHeartIcon/BeeHeartIcon.vue";

export default {
  components: {
    BeeHeartIcon
  },
  name: "PaymentSucceedDialog",
  props: {
    active: Boolean
  },
  methods: {
    redirectToDashboard() {
      this.$router.push({ name: "Dashboard" });
    }
  }
};
</script>

<style lang="scss">
@import "./PaymentSucceedDialog.scss";
</style>
