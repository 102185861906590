<template>
  <v-container fluid class="User">
    <transition name="fade">
      <EditUserModal
        v-if="overlayEditUser"
        :user="{
          firstName: user.user.first_name,
          lastName: user.user.last_name,
          role: user.type,
          groups: user.groups,
          id: user.user.id
        }"
        @toggleEditUser="toggleEditUser"
      />
    </transition>
    <transition name="fade">
      <RechargeOverlay
        v-if="overlayRecharge"
        @toggleOverlayRecharge="toggleOverlayRecharge"
        @recharge="rechargeUser"
        :donateFunction="rechargeUser"
      />
    </transition>
    <v-row>
      <v-col cols="12" md="6" v-if="!loading">
        <UserInformation
          v-if="!expanded.targets"
          class="mb-6"
          :firstName="user.user.first_name"
          :lastName="user.user.last_name"
          :mail="user.user.email"
          :groups="user.groups"
          :wallet="user.user.balance"
          :roleId="user.type"
          @toggleOverlayRecharge="toggleOverlayRecharge"
          @toggleEditUser="toggleEditUser"
        />
        <ListFundraising
          @changeShowElement="value => expand(value)"
          :targets="whiprounds"
          :loading="whiproundsLoading"
          :isExpanded="expanded.targets"
          :title="$t('listFundraising.titleUser')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <ListOfPayments
          v-if="!expanded.boosts"
          class="mb-6"
          @changeShowElement="value => expand(value)"
          :paymentsExpanded="donations"
          :paymentsNotExpanded="lastDonations"
          :expand="expanded.payments"
          :loading="donationsLoading"
          showDetails
        />
        <BoostsList
          v-if="!expanded.payments"
          :title="$t('boostsList.titleForUsers')"
          @changeShowElement="value => expand(value)"
          :boosts="recharges"
          :isExpanded="expanded.boosts"
          :loading="rechargesLoading"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserInformation from "@/components/Admin/UserInformation/UserInformation.vue";
import RechargeOverlay from "@/components/Base/RechargeOverlay/RechargeOverlay.vue";
import ListOfPayments from "@/components/Default/ListOfPayments/ListOfPayments.vue";
import ListFundraising from "@/components/Base/ListFundraising/ListFundraising.vue";
import BoostsList from "@/components/Base/BoostsList/BoostsList.vue";
import EditUserModal from "@/components/Default/EditUserModal/EditUserModal.vue";
import { mapGetters } from "vuex";

export default {
  name: "User",
  components: {
    UserInformation,
    RechargeOverlay,
    ListOfPayments,
    ListFundraising,
    BoostsList,
    EditUserModal
  },
  data() {
    return {
      id: this.$route.params.id,
      overlayEditUser: false,
      overlayRecharge: false,
      expanded: {
        class: "none",
        payments: false,
        targets: false,
        boosts: false
      }
    };
  },
  created() {
    this.$store.dispatch("user/loadUser", this.id);
    this.$store.dispatch("users/getCompanyUsers");
    this.$store.dispatch("groups/getGroups");
  },
  computed: {
    isAdmin() {
      return this.$store.getters["session/isAdmin"];
    },
    ...mapGetters("user", [
      "user",
      "loading",
      "whiprounds",
      "whiproundsLoading",

      "donations",
      "donationsLoading",
      "recharges",
      "rechargesLoading"
    ]),
    firstName() {
      return "Filip";
    },
    lastName() {
      return "Tobiasz";
    },
    lastDonations() {
      return this.donations.slice(0, 5);
    },
    lastWhiprounds() {
      return this.whiprounds.slice(0, 5);
    },
    lastRecharges() {
      return this.recharges.slice(0, 5);
    }
  },
  methods: {
    async rechargeUser(data) {
      await this.$store.dispatch("user/rechargeUser", {
        id: this.id,
        data
      });
      this.toggleOverlayRecharge();
    },
    toggleEditUser() {
      this.overlayEditUser = !this.overlayEditUser;
    },
    toggleOverlayRecharge() {
      this.overlayRecharge = !this.overlayRecharge;
    },
    expand(value) {
      Object.keys(this.expanded).forEach(key => {
        this.expanded[key] = key === value;
      });
    }
  },
  beforeCreate() {
    this.$emit("change-page-name", this.$t("userPage.pageTitle"));
  }
};
</script>

<style></style>
