import Vue from "vue";
import VueRouter from "vue-router";

import SessionRoutes from "./session.js";
import AdminRoutes from "./admin.js";
import defaultRoutes from "./default";
import store from "../store/";

Vue.use(VueRouter);

const routes = [AdminRoutes, SessionRoutes, defaultRoutes];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) => {
  const isAdmin = store.getters["session/isAdmin"];
  const dashboard = isAdmin ? "/dashboard" : "/user-dashboard";
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["session/isLoggedIn"]) {
      await store.dispatch("session/verifyToken");
      if (to.matched.some((record) => record.meta.onlyAdmin)) {
        if (!isAdmin) {
          return next(dashboard);
        }
      }
      return next();
    }
    next({
                path: "/login",
                query: { redirect: to.fullPath }
            });
  } else {
    if (!store.getters["session/isLoggedIn"]) {
      return next();
    }
    next(dashboard);
  }
});

export default router;
