<template>
  <v-card outlined class="WhipPanel pa-md-8">
    <TableLoader :active="loading" />
    <RechargeOverlay
      :userIsEmployee="true"
      @recharge="donateWhipHandler"
      v-if="overlayRecharge"
      @toggleOverlayRecharge="toggleOverlayRecharge"
      @refetch="refetch"
    />

    <v-card-text class="black--text">
      <div class="d-flex justify-space-between">
        <h2 class="mb-5">
          {{ whip.name }}
        </h2>
        <v-btn v-if="isEditable" @click="editWhip" icon>
          <v-icon small> bb-edit_file_icon </v-icon>
        </v-btn>
      </div>

      <v-img width="100%" height="306px" :src="whip.image">
        <div class="full-width fill-height d-flex justify-end">
          <div
            class="WhipPanel__details fill-height d-flex flex-column justify-center align-center"
          >
            <CircularProgressBoxDetailed
              v-if="whip && whip.balance && whip.goal"
              :minValue="whip.balance.amount"
              :maxValue="whip.goal"
              class="mb-7"
            />
            <div v-if="!whiproundIsClosed">
              <v-btn
                v-if="whiproundIsNotDonatable && isEditable"
                @click="closeWhip"
                large
                depressed
                color="primary"
              >
                <v-icon left small> mdi-check </v-icon>
                {{ $t("dashboard.endWhip") }}
              </v-btn>
              <v-btn
                v-else
                :disabled="whiproundIsNotDonatable"
                @click="toggleOverlayRecharge"
                large
                depressed
                color="primary"
              >
                <v-icon left small> bb-circle_add_icon </v-icon>
                {{ $t("dashboard.recharge.button") }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-img>
      <div
        class="WhipPanel__details-description full-width d-flex flex-column flex-md-row justify-md-space-between pt-8"
      >
        <div
          class="WhipPanel__details-description--left pr-0 pr-md-16 mb-10 mb-md-0"
        >
          <WhipDescription :description="whip.description" />
        </div>
        <div class="WhipPanel__details-description--right">
          <WhiproundAuthor class="mb-7" :author="whip.author_name" />
          <WhiproundStatus class="mb-7" :status="whip.status" />
          <DownloadFiles class="mb-7" :files="whip.files" />
          <WhipCategories :categories="whipCategories" />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CircularProgressBoxDetailed from "@/components/Default/CircularProgressBoxDetailed/CircularProgressBoxDetailed.vue";
import WhipDescription from "@/components/Default/WhipDescription/WhipDescription.vue";
import DownloadFiles from "@/components/Default/DownloadFiles/DownloadFiles.vue";
import WhiproundStatus from "@/components/Default/WhiproundStatus/WhiproundStatus.vue";
import WhipCategories from "@/components/Default/WhipCategories/WhipCategories.vue";
import RechargeOverlay from "@/components/Base/RechargeOverlay/RechargeOverlay.vue";
import TableLoader from "@/components/Base/TableLoader/TableLoader.vue";
import WhiproundAuthor from "@/components/Default/WhiproundAuthor/WhiproundAuthor.vue";

export default {
  name: "WhipPanel",
  computed: {
    isAdmin() {
      return this.$store.getters["session/isAdmin"];
    },
    isEditable() {
      const { id } = this.$store.getters["session/user"];

      return id === this.whip.author || this.isAdmin;
    },
    whiproundIsNotDonatable() {
      return this.whip && this.whip.status !== 1;
    },
    whiproundIsClosed() {
      return this.whip && this.whip.status === 3;
    },
    whipCategories() {
      if (this.loading) {
        return [];
      }
      const categories = [];

      this.whip.categories.forEach(groupId => {
        categories.push(
          this.categories.find(group => {
            return groupId === group.id;
          })
        );
      });

      return categories;
    },
    loading() {
      if (
        (this.whip &&
          Object.keys(this.whip).length === 0 &&
          this.whip.constructor === Object) ||
        this.categories.length === 0
      ) {
        return true;
      }

      return false;
    }
  },
  props: {
    whip: Object,
    categories: Array
  },

  components: {
    CircularProgressBoxDetailed,
    WhipDescription,
    DownloadFiles,
    WhipCategories,
    RechargeOverlay,
    TableLoader,
    WhiproundStatus,
    WhiproundAuthor
  },
  data() {
    return {
      overlayRecharge: false
    };
  },
  methods: {
    donateWhipHandler(data) {
      this.$store.dispatch("whipround/donateWhip", data).then(() => {
        this.toggleOverlayRecharge();
      });
    },
    closeWhip() {
      this.$store.dispatch("whipround/closeWhip", this.whip.id);
    },
    refetch() {
      this.$store.dispatch("whipround/getWhipround", this.whip.id);
    },
    toggleOverlayRecharge() {
      this.overlayRecharge = !this.overlayRecharge;
    },
    editWhip() {
      this.$emit("editWhip");
    }
  }
};
</script>

<style lang="scss">
@import "./WhipPanel.scss";
</style>
