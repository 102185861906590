var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-6",attrs:{"fluid":""}},[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('p',{staticClass:"text-1 gray-text"},[_vm._v(" "+_vm._s(_vm.$t("passwordSettings.currentPassword"))+" ")]),_c('v-text-field',{staticClass:"bb-input",attrs:{"prepend-inner-icon":"bb-padlock_icon","placeholder":_vm.$t('passwordSettings.currentPassword'),"outlined":"","append-icon":_vm.currentPassword.isPasswordVisible
              ? 'bb-eye_closed_icon'
              : 'bb-eye_open_icon',"type":_vm.currentPassword.isPasswordVisible ? 'text' : 'password',"rules":[_vm.rules.required, _vm.rules.passwordRule]},on:{"click:append":function($event){return _vm.toggleShowPassword('currentPassword')}},model:{value:(_vm.currentPassword.value),callback:function ($$v) {_vm.$set(_vm.currentPassword, "value", $$v)},expression:"currentPassword.value"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('p',{staticClass:"text-1 gray-text"},[_vm._v(" "+_vm._s(_vm.$t("passwordSettings.newPassword"))+" ")]),_c('v-text-field',{staticClass:"bb-input",attrs:{"prepend-inner-icon":"bb-padlock_icon","placeholder":_vm.$t('passwordSettings.newPassword'),"outlined":"","append-icon":_vm.newPassword.isPasswordVisible
              ? 'bb-eye_closed_icon'
              : 'bb-eye_open_icon',"type":_vm.newPassword.isPasswordVisible ? 'text' : 'password',"rules":[_vm.rules.required, _vm.rules.passwordRule]},on:{"click:append":function($event){return _vm.toggleShowPassword('newPassword')}},model:{value:(_vm.newPassword.value),callback:function ($$v) {_vm.$set(_vm.newPassword, "value", $$v)},expression:"newPassword.value"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('p',{staticClass:"text-1 gray-text"},[_vm._v(" "+_vm._s(_vm.$t("passwordSettings.confirmNewPassword"))+" ")]),_c('v-text-field',{staticClass:"bb-input",attrs:{"prepend-inner-icon":"bb-padlock_icon","placeholder":_vm.$t('passwordSettings.confirmNewPassword'),"outlined":"","append-icon":_vm.confirmNewPassword.isPasswordVisible
              ? 'bb-eye_closed_icon'
              : 'bb-eye_open_icon',"type":_vm.confirmNewPassword.isPasswordVisible ? 'text' : 'password',"rules":[
            _vm.rules.passwordsMatch(_vm.confirmNewPassword.value, _vm.newPassword.value)
          ]},on:{"click:append":function($event){return _vm.toggleShowPassword('confirmNewPassword')}},model:{value:(_vm.confirmNewPassword.value),callback:function ($$v) {_vm.$set(_vm.confirmNewPassword, "value", $$v)},expression:"confirmNewPassword.value"}})],1)],1)],1),_c('div',[_c('v-btn',{staticClass:"bb-button-shadow mb-1 mr-2",attrs:{"color":"primary","large":""},on:{"click":_vm.validateNewPassword}},[_vm._v(" "+_vm._s(_vm.$t("passwordSettings.saveNewPassword"))+" ")]),_c('v-btn',{staticClass:"mb-1 mr-2",attrs:{"text":"","large":""},on:{"click":_vm.cancelNewPassword}},[_vm._v(" "+_vm._s(_vm.$t("common.clear"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }