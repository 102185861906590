import {
  getCompanyUser,
  getCompanyUserWhiprounds,
  getCompanyUserDonations,
  getCompanyUserRecharges,
  rechargeCompanyUser,
  updateCompanyUser
} from "@/services/companies";
import i18n from "@/i18n/index.js";

export default {
  loadUser({ commit, dispatch }, id) {
    commit("clear_all_user_data");
    dispatch("getUser", id);
    dispatch("getUserWhiprounds", id);
    dispatch("getUserDonations", id);
    dispatch("getUserRecharges", id);
  },
  getUserWhiprounds({ commit, rootGetters }, id) {
    const companyId = rootGetters["session/user"].company.id;
    getCompanyUserWhiprounds(companyId, id).then(({ data }) => {
      commit("update_user_whiprounds", data);
    });
  },
  getUser({ commit, rootGetters }, id) {
    const companyId = rootGetters["session/user"].company.id;
    getCompanyUser(companyId, id).then(({ data }) => {
      commit("update_user", data);
    });
  },
  getUserDonations({ commit, rootGetters }, id) {
    const companyId = rootGetters["session/user"].company.id;

    getCompanyUserDonations(companyId, id).then(({ data }) => {
      commit("update_user_donations", data.results);
    });
  },

  getUserRecharges({ commit, rootGetters }, id) {
    const companyId = rootGetters["session/user"].company.id;

    getCompanyUserRecharges(companyId, id).then(({ data }) => {
      commit("update_user_recharges", data.results);
    });
  },

  rechargeUser({ rootGetters, dispatch }, payload) {
    const companyId = rootGetters["session/user"].company.id;
    const { id, data } = payload;

    rechargeCompanyUser(companyId, id, data).then(() => {
      dispatch("loadUser", id);
    });
  },

  updateUser({ rootGetters, commit, dispatch }, payload) {
    const companyId = rootGetters["session/user"].company.id;
    const { id, data } = payload;

    updateCompanyUser(companyId, id, data).then(({ data }) => {
      commit("update_user", data);
    });
  }
};
