<template>
  <v-progress-circular
    class="CircularProgressBoxDetailed"
    :size="140"
    :width="5"
    :value="progress"
    color="white"
    :rotate="-90"
  >
    <div class="circular-progress-title">
      {{ $t("circularProgressBoxDetailed.collected") }}
    </div>
    <div
      class="circular-progress-min-number"
      :class="{
        smaller: current.length > 9
      }"
    >
      {{ current }}
    </div>
    <div class="circular-progress-max-number">/ {{ goal }}</div>
  </v-progress-circular>
</template>

<script>
import { formatCurrency, parseCurrencyToInt } from "@/helpers/formatCurrency";
export default {
  props: {
    minValue: [Number, String],
    maxValue: [Number, String]
  },
  data() {
    return {
      formatCurrency,
      parseCurrencyToInt
    };
  },
  computed: {
    current() {
      return formatCurrency(this.minValue);
    },
    goal() {
      return formatCurrency(this.maxValue);
    },
    progress() {
      return (
        (parseCurrencyToInt(this.current) / parseCurrencyToInt(this.goal)) * 100
      );
    }
  }
};
</script>

<style lang="scss">
@import "./CircularProgressBoxDetailed.scss";
</style>
