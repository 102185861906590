<template>
  <div>
    <p class="text-1 gray-text mt-2">
      {{ $t("addTarget.image") }}
    </p>

    <div class="d-flex align-center  ">
      <label class="custom-file-upload" ref="customFileUpload">
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          @change="processImage($event)"
        />
        <v-img
          class="mt-6  mb-6"
          :src="imagePreview"
          width="360"
          height="145"
        />
        <div class="file-input-button" v-ripple>
          <div class="fill-height white--text d-flex align-center">
            {{ $t("addTarget.addImage") }}
          </div>
        </div>
      </label>

      <transition name="fade">
        <div v-if="image" class="d-flex align-center ml-4">
          <v-icon small> bb-edit_file_icon </v-icon>
          <span class="ml-3 text-1">{{ image.name }}</span>
          <v-btn @click="removeImage" icon x-small class="ml-2">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    image(value) {
      this.$emit("newImage", value);
    },
    placeholderImage(value) {
      if (value) {
        this.imagePreview = this.placeholderImage;
        this.defaultImagePreview = this.placeholderImage;
      } else {
        this.setDeafultPlaceholder();
      }
    }
  },
  props: {
    placeholderImage: String
  },
  created() {
    if (this.placeholderImage) {
      this.imagePreview = this.placeholderImage;
      this.defaultImagePreview = this.placeholderImage;
    }
  },
  data() {
    return {
      image: null,

      defaultImagePreview: "/img/image-placeholder.jpg",
      imagePreview: "/img/image-placeholder.jpg"
    };
  },
  methods: {
    setDeafultPlaceholder() {
      this.imagePreview = "/img/image-placeholder.jpg";
      this.defaultImagePreview = "/img/image-placeholder.jpg";
    },
    removeImage() {
      this.image = null;
      this.imagePreview = this.defaultImagePreview;
    },
    processImage(event) {
      this.image = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(this.image);
      const vm = this;
      reader.onload = function(e) {
        vm.imagePreview = e.target.result;
      };
    }
  }
};
</script>

<style></style>
