import { pageSize } from "@/constants/apiParams";

export const getParamsForList = (params) => {
  let urlParams = "";
  if (params) {
    if (!params.pageSize) {
      urlParams += `?page_size=${pageSize}`;
    } else {
      urlParams += `?page_size=${params.pageSize}`;
    }
    if (params.filter) {
      urlParams += `&status=${params.filter}`;
    }
    if (params.page) {
      urlParams += `&page=${params.page}`;
    }
  }
  return urlParams;
};

export const longListParams = "?page_size=1000";
