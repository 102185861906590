import { search } from "@/services/search";

export default {
  search({ commit, dispatch }, query) {
    commit("set_loading");
    search(query).then(({ data }) => {
      commit("set_serched_data", data);
    });
  }
};
