<template>
  <v-overlay v-model="active" z-index="10" opacity="0.6">
    <v-card light outlined class="pa-md-10 text-center" v-if="user">
      <div class="d-flex justify-space-between">
        <h2 class="heading-1 mb-2 full-width">
          {{ $t("deleteUser.title") }}
        </h2>
        <v-btn @click="closeModal" class="close-modal" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <p class="py-12">
        {{ $t("common.areYouSureYouWantDelete") }}
        <span class="primary--text font-weight-medium" v-if="user && user.user">
          {{ userDisplayName }} </span
        >?
      </p>
      <v-btn large @click="deleteUser" color="primary">
        {{ $t("common.imSure") }}
      </v-btn>
      <v-btn large text @click="closeModal">
        {{ $t("common.clear") }}
      </v-btn>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: "DeleteUser",
  props: {
    user: Object
  },
  computed: {
    userDisplayName() {
      return this.user.user.full_name
        ? this.user.user.full_name
        : this.user.user.email;
    },
    active() {
      return Object.keys(this.user).length !== 0;
    }
  },
  methods: {
    deleteUser() {
      this.$emit("deleteUser", this.user.user.id);
    },
    closeModal() {
      this.$emit("cancelDeleteUser", null);
    }
  }
};
</script>

<style></style>
