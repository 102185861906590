export default {
  set_loading(state) {
    state.loading = true;
  },
  set_serched_data(state, data) {
    state.loading = false;
    state.groups = data.groups;
    state.whiprounds = data.whip_rounds;
  }
};
