<template>
  <v-container fluid>
    <transition name="fade">
      <PageLoader :active="!!loading" />
    </transition>
    <v-card outlined v-if="!loading">
      <v-card-text class="black--text pa-6">
        <div class="d-flex justify-space-between pt-4 pb-5">
          <h2>
            {{ $t("payments.upgradeYourPlan") }}
          </h2>
          <p class="mb-0">
            {{ $t("common.currentPlan") }}:
            <span class="primary--text">{{ currentPlan.name }}</span>
          </p>
        </div>
        <div class="d-flex justify-space-between pt-4 pb-10">
          {{ $t("registerPage.main.companyCard.freeTestMonthInfo") }} {{ $t("registerPage.main.companyCard.weDontRequireCreditCardInfo") }}
        </div>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="4"
              v-for="payment in payments"
              :key="`pricing-card-${payment.id}`"
            >
              <PricingCard
                class="pb-8"
                :title="payment.nickname"
                :count="payment.unit_amount / 100"
                :desc="payment.metadata.productDescription"
                :recommended="payment.metadata.productRecommended"
                :sale="payment.sale"
                :id="payment.id"
                @selectPlan="selectPlan"
                :suffixPrice="payment.currency"
              />
            </v-col>
          </v-row>
        </v-container>
        <transition name="fade">
          <div v-if="selectedPlan" id="payment">
            <PageDividerWithText :text="$t('payments.pageName')" class="pt-8" />
            <PaymentCard :customerId="currentPlan.id" :price="selectedPlan" />
          </div>
        </transition>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PricingCard from "@/components/Admin/PricingCard/PricingCard.vue";
import PageDividerWithText from "@/components/Base/PageDividerWithText/PageDividerWithText.vue";
import PaymentCard from "@/components/Admin/PaymentCard/PaymentCard.vue";
import PageLoader from "@/components/Base/PageLoader/PageLoader.vue";

import { getPrices } from "@/services/payments.js";

export default {
  name: "Payments",
  components: {
    PricingCard,
    PageDividerWithText,
    PaymentCard,
    PageLoader
  },
  data() {
    return {
      selectedPlan: null,
      payments: [],
      loading: true
    };
  },
  computed: {
    currentPlan() {
      return this.$store.getters["session/currentPlan"];
    }
  },
  methods: {
    selectPlan(id) {
      const found = this.payments.find(payment => payment.id == id);

      if (found) {
        this.selectedPlan = found;
      }
    }
  },
  created() {
    getPrices()
      .then(response => {
        this.payments = response.data.prices;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  beforeCreate() {
    this.$emit("change-page-name", this.$t("payments.pageName"));
  }
};
</script>

<style></style>
