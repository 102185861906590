import { api } from "./api.js";
import { GROUPS } from "@/constants/apiUrls";
import axiosErrorHandler from "@/helpers/axiosErrorHandler";

export const getGroups = (payload) => {
  return api()
    .get(GROUPS.ROOT(payload))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const addGroup = (data) => {
  return api()
    .post(GROUPS.ROOT(), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getGroup = (id) => {
  return api()
    .get(GROUPS.GROUP(id))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const removeGroup = (id) => {
  return api()
    .delete(GROUPS.GROUP(id))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getGroupTargets = (id) => {
  return api()
    .get(GROUPS.GROUP_WHIPROUNDS(id))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getGroupRecharges = (id) => {
  return api()
    .get(GROUPS.GROUP_RECHARGES(id))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const rechargeGroup = (id, data) => {
  return api()
    .post(GROUPS.GROUP_RECHARGES(id), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const updateGroup = (id, data) => {
  return api()
    .patch(GROUPS.GROUP(id), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const removeUserFromGroup = (id, data) => {
  return api()
    .delete(GROUPS.GROUP_MEMBERS(id), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};
