<template>
  <v-overlay v-model="show" class="admin-overlay" z-index="9">
    <v-card class="admin-overlay-card pa-4 pa-sm-8 pa-md-16" outlined light>
      <v-btn
        class="overlay-close-button"
        icon
        text
        color="black"
        @click="closeOverlay()"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <v-card-title class="mb-10">
        {{ $t("editGroupOverlay.title") }}
      </v-card-title>
      <v-card-text v-if="editingGroup">
        <p class="text-1">
          {{ $t("editGroupOverlay.groupName") }}
        </p>
        <v-text-field
          v-model="editingGroup.name"
          outlined
          dense
          :placeholder="$t('editGroupOverlay.groupName')"
        ></v-text-field>
        <div>
          <v-btn
            @click="saveGroup"
            large
            class="bb-button-shadow"
            color="primary"
          >
            {{ $t("common.saveChanges") }}
          </v-btn>
          <v-btn class="ml-6" text @click="closeOverlay()">
            {{ $t("common.cancel") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
import rules from "@/constants/rules";
import { updateGroup } from "@/services/groups";

export default {
  props: {
    group: Object
  },
  watch: {
    group: function(value) {
      if (value) {
        this.editingGroup = Object.assign({}, value);
      }
    }
  },
  data() {
    return {
      editingGroup: {},
      addMailRules: rules.emailRules
    };
  },
  methods: {
    saveGroup() {
      const data = {
        name: this.editingGroup.name
      };

      const id = this.editingGroup.id;

      this.$store.dispatch("group/editGroup", { id, data }).then(() => {
        this.$emit("refetch");
        this.closeOverlay();
      });
    },
    updateUserList(event) {
      this.editingGroup.userList = event;
    },
    closeOverlay() {
      this.$emit("closeOverlay");
    }
  },
  computed: {
    show() {
      return Object.keys(this.group).length > 0;
    }
  }
};
</script>

<style></style>
