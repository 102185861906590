export default {
  save_user_data(state, data) {
    state.userData = data;
  },
  save_company_data(state, company) {
    state.userData.company = company;
  },
  auth_refresh_token(state, token) {
    state.accessToken = token;
  },
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, commitData) {
    state.status = "success";
    state.accessToken = commitData.accessToken;
    state.refreshToken = commitData.refreshToken;
    state.user = commitData.user;
  },
  auth_error(state) {
    state.status = "error";
  },
  logout(state) {
    state.status = "";
    state.accessToken = "";
    state.refreshToken = "";
    state.user = "";
    state.userData = null;
  },
  setAutoRefreshInProgress(state, value) {
    state.autoRefreshInProgress = value;
  }
};
