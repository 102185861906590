<template>
  <div>
    <div class="d-flex justify-space-between align-end">
      <p class="mb-0 font-weight-medium">
        {{ $t("groupUserList.title") }}
      </p>
      <a class="body-2" v-if="isExpanded" @click="emitShowAll('close')">{{
        $t("common.collapse")
      }}</a>
      <a class="body-2" v-else @click="emitShowAll('users')">{{
        $t("common.seeAll")
      }}</a>
    </div>
    <v-divider class="my-5" />

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              {{ $t("groupUserList.name") }}
            </th>
            <th>
              {{ $t("common.mail") }}
            </th>
            <th>
              {{ $t("groupUserList.amount") }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="`group-user-${user.user.id}`">
            <td>{{ `${user.user.full_name}` }}</td>
            <td>
              {{ user.user.email }}
            </td>
            <td class="primary--text">
              <span> {{ formatCurrency(user.user.balance.amount) }} </span>
            </td>
            <td class="text-right">
              <div v-if="isAdmin">
                <v-btn
                  icon
                  small
                  :to="{ name: 'User', params: { id: user.user.id } }"
                >
                  <v-icon small> bb-edit_file_icon </v-icon>
                </v-btn>
                <v-btn icon small @click="deleteUser(user)">
                  <v-icon small> bb-trash_icon </v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { formatCurrency } from "@/helpers/formatCurrency";

export default {
  name: "GroupUserList",
  props: {
    isExpanded: Boolean,
    users: Array
  },
  data() {
    return {
      formatCurrency: formatCurrency
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["session/isAdmin"];
    }
  },
  methods: {
    emitShowAll(value) {
      this.$emit("changeShowElement", value);
    },
    deleteUser(value) {
      this.$emit("deleteUser", value);
    }
  }
};
</script>

<style></style>
