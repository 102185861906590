<template>
  <div class="d-flex justify-center align-center">
    <v-divider />
    <div
      class="divider-text pa-2 text-uppercase primary--text font-weight-medium text-center"
    >
      {{ text }}
    </div>
    <v-divider />
  </div>
</template>

<script>
export default {
  name: "PageDividerWithText",
  props: {
    text: String
  }
};
</script>

<style lang="scss">
@import "./PageDividerWithText.scss";
</style>
