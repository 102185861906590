<template>
  <div class="BudgetProgressBar py-2">
    <v-progress-linear
      rounded
      :value="progress"
      class="mb-2"
    ></v-progress-linear>
    <div class="budget-progress-bar-label font-weight-regular">
      {{ formatCurrency(done) }} /
      {{ formatCurrency(max) }}
    </div>
  </div>
</template>

<script>
import {
  formatCurrency,
  parseCurrencyToInt,
  parseIntWithDecimal
} from "@/helpers/formatCurrency";

export default {
  name: "BudgetProgressBar",
  props: {
    done: Number,
    max: String
  },
  data() {
    return {
      currency: "PLN",
      formatCurrency,
      parseCurrencyToInt,
      parseIntWithDecimal
    };
  },
  computed: {
    progress() {
      const done = parseIntWithDecimal(this.done.toString());
      const { max } = this;

      return (parseCurrencyToInt(done) / parseCurrencyToInt(max)) * 100;
    }
  }
};
</script>

<style lang="scss">
@import "./BudgetProgressBar.scss";
</style>
