<template>
  <v-card class="budget-bee-card py-5" flat>
    <div class="trinagle" v-if="recommended">
      <span class="triangle-up"></span>
      <div class="trapezoid">
        <label>{{ $t("pricing.recommendedText") }}</label>
      </div>
      <span class="triangle-right"></span>
    </div>
    <div v-if="sale" class="sale">
      {{ sale }}
    </div>
    <v-card-title class="text-center">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <div class="price-container d-flex justify-center align-center mb-6">
        <span class="small" v-if="prefixPrice">
          {{ prefixPrice }}
        </span>
        <span class="big">
          {{ count }}
        </span>

        <span class="small" v-if="suffixPrice">
          {{ suffixPrice }}
        </span>
      </div>
      <p class="text-center">
        {{ desc }}
      </p>
      <p class="text-center mb-0">
        <v-btn
          href="#payment"
          class="bb-button-shadow"
          large
          color="primary"
          @click="emitId()"
        >
          {{ $t("pricing.cardButton") }}
        </v-btn>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PricingCard",
  props: [
    "title",
    "count",
    "desc",
    "recommended",
    "sale",
    "id",
    "prefixPrice",
    "suffixPrice"
  ],
  methods: {
    emitId() {
      this.$emit("selectPlan", this.id);
    }
  }
};
</script>

<style lang="scss">
@import "./PricingCard.scss";
</style>
