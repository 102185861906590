<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      @submit.prevent="prepareInputValueToBeAdded"
    >
      <p class="text-1">
        {{ label }}
      </p>
      <v-text-field
        v-model="value"
        outlined
        :placeholder="placeholder"
        dense
        class="input-white-background mb-1"
        :hint="hint"
        autocomplete="off"
        :rules="rules"
        @keyup="checkIfMailCanBeAdded"
      ></v-text-field>
    </v-form>
    <transition name="fade">
      <div v-if="addedMails.length > 0" class="mb-2">
        <v-chip
          color="primary"
          v-for="(mail, index) in addedMails"
          :key="`added-mail-chip-${index}`"
          class="added-mail-chip mr-1 mb-1"
        >
          {{ mail }}
          <v-btn
            small
            icon
            class="ml-2 mr-n1"
            color="white"
            @click="deleteMail(index)"
          >
            <v-icon small> mdi-close </v-icon>
          </v-btn>
        </v-chip>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AddUsersInput",
  props: {
    label: String,
    placeholder: String,
    hint: String,
    rules: Array,
    addedMails: Array
  },
  data() {
    return {
      valid: false,
      value: ""
    };
  },
  watch: {
    addedMails() {
      this.$emit("addedUsers", this.addedMails);
    }
  },
  methods: {
    deleteAllMails() {
      this.value = "";
      this.$emit("addedUsers", []);
    },
    deleteMail(index) {
      this.addedMails.splice(index, 1);
    },
    checkIfMailCanBeAdded(event) {
      if (event.code == "Comma" || event.code == "Space") {
        this.prepareInputValueToBeAdded();
      }
    },
    async prepareInputValueToBeAdded() {
      /* TO BE REFACTORED - function should go to parent and works with events */
      this.value = await this.value.replaceAll(",", "").trim();
      this.addInputValueToMail();
    },
    async addInputValueToMail() {
      await this.$refs.form.validate();

      if (this.valid && this.value) {
        this.addedMails.push(this.value);
        this.value = "";
      }
    }
  }
};
</script>

<style></style>
