export const userMobileMenuItems = [
  {
    route: "ProfileSettings",
    name: "menu.accountSettings"
  },
  {
    route: "PasswordSettings",
    name: "menu.changePassword"
  }
];

export const userMenuItems = [
  {
    icon: "bb-dashboard_icon",
    route: "UserDashboard",
    name: "menu.dashboard"
  },
  {
    icon: "bb-add_target_icons",
    route: "Targets",
    name: "menu.whipRounds"
  },
  {
    icon: "bb-enterprise_icon",
    route: "Templates",
    name: "menu.templates"
  },
  {
    icon: "bb-group_icon",
    route: "Groups",
    name: "menu.groups"
  },
  {
    icon: "bb-info_icon",
    route: "FAQ",
    name: "menu.faq"
  }
];

export const adminMenuItems = [
  {
    icon: "bb-dashboard_icon",
    route: "Dashboard",
    name: "menu.dashboard"
  },
  {
    icon: "bb-add_target_icons",
    route: "Targets",
    name: "menu.whipRounds"
  },
  {
    icon: "bb-enterprise_icon",
    route: "Templates",
    name: "menu.templates"
  },
  {
    icon: "bb-group_icon",
    route: "Groups",
    name: "menu.groups"
  },
  {
    icon: "bb-person_people",
    route: "Users",
    name: "menu.groupUsers"
  },
  {
    icon: "bb-payment_icon",
    route: "Payments",
    name: "menu.payments"
  },
  {
    icon: "bb-settings_icon",
    route: "CompanySettings",
    name: "menu.settings"
  },
  {
    icon: "bb-info_icon",
    route: "FAQ",
    name: "menu.faq"
  }
];

export const userTemplateMobileMenuItems = [
  ...userMenuItems,
  ...userMobileMenuItems
];

export const adminTemplateMobileMenuItems = [
  ...adminMenuItems,
  ...userMobileMenuItems
];
