<template>
  <v-app>
    <div>
      <router-view />
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  created() {
    const lang = this.$cookies.get("lang");
    if (lang) {
      this.$i18n.locale = lang;
    }
  },
  watch: {
    lang(value) {
      this.$cookies.set("lang", value);
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    }
  }
};
</script>
