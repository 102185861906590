export default {
  clear_all_user_data(state) {
    state.user = null;
    state.loading = true;

    state.whiprounds = [];
    state.whiproundsLoading = true;
    state.donations = [];
    state.donationsLoading = true;
    state.recharges = [];
    state.rechargesLoading = true;
  },
  set_loading_recharges(state, loading) {
    state.rechargesLoading = loading;
  },
  update_user(state, user) {
    state.user = user;
    state.loading = false;
  },
  update_user_whiprounds(state, whiprounds) {
    state.whiprounds = whiprounds;
    state.whiproundsLoading = false;
  },
  update_user_donations(state, donations) {
    state.donations = donations;
    state.donationsLoading = false;
  },

  update_user_recharges(state, recharges) {
    state.recharges = recharges;
    state.rechargesLoading = false;
  }
};
