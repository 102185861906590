<template>
  <v-container fluid>
    <h2 class="mb-10">
      {{ $t("search.groups") }}
    </h2>
    <GroupsTable @refetch="search" class="mb-10" :groups="groups" />
    <h2 class="mb-10">
      {{ $t("search.targets") }}
    </h2>
    <WhiproundsTable :loading="false" :targets="whiprounds" />

    <PageLoader :active="loading" />
  </v-container>
</template>

<script>
import EditUserOverlay from "@/components/Admin/EditUserOverlay/EditUserOverlay.vue";
import RemoveUserOverlay from "@/components/Admin/RemoveUserOverlay/RemoveUserOverlay.vue";
import BudgetProgressBar from "@/components/Admin/BudgetProgressBar/BudgetProgressBar.vue";
import PageLoader from "@/components/Base/PageLoader/PageLoader.vue";
import WhiproundsTable from "@/components/Base/WhiproundsTable/WhiproundsTable.vue";
import GroupsTable from "@/components/Base/GroupsTable/GroupsTable.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    EditUserOverlay,
    RemoveUserOverlay,
    BudgetProgressBar,
    PageLoader,
    WhiproundsTable,
    GroupsTable
  },

  data() {
    return {
      isEditingUser: false,
      isRemovingUser: false,
      editingUser: {},
      removingUser: {},
      activeFilter: 0
    };
  },
  computed: {
    ...mapGetters("search", ["loading", "groups", "whiprounds"]),
    query() {
      return this.$route.params.text;
    }
  },
  created() {
    this.search();
    this.$store.dispatch("groups/getGroups");
  },
  watch: {
    query() {
      this.search();
    }
  },
  methods: {
    search() {
      this.$store.dispatch("search/search", this.query);
    },
    checkIfNotLastGroup(index, groups) {
      const groupsLength = groups.length;
      return index < groupsLength - 1;
    },
    clearEditingUser() {
      this.isEditingUser = false;
      this.editingUser = {};
    },
    clearRemovingUser() {
      this.removingUser = {};
      this.isRemovingUser = false;
    },
    editUser(user) {
      this.isEditingUser = true;
      this.editingUser = user;
    },
    removeUser(user) {
      this.removingUser = user;
      this.isRemovingUser = true;
    }
  },
  beforeCreate() {
    this.$emit("change-page-name", this.$t("search.pageTitle"));
  }
};
</script>
