<template>
  <div class="d-flex justify-center align-center flex-column">
    <h2 class="mb-16">
      {{ $t("activation.title") }}
    </h2>

    <v-form
      v-model="isValid"
      ref="form"
      class="register-form"
      @submit.prevent="activateAccount"
    >
      <v-text-field
        :rules="[rules.passwordRule]"
        type="password"
        v-model="password.new"
        color="primary"
        outlined
        class="mail-input"
        prepend-inner-icon="mdi-lock"
        :label="$t('registerPage.company.yourPassword')"
      ></v-text-field>

      <v-text-field
        :rules="[rules.passwordsMatch(password.new, password.confirm)]"
        type="password"
        v-model="password.confirm"
        color="primary"
        outlined
        class="mail-input"
        prepend-inner-icon="mdi-lock-check"
        :label="$t('registerPage.company.confirmYourPassword')"
      ></v-text-field>

      <v-text-field
        :rules="[rules.required]"
        v-model="firstName"
        color="primary"
        outlined
        class="mail-input"
        prepend-inner-icon="bb-group_icon"
        :label="$t('common.firstName')"
      ></v-text-field>

      <v-text-field
        :rules="[rules.required]"
        v-model="lastName"
        color="primary"
        outlined
        class="mail-input"
        prepend-inner-icon="bb-person_info_icon"
        :label="$t('common.lastName')"
      ></v-text-field>
    </v-form>

    <v-btn
      :loading="loading"
      @click="activateAccount"
      class="mb-8"
      large
      color="primary"
    >
      {{ $t("common.activate") }}
    </v-btn>
  </div>
</template>

<script>
import rules from "@/constants/rules";

export default {
  data() {
    return {
      isValid: false,
      firstName: "",
      lastName: "",
      rules: rules,
      password: {
        new: "",
        confirm: ""
      },
      loading: false
    };
  },
  methods: {
    sendDataToDatabase() {
      const { user, token } = this.$route.params;

      const tokenSliced = token.split("&")[0];

      const data = {
        key: tokenSliced,
        user,
        password: this.password.new,
        first_name: this.firstName,
        last_name: this.lastName
      };

      this.$store.dispatch("session/activate", data);
    },
    activateAccount() {
      this.$refs.form.validate();
      if (this.isValid) {
        this.sendDataToDatabase();
      }
    }
  }
};
</script>

<style lang></style>
