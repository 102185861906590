<template>
  <v-container class="register-employee-container text-center mt-8">
    <h1 class="mb-5">
      {{ $t("registerPage.employee.title") }}
    </h1>
    <p class="mb-0 register-employee-description">
      {{ $t("registerPage.employee.description") }}
    </p>

    <v-btn
      color="primary"
      class="return-button my-8"
      router
      :to="{ name: 'Login' }"
      text
    >
      {{ $t("common.logIn") }}
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "RegisterEmployee"
};
</script>

<style lang="scss">
@import url("./RegisterEmployee.scss");
</style>
