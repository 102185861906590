<template>
  <v-container fluid class="SingleWhip" v-if="whipround">
    <transition name="fade">
      <EditWhipModal
        v-if="editWhipModal"
        @toggleEditWhip="toggleEditWhip"
        :whip="whipround"
        :categories="categories"
        :groups="groups"
      />
    </transition>
    <v-row>
      <v-col cols="12" md="7">
        <WhipPanel
          @editWhip="toggleEditWhip"
          :whip="whipround"
          :categories="categories"
        />
      </v-col>

      <v-col cols="12" md="5" :class="`d-flex flex-column ${expandClasses}`">
        <ListOfPayments
          @changeShowElement="value => expand(value)"
          :expand="expandCard.payments"
          :paymentsExpanded="donations"
          :paymentsNotExpanded="lastPayments"
          :loading="loadingDonations"
        />
        <Comments @changeShowElement="expand" :expand="expandCard.comments" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListOfPayments from "@/components/Default/ListOfPayments/ListOfPayments.vue";
import Comments from "@/components/Default/Comments/Comments.vue";
import WhipPanel from "@/components/Default/WhipPanel/WhipPanel.vue";
import EditWhipModal from "@/components/Default/EditWhipModal/EditWhipModal.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ListOfPayments,
    Comments,
    WhipPanel,
    EditWhipModal
  },
  created() {
    this.$emit("change-page-name", this.$t("common.whip"));
    this.$store.dispatch("whipround/loadWhiproundData", this.id);
    this.$store.dispatch("groups/getGroups");
    this.$store.dispatch("categories/getCategories", this.id);
    this.$store.dispatch("users/getCompanyUsers");
  },
  methods: {
    toggleEditWhip() {
      this.editWhipModal = !this.editWhipModal;
    },
    expand(property) {
      if (property == "payments") {
        this.expandPayments();
      } else if (property == "comments") {
        this.expandComments();
      } else if (property == "close") {
        this.closeCards();
      }
    },
    expandPayments() {
      this.expandCard.payments = true;
      this.expandCard.comments = false;
    },
    expandComments() {
      this.expandCard.payments = false;
      this.expandCard.comments = true;
    },
    closeCards() {
      this.expandCard.payments = false;
      this.expandCard.comments = false;
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      editWhipModal: false,
      expandCard: {
        payments: false,
        comments: false
      }
    };
  },
  computed: {
    categories() {
      return this.$store.getters["categories/categories"];
    },
    groups() {
      return this.$store.getters["groups/groups"];
    },

    ...mapGetters("whipround", ["whipround", "donations", "loadingDonations"]),

    expandClasses() {
      const expandCard = this.expandCard;

      if (expandCard.payments) return "expand-payments";
      else if (expandCard.comments) return "expand-comments";
      return "";
    },
    lastPayments() {
      return this.donations.slice(0, 4);
    }
  }
};
</script>

<style lang="scss">
@import "./SingleWhip.scss";
</style>
