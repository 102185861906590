<template>
  <div class="full-height d-flex flex-column justify-space-between">
    <div>
      <div class="d-flex justify-space-between">
        <p class="font-weight-medium mb-2">
          {{ $t("common.wallet") }}
        </p>
        <div class="mt-n1" v-if="tooltipText">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="ml-2" v-bind="attrs" v-on="on">
                bb-info_icon
              </v-icon>
            </template>
            <span>
              {{ tooltipText }}
            </span>
          </v-tooltip>
        </div>
      </div>
      <h2 class="primary--text">{{ formatCurrency(wallet.amount) }}</h2>
    </div>
    <div v-if="isAdmin">
      <v-btn
        class="bb-button-shadow"
        @click="toggleOverlayRecharge"
        large
        color="primary"
      >
        <v-icon left x-small> bb-circle_add_icon </v-icon>
        {{ $t("dashboard.recharge.button") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "@/helpers/formatCurrency";

export default {
  props: {
    wallet: Object,
    tooltipText: String
  },
  data() {
    return {
      formatCurrency: formatCurrency
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["session/isAdmin"];
    }
  },
  methods: {
    toggleOverlayRecharge() {
      this.$emit("toggleOverlayRecharge");
    }
  }
};
</script>

<style></style>
