<template>
  <div class="text-center mt-8">
    <h1 class="mb-5">{{ $t("registerPage.company.title") }}</h1>
    <p class="gray-text mb-16">
      {{ $t("registerPage.company.description") }}
      <br>
      {{ $t("registerPage.company.descriptionSecondLine") }}
    </p>

    <v-form v-model="isValid" ref="form" @submit.prevent="registerUser">
      <v-text-field
        :rules="rules.emailRules"
        v-model="mail"
        color="primary"
        outlined
        class="mail-input"
        prepend-inner-icon="mdi-email"
        :label="$t('registerPage.company.yourMail')"
      ></v-text-field>
      <v-text-field
        :rules="[rules.passwordRule]"
        type="password"
        v-model="password.new"
        color="primary"
        outlined
        class="mail-input"
        prepend-inner-icon="mdi-lock"
        :label="$t('registerPage.company.yourPassword')"
      ></v-text-field>

      <v-text-field
        :rules="[rules.passwordsMatch(password.new, password.confirm)]"
        type="password"
        v-model="password.confirm"
        color="primary"
        outlined
        class="mail-input"
        prepend-inner-icon="mdi-lock-check"
        :label="$t('registerPage.company.confirmYourPassword')"
      ></v-text-field>

      <v-text-field
        :rules="[rules.required]"
        v-model="companyName"
        color="primary"
        outlined
        class="mail-input"
        prepend-inner-icon="mdi-domain"
        :label="$t('registerPage.company.companyName')"
      ></v-text-field>

      <v-select
        v-model="currency"
        outlined
        prepend-inner-icon="mdi-cash"
        :rules="[rules.required]"
        color="primary"
        class="currency-input mail-input"
        :items="currencies"
        :label="$t('common.currency')"
      />

      <div class="mb-12 d-flex justify-center align-center">
        <v-checkbox
          class="custom-checkbox"
          :rules="[rules.gdprRequired]"
          v-model="gdprCheck"
        ></v-checkbox>
        <p class="ml-1 mb-0 mt-3 gray-text text-left">
          {{ $t("registerPage.company.gdpr.messeage") }}
          <a :href="termsLink" target="_blank">
            {{ $t("registerPage.company.gdpr.termsAndConditions") }}.
          </a>
           {{ $t("common.and") }}
          <a :href="policyLink" target="_blank">
            {{ $t("registerPage.company.gdpr.privacyPolicy") }}.
          </a>
        </p>
      </div>
    </v-form>
    <v-btn
      :loading="loading"
      @click="registerUser"
      class="mb-8"
      large
      color="primary"
    >
      {{ $t("registerPage.main.companyCard.buttonText") }}
    </v-btn>
    <!-- <div>
      <a>
        {{ $t("registerPage.company.googleRegister") }}
      </a>
    </div> -->
  </div>
</template>

<script>
import rules from "@/constants/rules";
import currencies from "@/constants/currencies";

export default {
  name: "RegisterCompany",
  data() {
    return {
      rules: rules,
      isValid: null,
      mail: "",
      gdprCheck: false,
      policyLink: this.$t("privacyPolicy"),
      termsLink: this.$t("termsAndConditions"),
      companyName: "",
      currency: "PLN",
      currencies: currencies,
      password: {
        new: "",
        confirm: ""
      },
      loading: false
    };
  },
  methods: {
    registerUser() {
      this.validate();
      this.register(this.isValid);
    },
    validate() {
      this.$refs.form.validate();
    },
    async register(validate) {
      if (validate) {
        this.loading = true;

        await this.$store
          .dispatch("session/register", {
            email: this.mail,
            password: this.password.new,
            company_name: this.companyName,
            default_currency: this.currency
          })

          .catch(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
