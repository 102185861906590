<template>
  <div class="login-container">
    <h1 class="mb-12">{{ $t("loginPage.title") }}</h1>
    <v-form
      v-model="isValid"
      ref="form"
      @submit.prevent="logInButton(email, password)"
    >
      <v-text-field
        v-model="email"
        outlined
        prepend-inner-icon="bb-person_info_icon"
        :placeholder="$t('common.login')"
        @keyup.enter="logInButton(email, password)"
        :rules="[rules.required]"
      >
      </v-text-field>
      <v-text-field
        v-model="password"
        outlined
        prepend-inner-icon="bb-padlock_icon"
        :placeholder="$t('common.password')"
        :append-icon="passwordAppendIcon"
        :type="passwordInputType"
        @click:append="toggleShowPassword"
        @keyup.enter="logInButton(email, password)"
        :rules="[rules.required]"
      >
      </v-text-field>
    </v-form>
    <div class="d-flex align-center justify-space-between">
      <v-row>
        <v-col cols="6" class="py-0 d-flex align-center">
          <router-link class="gray-link my-3" :to="{ name: 'ForgotPassword' }">
            {{ $t("loginPage.forgotPass") }}
          </router-link>
        </v-col>
        <v-col cols="6" class="py-0  d-flex align-center">
          <v-btn
            block
            :loading="loading"
            @click="logInButton(email, password)"
            x-large
            color="primary bb-button-shadow"
          >
            {{ $t("common.logIn") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="spacer-container d-flex align-center my-6">
      <div class="spacer" />
      <p class="mb-0 mx-4">
        {{ $t("loginPage.or") }}
      </p>
      <div class="spacer" />
    </div>
    <div class="text-center">
      <!-- TODO NOT MVP -->
      <!-- <div>
        <v-btn
          @click="logInGoogle"
          :loading="loading"
          class="google-login-button mb-8"
          large
          color="#4184f3"
          dark
        >
          <v-icon left> mdi-google </v-icon>
          {{ $t("loginPage.loginGoogle") }}
        </v-btn>
      </div> -->
      <div>
        <router-link :to="{ name: 'Register' }">
          {{ $t("loginPage.register") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import rules from "@/constants/rules";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      loading: false,
      isValid: null,
      rules
    };
  },
  computed: {
    passwordAppendIcon() {
      return this.showPassword ? "bb-eye_open_icon" : "bb-eye_closed_icon";
    },
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    }
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    logInButton(email, password) {
      this.validate();
      this.logIn(email, password);
    },
    validate() {
      this.$refs.form.validate();
    },
    logIn(email, password) {
      if (this.isValid) {
        this.loading = true;
        let data = {
          email: email,
          password: password
        };

        this.$store.dispatch("session/login", data).catch(error => {
          this.loading = false;
        });
      }
    },
    logInGoogle() {
      this.$refs.form.validate();
    }
  }
};
</script>
