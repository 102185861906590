<template>
  <v-card class="RegisterCard d-flex flex-column justify-center" outlined>
    <div class="register-card-icon-container pa-5 pt-8">
      <v-icon x-large>{{ card.icon }}</v-icon>
    </div>
    <v-card-title class="justify-center">
      {{ card.title }}
    </v-card-title>
    <v-card-text>
      <p class="register-desciption">{{ card.description }}</p>
      <v-btn router large depressed :to="{ name: card.link }" color="primary">{{
        card.buttonText
      }}</v-btn>
    </v-card-text>
    <div v-if="card.freeTestMonthInfo" class="marketing-info">
      {{ card.freeTestMonthInfo }}<br>
      {{ card.weDontRequireCreditCardInfo }}
    </div>
  </v-card>
</template>

<script>
export default {
  name: "RegisterCard",
  props: {
    card: Object
  }
};
</script>

<style lang="scss">
@import url("./RegisterCard.scss");
</style>
