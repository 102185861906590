import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/";
import vuetify from "./plugins/vuetify";
import i18n from "@/i18n";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueGtag from "vue-gtag";
import VueFbCustomerChat from 'vue-fb-customer-chat';
Vue.config.productionTip = false;

import { StripePlugin } from "@vue-stripe/vue-stripe";

import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')


const fbChatOptions = {
 page_id: 101417544765270, //  change 'null' to your Facebook Page ID,
 theme_color: '#42C670', // theme color in HEX
 locale: i18n.locale, // default 'en_US'
};

const googleOptions = {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
  appName: 'BB',
  pageTrackerScreenviewEnabled: true
};

const stripeOptions = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  stripeAccount: process.env.STRIPE_ACCOUNT,
  // apiVersion: process.env.API_VERSION,
  locale: i18n.locale
};

Vue.use(VueFbCustomerChat, fbChatOptions);
Vue.use(VueGtag, googleOptions, router);
Vue.use(StripePlugin, stripeOptions);
Vue.use(VueSweetalert2);
Vue.use(VueTour);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
