<template>
  <div>
    <AdminLayout v-if="isAdmin" />
    <DefaultLayout v-else />
  </div>
</template>

<script>
import AdminLayout from "@/layouts/Admin/Admin.vue";
import DefaultLayout from "@/layouts/Default/Default.vue";

export default {
  components: {
    AdminLayout,
    DefaultLayout
  },
  computed: {
    isAdmin() {
      return this.$store.getters["session/isAdmin"];
    }
  }
};
</script>

<style></style>
