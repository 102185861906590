<template>
  <div class="text-center mt-8">
    <h1 class="mb-5">{{ $t("forgotPassword.title") }}</h1>
    <p class="gray-text mb-16" v-html="$t('forgotPassword.description')"></p>
    <v-form v-model="isValid" ref="form" @submit.prevent="handleClick">
      <v-text-field
        :rules="rules.emailRules"
        v-model="mail"
        color="primary"
        outlined
        class="mail-input"
        prepend-inner-icon="mdi-email"
        :placeholder="$t('forgotPassword.yourMail')"
      ></v-text-field>
    </v-form>
    <v-btn @click="handleClick" class="mb-8" large color="primary">
      {{ $t("forgotPassword.button") }}
    </v-btn>
  </div>
</template>

<script>
import rules from "@/constants/rules.js";
import { resetPassword } from "@/services/passwords";

export default {
  name: "ForgotPassword",

  data() {
    return {
      isValid: null,
      mail: "",
      rules
    };
  },
  methods: {
    handleClick() {
      this.validate();
      this.remindPass(this.isValid);
    },
    validate() {
      this.$refs.form.validate();
    },
    remindPass(validate) {
      if (validate) {
        resetPassword({ email: this.mail })
          .then(({ data }) => {
            this.$swal({
              icon: "success",
              title: this.$t("common.success"),
              text: data.detail
            });
          })
         
      }
    }
  }
};
</script>
