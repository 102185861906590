<template>
  <v-app class="LoggedIn">
    <PageLoader :active="!gotUser" />
    <template v-if="gotUser">
      <v-app-bar
        flat
        height="70px"
        color="white"
        class="d-block d-md-none mobile-cutom-app-bar"
      >
        <div class="mobile-logo">
          <router-link :to="{ name: 'Dashboard' }">
            <img src="@/assets/logo-square.png" />
          </router-link>
        </div>
        <v-spacer />
        <div class="d-flex align-center justify-center">
          <MobileMenu>
            <router-link
              v-for="(item, index) in adminTemplateMobileMenuItems"
              :key="`mobile-menu-${index}`"
              :to="{ name: item.route }"
              class="black--text font-weight-medium my-2"
            >
              {{ $t(item.name) }}
            </router-link>
          </MobileMenu>
        </div>
      </v-app-bar>
      <v-app-bar
        class="custom-app-bar pa-0 d-none d-md-block"
        flat
        color="white"
        fixed
        height="100px"
      >
        <div class="d-flex align-center app-bar-content">
          <div class="logo-dashboard d-flex align-center justify-center">
            <router-link :to="{ name: 'Dashboard' }">
              <img src="@/assets/logo-square.png" />
            </router-link>
          </div>
          <v-spacer />
          <div class="search">
            <Search />
          </div>
          <FastAddActions />
          <VerticalDivider class="mr-4" />
          <CurrentUser />
          <UserMenu class="ml-11" />
        </div>
      </v-app-bar>
      <DesktopMenu>
        <MenuList :menuItems="menuItems" />
      </DesktopMenu>
      <div class="main-wrapper-padding">
        <v-main>
          <router-view @change-page-name="changePageName" />
        </v-main>
      </div>
    </template>
  </v-app>
</template>
<script>
import UserMenu from "@/components/Admin/UserMenu/UserMenu.vue";
import MobileMenu from "@/components/Base/MobileMenu/MobileMenu.vue";
import DesktopMenu from "@/components/Base/DesktopMenu/DesktopMenu.vue";
import MenuList from "@/components/Base/MenuList/MenuList";
import { adminMenuItems, adminTemplateMobileMenuItems } from "@/data/menuItems";
import PageLoader from "@/components/Base/PageLoader/PageLoader.vue";
import Search from "@/components/Base/Search/Search.vue";
import CurrentUser from "@/components/Base/CurrentUser/CurrentUser.vue";
import VerticalDivider from "@/components/Base/VerticalDivider/VerticalDivider.vue";
import FastAddActions from "@/components/Admin/FastAddActions/FastAddActions.vue";

export default {
  name: "AdminLayout",
  components: {
    PageLoader,
    MobileMenu,
    DesktopMenu,
    UserMenu,
    MenuList,
    Search,
    CurrentUser,
    VerticalDivider,
    FastAddActions
  },
  data() {
    return {
      menuItems: adminMenuItems,
      adminTemplateMobileMenuItems,
      pageName: "",
      searchValue: ""
    };
  },
  methods: {
    changePageName(name) {
      this.pageName = name;
    },
    search() {
      this.$router.push({
        name: "Search",
        params: {
          text: this.searchValue
        }
      });
    }
  },

  computed: {
    gotUser() {
      return this.$store.getters["session/gotUserData"];
    }
  },
  created() {
    this.$store.dispatch("session/getUserData");
  }
};
</script>
<style lang="scss">
@import "./Admin.scss";
</style>
