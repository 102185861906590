import { api } from "./api.js";
import { COMPANIES } from "@/constants/apiUrls";
import axiosErrorHandler from "@/helpers/axiosErrorHandler";

export const getCompanies = () => {
  return api()
    .get(COMPANIES.ROOT)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getCompany = (id) => {
  return api()
    .get(COMPANIES.SINGLE(id))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const updateCompany = (id, data) => {
  return api()
    .patch(COMPANIES.SINGLE(id), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getCompanyUsers = (id, params) => {
  return api()
    .get(COMPANIES.USERS_WITH_PARAMS(id, params))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const addCompanyUser = (id, data) => {
  return api(true)
    .post(COMPANIES.USERS(id), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getCompanyUser = (companyId, userId, data) => {
  return api()
    .get(COMPANIES.USER(companyId, userId), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const updateCompanyUser = (companyId, userId, data) => {
  return api()
    .patch(COMPANIES.USER(companyId, userId), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const deleteCompanyUser = (companyId, userId) => {
  return api()
    .delete(COMPANIES.USER(companyId, userId))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getCompanyUserWhiprounds = (companyId, userId) => {
  return api()
    .get(COMPANIES.USER_WHIPS(companyId, userId))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getCompanyUserDonations = (companyId, userId) => {
  return api()
    .get(COMPANIES.USER_DONATIONS(companyId, userId))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const getCompanyUserRecharges = (companyId, userId) => {
  return api()
    .get(COMPANIES.USER_RECHARGES(companyId, userId))
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const rechargeCompanyUser = (companyId, userId, data) => {
  return api()
    .post(COMPANIES.USER_RECHARGES(companyId, userId), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const rechargeCompanyUsers = (companyId, data) => {
  return api()
    .post(COMPANIES.COMPANY_RECHARGE(companyId), data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};
