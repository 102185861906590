import { paymentApi } from "./api.js";
import { PAYMENTS } from "@/constants/apiUrls";
import axiosErrorHandler from "@/helpers/axiosErrorHandler";

export const getPrices = () => {
  return paymentApi()
    .get(PAYMENTS.PRICES())
    .catch((error) => {
      axiosErrorHandler(error);
    });
};

export const createSubscription = (data) => {
  return paymentApi()
    .post(PAYMENTS.SUBSCRIPTION, data)
    .catch((error) => {
      axiosErrorHandler(error);
    });
};
