import {
  getWhipround,
  updateWhip,
  getWhipDonations
} from "@/services/whiprounds";
import { getComments, addComment } from "@/services/whiprounds";
import {
  parseCurrencyToInt,
  parseIntWithDecimal
} from "@/helpers/formatCurrency";
import { donateWhip } from "@/services/whiprounds";

export default {
  donateWhip({ dispatch, getters }, data) {
    if (!data.is_cyclic) delete data.periodicity;
    data.amount = parseCurrencyToInt(data.amount);
    const id = getters.whipround.id;

    donateWhip(id, data).then(() => {
      dispatch("getWhipround", id);
      dispatch("getWhiproundDonations", id);
    });
  },
  loadWhiproundData({ dispatch, commit }, id) {
    commit("clear_whipround_data");
    dispatch("getWhipround", id);
    dispatch("getWhiproundDonations", id);
  },

  getWhiproundDonations({ commit }, id) {
    getWhipDonations(id).then((response) => {
      commit("update_donations", response.data.results);
    });
  },
  getWhipround({ commit, dispatch }, id) {
    getWhipround(id).then((response) => {
      const { data } = response;
      dispatch("session/getUserData", null, { root: true });
      commit("update_whipround", data);
    });
  },
  closeWhip({ commit }, id) {
    const data = new FormData();

    data.append("status", 3);

    updateWhip(id, data).then((response) => {
      commit("update_whipround", response.data);
    });
  },
  updateWhip({ commit, getters }, payload) {
    const { groups, editingWhip, files } = payload;

    const data = new FormData();

    const goal = parseIntWithDecimal(editingWhip.goal, 2);

    data.append("name", editingWhip.name);
    data.append("description", editingWhip.description);
    data.append("goal", goal);
    if (editingWhip.image) {
      data.append("image", editingWhip.image);
    }

    groups.forEach((group) => {
      data.append("groups", group);
    });

    editingWhip.categories.forEach((category) => {
      data.append("categories", category);
    });

    if (files.length) {
      files.forEach((file) => {
        data.append("files", file);
      });
    }

    const id = getters.whipround.id;

    updateWhip(id, data).then((response) => {
      commit("update_whipround", response.data);
    });
  },
  getComments({ commit }, id) {
    getComments(id).then((response) => {
      const { data } = response;
      commit("update_comments", data);
      commit("update_stop_loading_comments");
    });
  },
  addComment({ dispatch }, payload) {
    addComment(payload.id, payload.data).then(() => {
      dispatch("getComments", payload.id);
    });
  }
};
