<template>
  <div class="text-right MenuMobileLanguageSwitcher">
    <span
      v-for="(locale, index) in availableLocales"
      :key="`menu-locale-${locale.code}`"
    >
      <a
        class="text-underline my-2"
        @click="handleLangSwitch(locale.code)"
        :class="locale.active ? 'locale-active' : ''"
      >
        {{ locale.code }}
      </a>
      <span v-if="index < availableLocales.length - 1">|</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "MenuMobileLanguageSwitcher",
  methods: {
    handleLangSwitch(locale) {
      this.$i18n.locale = locale;
    },
  },
  computed: {
    availableLocales() {
      return this.$i18n.availableLocales.map((locale) => {
        return {
          code: locale,
          active: this.$i18n.locale == locale,
        };
      });
    },
  },
};
</script>

<style lang="scss">
@import "./MenuMobileLanguageSwitcher.scss";
</style>
