<template>
  <div class="Comments" :class="expand ? 'pt-0' : 'pt-8'">
    <div class="d-flex justify-space-between align-end">
      <p class="mb-0 font-weight-medium">
        {{ $t("comments.title") }}
      </p>

      <a
        class="text-smaller"
        @click="emitShowAll(expand ? 'close' : 'comments')"
      >
        {{ expand ? $t("common.collapse") : $t("common.seeAll") }}
      </a>
    </div>
    <v-divider class="my-5" />

    <transition name="fade">
      <div v-if="!loadingComments" class="comments-section py-6 px-3">
        <div class="comments-list pr-3" ref="comments">
          <transition name="fade">
            <p class="font-weight-medium" v-if="comments.length === 0">
              {{ $t("comments.noComments") }}
            </p>
          </transition>
          <div
            class="comment-row d-block d-md-flex py-3 px-4"
            v-for="comment in comments"
            :key="`comment-${comment.id}`"
          >
            <div
              class="comment-header d-flex d-md-block align-end justify-space-between mb-3 mb-md-0 mr-0 mr-md-2"
            >
              <router-link
                class="mb-0 mb-md-1 text-1"
                :to="{
                  name: 'User',
                  params: { id: comment.author.id }
                }"
              >
                {{ comment.author.full_name }}
              </router-link>

              <p class="mb-0 text-1 date">
                {{ formatFromIsoToString(comment.date_created) }}
              </p>
            </div>
            <div class="comment-content">
              <p class="font-weight-medium mb-0">
                {{ comment.text }}
              </p>
            </div>
          </div>
        </div>

        <v-form
          @submit.prevent="addNewComment"
          v-model="isValid"
          ref="form"
          class="d-flex align-center mt-3"
        >
          <v-text-field
            v-model="comment"
            class="user-input"
            outlined
            color="primary"
            :placeholder="$t('comments.commentPlaceholder')"
            hide-details
          ></v-text-field>
          <v-btn large icon color="primary" @click="addNewComment">
            <v-icon size="18" class="mr-1">bb-comment_icon</v-icon>
          </v-btn>
        </v-form>
      </div>
      <Loader v-else />
    </transition>
  </div>
</template>

<script>
import rules from "@/constants/rules";

import Loader from "@/components/Base/Loader/Loader";
import { formatFromIsoToString } from "@/helpers/formatDate";

import { mapGetters } from "vuex";

export default {
  props: {
    expand: Boolean
  },
  components: {
    Loader
  },
  name: "Comments",
  created() {
    this.$store.dispatch("whipround/getComments", this.id);
  },
  computed: {
    ...mapGetters("whipround", ["comments", "loadingComments"])
  },
  watch: {
    comments() {
      const vm = this;
      setTimeout(() => {
        vm.scrollCommentToBottom();
      }, 1000);
    }
  },

  data() {
    return {
      formatFromIsoToString: formatFromIsoToString,
      id: this.$route.params.id,
      rules: rules,
      isValid: false,
      comment: ""
    };
  },
  methods: {
    scrollCommentToBottom() {
      this.$refs.comments.scrollTop = 99999999;
    },
    addNewComment() {
      this.$refs.form.validate();
      if (this.isValid && this.comment) {
        this.sendNewComment();
      }
    },

    sendNewComment() {
      const data = { text: this.comment };
      this.$store
        .dispatch("whipround/addComment", { id: this.id, data })
        .then(() => {
          this.resetForm();
        });
    },
    emitShowAll(value) {
      this.$emit("changeShowElement", value);
    },
    resetForm() {
      this.comment = "";
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<style lang="scss">
@import "./Comments.scss";
</style>
