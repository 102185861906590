<template>
  <v-container fluid class="InviteUser pa-6">
    <PageLoader :active="!groups.length" />
    <div class="settings-content">
      <AddUsersInput
        ref="AddUsersInput"
        class="add-mail-input"
        :addedMails="addedMails"
        :label="$t('inviteUser.addEmail.label')"
        :placeholder="$t('inviteUser.addEmail.placeholder')"
        :hint="$t('inviteUser.addEmail.hint')"
        :rules="addMailRules"
        @addedUsers="handleAddedUsers"
      />

      <p class="text-1">
        {{ $t("inviteUser.sendFileDescription") }}
      </p>
      <p class="text-1">
        {{ $t("inviteUser.sendFileCSVInstructions") }}
      </p>
      <div class="mt-6 d-flex align-center mb-6">
        <label class="custom-file-upload" ref="customFileUpload">
          <input
            type="file"
            accept=".csv, text/plain"
            @change="processFile($event)"
          />

          <div class="file-input-button" v-ripple>
            <div class="fill-height white--text d-flex align-center">
              <v-icon small left color="white">
                bb-envelope_icon
              </v-icon>
              {{ $t("inviteUser.sendFile") }}
            </div>
          </div>
        </label>
        <transition name="fade">
          <div v-if="mailFile" class="d-flex align-center ml-4">
            <v-icon small> bb-edit_file_icon </v-icon>
            <span class="ml-3 text-1">{{ mailFile.name }}</span>
            <v-btn @click="removeFile" icon x-small class="ml-2">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </div>
        </transition>
      </div>
      <p class="text-1">
        {{ $t("inviteUser.groups") }}
      </p>
      <v-chip-group v-model="groupsSelected" column multiple class="mb-6">
        <v-chip
          v-for="group in groups"
          :key="`chip-group-${group.id}`"
          class="group-chip"
          :class="{
            'default-group-chip': group.is_default
          }"
        >
          {{ group.name }}
        </v-chip>
      </v-chip-group>
      <div>
        <v-btn
          @click="inviteUsers"
          class="bb-button-shadow"
          large
          color="primary"
        >
          {{ $t("inviteUser.addButton") }}
        </v-btn>
        <v-btn @click="cancelInviting" class="ml-4" text color="#A0A0A0">
          {{ $t("inviteUser.cancelButton") }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import AddUsersInput from "@/components/Admin/AddUsersInput/AddUsersInput";
import rules from "@/constants/rules";
import { addCompanyUser } from "@/services/companies";
import PageLoader from "@/components/Base/PageLoader/PageLoader.vue";
export default {
  name: "InviteUser",
  components: {
    AddUsersInput,
    PageLoader
  },
  created() {
    this.$store.dispatch("groups/getGroups");
  },
  data() {
    return {
      groupsSelected: [],
      mailFile: null,
      addMailRules: rules.addUserInputRules,
      addedMails: []
    };
  },
  watch: {
    groups: function() {
      this.setDefaultGroup();
    }
  },
  computed: {
    groups() {
      return this.$store.getters["groups/groups"];
    }
  },
  methods: {
    setDefaultGroup() {
      const found = this.groups.findIndex(group => {
        return group.is_default;
      });
      if (found !== null) {
        this.groupsSelected = [found];
      }
    },
    sendDataToDatabase() {
      const data = new FormData();
      this.groupsSelected.forEach(groupIndex => {
        data.append("groups_ids", this.groups[groupIndex].id);
      });
      if (this.mailFile) {
        data.append("file", this.mailFile);
      }
      this.addedMails.forEach(mail => {
        data.append("emails", mail);
      });

      const companyId = this.$store.state.session.userData.company.id;

      addCompanyUser(companyId, data).then((response) => {
        if(response !== undefined){
          this.$swal({
            title: this.$t("messeages.connectionSuccess.addingSuccess"),
            icon: "success",
            toast: true,
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            position: "top-end"
          });
        }
      });
    },

    async validate() {
      if (this.addedMails.length > 0 || this.mailFile) {
        if(this.mailFile && this.mailFile.size > 5000000){
            this.$swal({
              title: this.$t("messeages.validationErrors.tooBigFile", { maxMegabytes: 5 }),
              icon: "error",
              toast: true,
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
              position: "top-end"
            });

            return false;
        }
        if (this.groupsSelected.length > 0) {
          return true;
        }

        this.$swal({
          title: this.$t("messeages.validationErrors.addAtLeastOneGroup"),
          icon: "error",
          toast: true,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          position: "top-end"
        });

        return false;
      } else {
        this.$swal({
          title: this.$t("messeages.validationErrors.addAtLeastOneUser"),
          icon: "error",
          toast: true,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          position: "top-end"
        });

        return false;
      }
    },
    async inviteUsers() {
      await this.$refs.AddUsersInput.addInputValueToMail();
      const vm = this;
      setTimeout(async function() {
        const valid = await vm.validate();
        if (valid) {
          vm.sendDataToDatabase();
        }
      }, 200);
    },
    cancelInviting() {
      this.$refs.AddUsersInput.deleteAllMails();

      this.groupsSelected = [];
      this.mailFile = null;
      this.setDefaultGroup();
    },
    handleAddedUsers(value) {
      this.addedMails = value;
    },
    removeFile() {
      this.mailFile = null;
    },
    processFile(event) {
      this.mailFile = event.target.files[0];
    }
  },
  beforeCreate() {
    this.$emit("change-page-name", this.$t("inviteUser.pageName"));
  }
};
</script>

<style lang="scss">
@import "./InviteUser.scss";
</style>
