<template>
  <div>
    <p class="pb-2 font-weight-medium">{{ $t("whiproundStatus.title") }}</p>
    <p>
      {{ $t(statusName) }}
    </p>
  </div>
</template>

<script>
import { targetStatuses } from "@/constants/targetStatuses";

export default {
  props: {
    status: Number
  },
  computed: {
    statusName() {
      if (this.status) {
        const found = targetStatuses.find(target => {
          return target.id === this.status;
        });

        return found ? found.singleKey : "targetStatus.open";
      } else return "";
    }
  }
};
</script>

<style></style>
