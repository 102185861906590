import { getCategories } from "@/services/categories";
export default {
  getCategories({ commit }) {
    getCategories().then((response) => {
      const { results } = response.data;

      commit("update_categories", results);
    });
  }
};
