<template>
  <v-container fluid class="User">
    <EditGroupModal
      v-if="!loading && users.length > 0"
      :active="overlayEditGroup"
      :group="{ members, name: group.name, founder: group.founder }"
      :users="users"
      @toggleEditGroup="toggleEditGroup"
      @refetchGroup="refetchGroup"
    />
    <RechargeOverlay
      v-if="overlayRecharge"
      group-recharge
      @recharge="rechargeGroup"
      @toggleOverlayRecharge="toggleOverlayRecharge"
    />
    <DeleteUser
      :user="deletingUser"
      @cancelDeleteUser="cancelDeleteUser"
      @deleteUser="deleteUserFromGroup"
    />
    <v-row v-if="!loading">
      <v-col cols="12" md="6">
        <GroupInformation
          v-if="!expanded.targets"
          class="mb-6"
          :groupName="group.name"
          :founder="group.founder"
          :wallet="group.wallet"
          :editable="isFounder || isAdmin"
          @toggleOverlayRecharge="toggleOverlayRecharge"
          @toggleEditGroup="toggleEditGroup"
        />
        <ListFundraising
          @changeShowElement="expand"
          :isExpanded="expanded.targets"
          :targets="targetsMapped"
          :loading="loadingTargets"
          :title="$t('listFundraising.titleGroup')"
          isGroupView
        />
      </v-col>

      <v-col cols="12" md="6">
        <GroupUserList
          class="mb-6"
          v-if="!expanded.boosts"
          @changeShowElement="expand"
          @deleteUser="deleteUser"
          :users="usersMapped"
          :isExpanded="expanded.users"
        />
        <BoostsList
          v-if="!expanded.users"
          :title="$t('boostsList.titleForUsers')"
          @changeShowElement="expand"
          :boosts="boostsMapped"
          :loading="loadingRecharges"
          :isExpanded="expanded.boosts"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GroupInformation from "@/components/Admin/GroupInformation/GroupInformation.vue";
import RechargeOverlay from "@/components/Base/RechargeOverlay/RechargeOverlay.vue";
import GroupUserList from "@/components/Base/GroupUserList/GroupUserList.vue";
import ListFundraising from "@/components/Base/ListFundraising/ListFundraising.vue";
import BoostsList from "@/components/Base/BoostsList/BoostsList.vue";
import EditGroupModal from "@/components/Default/EditGroupModal/EditGroupModal.vue";
import DeleteUser from "@/components/Base/DeleteUser/DeleteUser.vue";
import { mapGetters } from "vuex";

export default {
  name: "Group",
  components: {
    GroupInformation,
    RechargeOverlay,
    GroupUserList,
    ListFundraising,
    BoostsList,
    EditGroupModal,
    DeleteUser
  },
  created() {
    this.$store.dispatch("group/loadGroup", this.id);
    this.$store.dispatch("users/getCompanyUsers");
  },
  data() {
    return {
      id: this.$route.params.id,
      deletingUser: {},
      overlayEditGroup: false,
      expanded: {
        class: "none",
        users: false,
        targets: false,
        boosts: false
      },

      overlayRecharge: false,

      boosts: []
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["session/isAdmin"];
    },
    isFounder() {
      if (!Object.keys(this.group).length) return false;
      const { id } = this.$store.getters["session/user"];

      return this.group.founder.id === id;
    },
    ...mapGetters("group", [
      "group",
      "targets",
      "recharges",
      "loading",
      "loadingTargets",
      "loadingRecharges"
    ]),
    users() {
      return this.$store.getters["users/users"];
    },
    members() {
      return this.group.users.map(user => {
        return user.user.id;
      });
    },
    targetsMapped() {
      return this.expanded.targets ? this.targets : this.targets.slice(0, 5);
    },
    usersMapped() {
      return this.expanded.users
        ? this.group.users
        : this.group.users.slice(0, 5);
    },
    boostsMapped() {
      return this.expanded.boosts ? this.recharges : this.recharges.slice(0, 5);
    }
  },
  methods: {
    deleteUserFromGroup(id) {
      this.$store.dispatch("group/deleteUser", {
        groupId: this.id,
        memeberId: id
      });
      this.cancelDeleteUser();
    },
    rechargeGroup(data) {
      this.$store.dispatch("group/rechargeGroup", { id: this.id, data });
      this.toggleOverlayRecharge();
    },
    refetchGroup() {
      this.$store.dispatch("group/getGroup", this.id);
    },
    toggleEditGroup() {
      this.overlayEditGroup = !this.overlayEditGroup;
    },
    toggleOverlayRecharge() {
      this.overlayRecharge = !this.overlayRecharge;
    },
    deleteUser(userToDelete) {
      this.deletingUser = userToDelete;
    },
    cancelDeleteUser() {
      this.deletingUser = {};
    },
    expand(value) {
      Object.keys(this.expanded).forEach(key => {
        this.expanded[key] = key === value;
      });
    }
  },
  beforeCreate() {
    this.$emit("change-page-name", this.$t("common.group"));
  }
};
</script>

<style></style>
