<template>
  <v-card outlined class="pa-3 CircularProgressBox fill-height">
    <v-card-title class="d-flex align-center justify-space-between flex-nowrap">
      <h4>{{ title }}</h4>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small class="ml-2" v-bind="attrs" v-on="on">
            bb-info_icon
          </v-icon>
        </template>
        <span>
          {{ tooltipText }}
        </span>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="text-center">
      <v-progress-circular
        color="primary"
        :rotate="270"
        size="175"
        width="10"
        :value="progress"
        class="mb-3"
      >
        <div class="progress-box-inside text-center" v-if="enhancedInsideText">
          <div class="progress-box-enhanced-count">
            {{ percentProgress }}
            <span class="mt-1"> {{ percentProgressLabel }} </span>
          </div>
          <v-divider class="mx-4 my-2" />
          <div class="progress-box-enhanced-count">
            {{ maxNumber }}
            <span class="mt-1"> {{ maxLabel }} </span>
          </div>
        </div>
        <div class="progress-box-inside" v-else>
          <div
            class="progress-box-count"
            :class="{
              smaller: formatAsCurrency
                ? formatCurrency(percentProgress)
                : percentProgress > 9
            }"
          >
            {{
              formatAsCurrency
                ? formatCurrency(percentProgress)
                : percentProgress
            }}

            <span class="mt-2"
              >/
              {{
                formatAsCurrency ? formatCurrency(maxNumber) : maxNumber
              }}</span
            >
          </div>
        </div>
      </v-progress-circular>
    </v-card-text>
    <v-card-text class="pt-0">
      <div class="d-flex justify-space-between">
        <div class="d-flex align-center">
          <div class="color-show color-show-green mr-2"></div>
          <div class="description">{{ percentProgressLabel }}</div>
        </div>
        <div class="d-flex align-center ml-2">
          <div class="color-show color-show-gray mr-2"></div>
          <div class="description">{{ maxLabel }}</div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatCurrency } from "@/helpers/formatCurrency.js";

export default {
  name: "CircularProgressBox",
  data() {
    return {
      formatCurrency
    };
  },
  props: {
    enhancedInsideText: Boolean,
    title: String,
    percentProgress: Number,
    percentProgressLabel: String,
    maxNumber: Number,
    maxLabel: String,
    tooltipText: String,
    formatAsCurrency: Boolean
  },
  computed: {
    progress() {
      return (this.percentProgress / this.maxNumber) * 100;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "CircularProgressBox.scss";
</style>
