<template>
  <div>
    <div class="px-2 mb-2 text-right full-width">
      <router-link
        v-for="item in userMobileMenuItems"
        :key="`user-menu-${item.route}`"
        :to="{ name: item.route }"
        class="d-block black--text text-underline my-2"
      >
        {{ $t(item.name) }}
      </router-link>
    </div>
    <v-divider class="mx-2 my-2" />
    <LanguageSwitcher />
    <v-divider class="mx-2 my-2" />
    <div class="px-2 mb-2 text-right full-width">
      <a @click="logOut" class="d-block black--text text-underline my-2">
        {{ $t("common.logOut") }}
      </a>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/Base/LanguageSwitcher/LanguageSwitcher.vue";
import { userMobileMenuItems } from "@/data/menuItems";

export default {
  data() {
    return {
      userMobileMenuItems
    };
  },
  components: {
    LanguageSwitcher
  },
  methods: {
    logOut() {
      this.$store.dispatch("session/logout");
    }
  }
};
</script>

<style></style>
