<template>
  <div
    class="Search d-flex justify-center align-center flex-column flex-md-row full-width"
  >
    <v-text-field
      dense
      outlined
      hide-details
      v-model="searchByName"
      :placeholder="$t('defaultLayout.searchByName')"
      class="mr-0 mr-md-1 user-input"
      :append-icon="adminLayout ? 'bb-search_icon' : null"
      @click:append="search"
      @keyup.enter="search"
    ></v-text-field>
    <div v-if="!adminLayout" class="d-flex align-center">
      <v-btn
        class="search-button px-0"
        depressed
        large
        color="primary"
        @click="search"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Search",
  props: {
    adminLayout: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchByName: ""
    };
  },
  methods: {
    search() {
      this.$router.push(`/search/${this.searchByName}`);
    }
  }
};
</script>

<style lang="scss">
@import "./Search.scss";
</style>
