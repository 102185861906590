<template>
  <v-overlay z-index="9999" class="edit-user-modal">
    <v-card light>
      <v-btn @click="cancelEditUser" class="close-button" color="black" icon>
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <v-card-text class="d-flex justify-center align-center pt-16 pb-10 px-16">
        <div>
          <h2 class="mb-10 black--text">
            {{ $t("editUserModal.title") }}
          </h2>
          <v-form ref="form" v-model="isValid">
            <p class="text-1 gray-text">
              {{ $t("common.firstName") }}
            </p>
            <v-text-field
              :placeholder="$t('common.firstName')"
              outlined
              v-model="editingUser.firstName"
              :rules="[rules.required]"
              class="bb-input"
            />
            <p class="text-1 gray-text">
              {{ $t("common.lastName") }}
            </p>
            <v-text-field
              :placeholder="$t('common.lastName')"
              outlined
              v-model="editingUser.lastName"
              :rules="[rules.required]"
              class="bb-input"
            />

            <p class="text-1 gray-text">
              {{ $t("common.role") }}
            </p>
            <v-select
              :placeholder="$t('common.role')"
              outlined
              :items="roles"
              item-value="id"
              item-text="name"
              v-model="editingUser.role"
              :rules="[rules.required]"
              class="bb-input"
            />
            <v-chip-group v-model="groupsIndexes" column multiple class="mb-10">
              <v-chip
                v-for="group in groups"
                :key="`chip-group-${group.id}`"
                class="group-chip"
              >
                {{ group.name }}
              </v-chip>
            </v-chip-group>
          </v-form>
          <v-btn
            @click="editUser"
            color="primary"
            class="bb-button-shadow "
            large
          >
            {{ $t("addTarget.saveChanges") }}
          </v-btn>
          <v-btn @click="cancelEditUser" text class="ml-6" large>
            {{ $t("common.clear") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
import rules from "@/constants/rules";
import { roles } from "@/constants/roles";

export default {
  props: {
    user: Object
  },
  data() {
    return {
      rules: rules,
      roles: roles,
      isValid: false,
      editingUser: {},
      groupsIndexes: []
    };
  },
  computed: {
    groups() {
      return this.$store.getters["groups/groups"];
    }
  },
  created() {
    this.editingUser = Object.assign({}, this.user);

    this.groupsIndexes = this.editingUser.groups
      .map(group => {
        return this.groups.findIndex(x => {
          return x.id == group.id;
        });
      })
      .filter(group => {
        return group != -1;
      });
  },
  methods: {
    getGroupsIds(groups) {
      return groups.map(group => {
        return this.groups[group].id;
      });
    },
    editUser() {
      const groups = this.getGroupsIds(this.groupsIndexes);
      const data = {
        groups,
        user: {
          first_name: this.editingUser.firstName,
          last_name: this.editingUser.lastName
        },
        type: this.editingUser.role
      };

      this.$store
        .dispatch("user/updateUser", {
          id: this.editingUser.id,
          data
        })
        .then(() => {
          this.$emit("toggleEditUser");
        });
    },
    cancelEditUser() {
      this.$emit("toggleEditUser");
    }
  }
};
</script>

<style lang="scss">
@import "./EditUserModal.scss";
</style>
