<template>
  <div>
    <v-simple-table class="bb-table" v-if="groups.length && targets.length">
      <template v-slot:default>
        <TableLoader :active="loading" />
        <thead>
          <tr>
            <th class="text-left">
              {{ $t("targets.tableHeading.name") }}
            </th>
            <th class="text-left">
              {{ $t("targets.tableHeading.status") }}
            </th>
            <th class="text-left">
              {{ $t("targets.tableHeading.groups") }}
            </th>
            <th class="text-left">
              {{ $t("targets.tableHeading.numberOfPayers") }}
            </th>
            <th class="text-left">
              {{ $t("targets.tableHeading.budget") }}
            </th>
            <th class="text-left">
              {{ $t("common.date") }}
            </th>
            <th class="text-left"></th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="target in targets" :key="`target-${target.id}`">
            <td class="font-weight-medium">
              {{ target.name }}
            </td>
            <td>
              {{ $t(getStatusName(target.status)) }}
            </td>
            <td class="font-weight-medium">
              <span
                v-for="(group, index) in getGroupsForWhip(target.groups)"
                :key="`user-${target.id}-group-${group.id}`"
              >
                <router-link :to="{ name: 'Group', params: { id: group.id } }">
                  {{ group.name }}</router-link
                ><span v-if="checkIfNotLastGroup(index, target.groups)"
                  >,
                </span>
              </span>
            </td>
            <td class="font-weight-medium">
              {{ target.contributors_count }}
            </td>
            <td class="font-weight-medium">
              <BudgetProgressBar
                :done="target.balance.amount"
                :max="target.goal"
              />
            </td>
            <td class="font-weight-medium">
              {{ formatFromIsoToString(target.date_created) }}
            </td>
            <td class="font-weight-medium">
              <v-icon color="primary" small class="mr-2">
                bb-comment_icon
              </v-icon>
              {{ $t("targets.comments", { count: target.comments_count }) }}
            </td>
            <td class="font-weight-medium">
              <router-link
                :to="{
                  name: 'SingleWhip',
                  params: {
                    id: target.id
                  }
                }"
              >
                {{ $t("common.more") }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p v-else>
      {{ $t("common.noWhiprounds") }}
    </p>
  </div>
</template>

<script>
import TableLoader from "@/components/Base/TableLoader/TableLoader.vue";
import { formatFromIsoToString } from "@/helpers/formatDate";
import BudgetProgressBar from "@/components/Admin/BudgetProgressBar/BudgetProgressBar.vue";
import { targetStatuses } from "@/constants/targetStatuses";

export default {
  name: "WhiproundsTable",
  props: {
    loading: Boolean,
    targets: Array
  },
  components: {
    TableLoader,
    BudgetProgressBar
  },
  data() {
    return {
      formatFromIsoToString
    };
  },
  methods: {
    getStatusName(id) {
      const found = targetStatuses.find(status => {
        return status.id === id;
      });

      if (found) return found.singleKey;
      return "targetStatus.open";
    },
    getGroupsForWhip(groupsIds) {
      const groups = [];

      groupsIds.forEach(id => {
        const found = this.groups.find(group => {
          return group.id === id;
        });
        if (found) groups.push(found);
      });

      return groups;
    },
    checkIfNotLastGroup(index, groups) {
      const groupsLength = groups.length;
      return index < groupsLength - 1;
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["session/isAdmin"];
    },

    groups() {
      return this.$store.getters["groups/groups"];
    }
  }
};
</script>

<style></style>
