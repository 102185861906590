<template>
  <div class="pa-2 pt-0 text-right MenuLanguageSwitcher">
    <div class="full-width my-2 black--text">
      {{ `${$t("common.switchLanguage")}` }}
    </div>
    <a
      v-for="locale in availableLocales"
      :key="`menu-locale-${locale.code}`"
      class="d-block black--text text-underline my-2"
      @click="handleLangSwitch(locale.code)"
      :class="locale.active ? 'locale-active' : ''"
    >
      {{ locale.name }}
    </a>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  methods: {
    handleLangSwitch(locale) {
      this.$i18n.locale = locale;
    },
    checkIfActiveLocale(locale) {
      return this.$i18n.locale == locale;
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.availableLocales.map((locale) => {
        return {
          code: locale,
          name: this.$t(`common.locales.${locale}`),
          active: this.checkIfActiveLocale(locale)
        };
      });
    }
  }
};
</script>

<style lang="scss">
@import "./LanguageSwitcher.scss";
</style>
