<template>
  <div class="WelcomeCard">
    <v-card outlined class="welcome-card-card">
      <v-card-text class="pa-md-10">
        <div class="d-flex align-center mb-2 justify-space-between">
          <p class="black--text mb-0">
            {{ $t("welcomeCard.hi") }}
            <span class="font-weight-bold"> {{ username }}, </span>
          </p>
          <v-btn @click="closeWelcomeCard" icon color="primary">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
        <p class="black--text mb-0">
          {{ $t("welcomeCard.welcomeMesseage.first") }}
          <router-link :to="{ name: 'AddTarget' }">
            {{ $t("welcomeCard.welcomeMesseage.second") }}
          </router-link>
          {{ $t("welcomeCard.welcomeMesseage.third") }}
          <a href="#templates">
            {{ $t("welcomeCard.welcomeMesseage.fourth") }}</a
          >.
          <span class="font-weight-bold">
            {{ $t("welcomeCard.beeReady") }}
          </span>
        </p>
      </v-card-text>
    </v-card>
    <BeeWork class="BeeWork d-none d-md-block" />
  </div>
</template>

<script>
import BeeWork from "@/components/Base/BeeWork/BeeWork";

export default {
  name: "WelcomeCard",
  components: {
    BeeWork
  },
  methods: {
    closeWelcomeCard() {
      this.$emit("closeWelcomeCard");
    }
  },
  props: {
    username: String
  }
};
</script>

<style lang="scss">
@import "./WelcomeCard.scss";
</style>
