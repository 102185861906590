<template>
  <div class="GroupInformation">
    <div class="d-flex justify-space-between align-end">
      <p class="mb-0 font-weight-medium">
        {{ $t("groupInformation.groupInformation") }}
      </p>
    </div>
    <v-divider class="my-5" />
    <v-card flat class="d-flex flex-column flex-md-row">
      <v-card-text class="black--text pl-10 py-10 pr-10 pr-md-4">
        <div class="d-flex justify-space-between mb-2">
          <h4>
            {{ groupName }}
          </h4>
          <v-btn v-if="editable" icon small @click="toggleGroupEdit">
            <v-icon small> bb-edit_file_icon </v-icon>
          </v-btn>
        </div>
        <p class="my-7">{{ $t("groupInformation.founder") }}:</p>
        <p class="gray-text mb-2">
          {{ founder.full_name }}
        </p>
        <p class="gray-text">
          <a :href="`mailto:${founder.email}`">
            {{ founder.email }}
          </a>
        </p>
      </v-card-text>
      <div class="card-vertical-divider" />
      <v-card-text class="black--text wallet-card pr-10 py-10 pl-10 pl-md-4">
        <Wallet
          @toggleOverlayRecharge="toggleOverlayRecharge"
          :tooltipText="$t('groupInformation.walletTooltip')"
          :wallet="wallet"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Wallet from "@/components/Admin/Wallet/Wallet.vue";

export default {
  components: {
    Wallet
  },
  name: "GroupInformation",
  props: {
    groupName: String,
    founder: Object,
    wallet: Object,
    editable: Boolean
  },
  methods: {
    toggleOverlayRecharge() {
      this.$emit("toggleOverlayRecharge");
    },
    toggleGroupEdit() {
      this.$emit("toggleEditGroup");
    }
  }
};
</script>

<style lang="scss">
@import "./GroupInformation.scss";
</style>
