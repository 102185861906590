<template>
  <v-app class="defaultLayout">
    <PageLoader :active="!gotUser" />
    <template v-if="gotUser">
      <v-app-bar
        fixed
        max-height="80px"
        height="80px"
        class="menu"
        color="white"
      >
        <v-row class="justify-space-between">
          <v-col cols="3" class="d-flex align-center">
            <div class="logo-container d-flex justify-center align-center ">
              <router-link :to="{ name: 'Dashboard' }">
                <img
                  src="@/assets/logo-square.png"
                  height="57px"
                  class="mr-3"
                />
              </router-link>
            </div>
          </v-col>
          <v-col cols="4" class="d-none d-md-flex">
            <Search class="my-1" />
          </v-col>
          <v-col cols="3" class="d-flex flex-row justify-end align-center">
            <VerticalDivider class="mr-4" />
            <div class="d-none d-md-block">
              <CurrentUser />
            </div>
            <div class="d-none d-md-flex">
              <UserLayoutMenu />
            </div>
            <div class="d-block d-md-none">
              <MobileMenu>
                <router-link
                  v-for="(item, index) in dekstopMenuItems"
                  :key="`mobile-menu-${index}`"
                  :to="{ name: item.route, params: item.params }"
                  class="black--text font-weight-medium my-2"
                >
                  {{ $t(item.name) }}
                </router-link>
              </MobileMenu>
            </div>
          </v-col>
        </v-row>
      </v-app-bar>
      <DesktopMenu user>
        <MenuList :menuItems="menuItems" />
      </DesktopMenu>
      <div class="main-wrapper-padding pt-16">
        <v-main class="pt-6">
          <router-view />
        </v-main>
      </div>
    </template>
  </v-app>
</template>
<script>
import Search from "@/components/Base/Search/Search.vue";
import UserLayoutMenu from "@/components/Default/UserLayoutMenu/UserLayoutMenu.vue";
import MobileMenu from "@/components/Base/MobileMenu/MobileMenu.vue";
import DesktopMenu from "@/components/Base/DesktopMenu/DesktopMenu.vue";
import MenuList from "@/components/Base/MenuList/MenuList.vue";
import userMock from "@/mocks/user";
import { userMenuItems, userTemplateMobileMenuItems } from "@/data/menuItems";
import PageLoader from "@/components/Base/PageLoader/PageLoader.vue";
import CurrentUser from "@/components/Base/CurrentUser/CurrentUser.vue";
import VerticalDivider from "@/components/Base/VerticalDivider/VerticalDivider.vue";
import { deepClone } from "@/helpers/";

export default {
  components: {
    Search,
    CurrentUser,
    VerticalDivider,
    UserLayoutMenu,
    MobileMenu,
    DesktopMenu,
    PageLoader,
    MenuList
  },
  data() {
    return {
      username: userMock.username,
      avatar: userMock.avatar,
      userTemplateMobileMenuItems
    };
  },
  created() {
    this.$store.dispatch("session/getUserData");
  },
  watch: {
    loggedIn: function(val) {
      if (!val) {
        this.$router.push({ name: "Login" });
      }
    }
  },
  computed: {
    menuItems() {
      const list = deepClone(userMenuItems);

      const { id } = this.$store.getters["session/user"];

      if (id) {
        list.splice(-1, 0, {
          icon: "bb-person_info_icon",
          route: "User",
          params: { id },
          name: "menu.aboutMe"
        });
      }

      return list;
    },
    dekstopMenuItems() {
      const list = deepClone(userTemplateMobileMenuItems);

      const { id } = this.$store.getters["session/user"];

      if (id) {
        list.push({
          icon: "bb-person_info_icon",
          route: "User",
          params: { id },
          name: "menu.aboutMe"
        });
      }

      return list;
    },
    loggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    gotUser() {
      return this.$store.getters["session/gotUserData"];
    }
  },
  beforeMount() {
    this.$store.dispatch("session/getUserData");
  }
};

</script>
<style lang="scss">
@import "./Default.scss";
</style>
