import SessionLayout from "@/layouts/Session/Session.vue";
import Login from "@/views/session/Login/Login.vue";
import Register from "@/views/session/Register/Register.vue";
import RegisterCompany from "@/views/session/RegisterCompany/RegisterCompany.vue";
import RegisterEmployee from "@/views/session/RegisterEmployee/RegisterEmployee.vue";
import ForgotPassword from "@/views/session/ForgotPassword/ForgotPassword.vue";
import ResetPassword from "@/views/session/ResetPassword/ResetPassword.vue";
import Activate from "@/views/session/Activate/Activate.vue";

export default {
  path: "/",
  redirect: "/login",
  component: SessionLayout,
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/register",
      name: "Register",
      component: Register
    },
    {
      path: "/register/company",
      name: "RegisterCompany",
      component: RegisterCompany
    },
    {
      path: "/register/employee",
      name: "RegisterEmployee",
      component: RegisterEmployee
    },
    {
      path: "/forgot",
      name: "ForgotPassword",
      component: ForgotPassword
    },
    {
      path: "/password-reset/:uuid/:token",
      name: "ResetPassword",
      component: ResetPassword
    },
    {
      path: "/activate/:user/:token",
      name: "Activate",
      component: Activate
    }
  ]
};
