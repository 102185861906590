import LoggedUser from "@/layouts/LoggedUser/LoggedUser.vue";
import Dashboard from "@/views/admin/Dashboard/Dashboard.vue";
import InviteUser from "@/views/admin/InviteUser/InviteUser.vue";
import CompanySettings from "@/views/admin/CompanySettings/CompanySettings.vue";
import Recharges from "@/views/admin/Recharges/Recharges.vue";
import Payments from "@/views/admin/Payments/Payments.vue";
import Users from "@/views/default/Users/Users.vue";

export default {
  path: "/",
  redirect: "/dashboard",
  component: LoggedUser,
  meta: {
    requiresAuth: true,
    onlyAdmin: true
  },
  children: [
    {
      path: "/users",
      name: "Users",
      component: Users
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard
    },
    {
      path: "/add/user",
      name: "InviteUser",
      component: InviteUser
    },
    {
      path: "/settings/company",
      name: "CompanySettings",
      component: CompanySettings
    },

    {
      path: "/recharges",
      name: "Recharges",
      component: Recharges
    },

    {
      path: "/payments",
      name: "Payments",
      component: Payments
    }
  ]
};
