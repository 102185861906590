<template>
  <div>
    <PageLoader :active="!groups.length" />
    <v-container v-if="groups.length" fluid class="pa-6">
      <div
        class="d-flex flex-column flex-md-row justify-space-between align-center mb-13"
      >
        <div class="full-width">
          <h2 class="text-left mb-4 mb-md-0">
            {{ $t("targets.title") }}
          </h2>
        </div>
        <div
          class="d-flex flex-column flex-md-row align-start align-md-center justify-end full-width"
        >
          <v-select
            v-model="activeFilter"
            hide-details
            class="small-select mr-2 mb-2 mb-md-0"
            outlined
            :placeholder="$t('common.filterBy')"
            :items="filtersComputed"
            item-text="name"
            item-value="id"
          />
          <v-btn
            class="bb-button-shadow"
            router
            :to="{ name: 'AddTarget' }"
            large
            color="primary"
          >
            <v-icon left> bb-add_target_icons_1 </v-icon>
            {{ $t("targets.addTarget") }}
          </v-btn>
        </div>
      </div>

      <WhiproundsTable :loading="loading" :targets="targets" />

      <transition name="fade">
        <v-pagination
        v-if="pagesAmount"
        v-model="page"
        :length="pagesAmount"
        :total-visible="7" />
      </transition>
    </v-container>
  </div>
</template>

<script>
import { roles } from "@/constants/roles.js";
import { getWhiprounds } from "@/services/whiprounds";
import { pageSize } from "@/constants/apiParams";
import { targetStatuses } from "@/constants/targetStatuses";

import WhiproundsTable from "@/components/Base/WhiproundsTable/WhiproundsTable.vue";
import PageLoader from "@/components/Base/PageLoader/PageLoader.vue";

export default {
  name: "Groups",
  components: {
    PageLoader,
    WhiproundsTable
  },

  data() {
    return {
      activeFilter: 0,
      page: 1,
      roles: roles,
      targets: [],
      loading: true,
      pagesAmount: 0
    };
  },
  watch: {
    page() {
      this.refetchWhiprounds();
    },
    activeFilter() {
      this.page = 1;
      this.refetchWhiprounds();
    }
  },
  computed: {
    groups() {
      return this.$store.getters["groups/groups"];
    },
    filtersComputed() {
      const list = [];

      targetStatuses.forEach(status => {
        list.push({
          name: this.$t(status.name),
          id: status.id
        });
      });

      return list;
    }
  },
  methods: {
    refetchWhiprounds() {
      this.loading = true;
      this.getWhiprounds();
    },
    getWhiprounds() {
      const { activeFilter, page } = this;

      const params = {
        filter: activeFilter,
        page
      };
      getWhiprounds(params).then(({ data }) => {
        const { results, count } = data;
        this.pagesAmount = Math.ceil(count / pageSize);
        this.targets = results;
        this.loading = false;
      });
    }
  },

  created() {
    this.getWhiprounds();
    this.$store.dispatch("groups/getGroups");
  },
  beforeCreate() {
    this.$emit("change-page-name", this.$t("targets.pageTitle"));
  }
};
</script>

<style></style>
